import i18n from "i18n";
import moment from "moment";

type StringBreakType = "word" | "sentence" | "path";
type DotDotDotOptions = { offset?: number, append?: string, prepend?: string, breakType?: StringBreakType, reverse?: boolean };

/**
 * Truncates a string and adds ellipsis (...) if it exceeds a specified character limit.
 * @param value - The string to truncate.
 * @param characterLimit - The maximum number of characters allowed in the truncated string.
 * @param options - Optional parameters for customizing the truncation behavior.
 * @returns The truncated string with ellipsis if necessary.
 */
function dotDotDot(value: string, characterLimit: number, options?: DotDotDotOptions): string {
    let { offset = 0, append = "...", prepend = "", breakType = "word", reverse = false } = options || {};
    const separator = breakType === "sentence" ? '.' : breakType === "path" ? '/' : ' ';
    const actualCharacterLimit: number = characterLimit - append.length;

    if (actualCharacterLimit >= value.length) {
        return value;
    }

    let str = reverse ? [...value].reverse().join('') : value;

    if (offset < 0) {
        offset = 0;
    }

    let sliceList: string[];
    if (str.length > characterLimit * 1.5) {
        sliceList = str.substring(0, characterLimit * 1.1).split(separator).filter(s => !!s);
    } else {
        sliceList = str.split(separator).filter(s => !!s);
    }

    let trimmedSentence = "";

    if (offset > 0) {
        trimmedSentence += prepend;
    }

    if (sliceList.length > 0) {
        let i = 0;
        do {
            if (offset > 0) {
                offset -= sliceList[i].length + 1;
                continue;
            }

            trimmedSentence += sliceList[i] + separator;
        } while (i++ < sliceList.length && trimmedSentence.length + sliceList[i].length < actualCharacterLimit);

        trimmedSentence += append;
        return reverse ? trimmedSentence.split('').reverse().join('') : trimmedSentence;
    } else {
        return value;
    }
}

/**
 * Returns a comma-separated string representation of an array of strings.
 * If the array is empty, an empty string is returned.
 * If the array has only one element, that element is returned.
 * If the array has two elements, they are joined with the word "and" in between.
 * If the array has more than two elements, they are joined with commas, and the word "and" is added before the last element.
 * 
 * @param value - The array of strings to be joined.
 * @returns The comma-separated string representation of the array.
 */
const commaSeperated = (value: string[]) => {
    if (value.length === 0) {
        return "";
    }

    if (value.length === 1) {
        return value[0];
    }

    if (value.length === 2) {
        return value.join(` ${i18n.t("and")} `);
    }

    const lastValue = value.pop();
    return `${value.join(", ")}, ${i18n.t("and")} ${lastValue}`;
}

/**
 * Converts an object into a query string.
 * @param obj - The object to convert.
 * @param prefix - The prefix to use for nested properties.
 * @returns The generated query string.
 */
const toQueryString = (obj: any, prefix: string = ""): string => {
    const query = [];
    for (let p in obj) {
        if (!obj.hasOwnProperty(p)) {
            continue;
        }

        const k = prefix ? `${prefix}[${p}]` : p;
        const v = obj[p];

        if (!v) {
            continue;
        }
        
        let str: string;
        
        if(v instanceof Date) {
            str = encodeURIComponent(k) + "=" + encodeURIComponent(moment(v).toISOString(true));
        }
        else if (typeof v === "object") {
            str = toQueryString(v, k);
        }
        else {
            str = encodeURIComponent(k) + "=" + encodeURIComponent(v);
        }

        if (str) {
            query.push(str);
        }
    }

    return query.join("&");
}

/**
 * Trims the label by removing the hash symbol (#) and any leading or trailing whitespace.
 * @param label - The label to trim.
 * @returns The trimmed label.
 */
const trimLabel = (label: string) => {
    return label.replaceAll(/#/g, '').trim();
}

const trimSearch = (search: string | undefined) => {
    return (search ?? "").replaceAll(/#/g, '').trim();
}

export { dotDotDot, commaSeperated, trimLabel, trimSearch, toQueryString };