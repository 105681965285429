import { Card, CardBody, CardHeader, CardTitle, FormGroup } from "reactstrap";
import type { EditSectionProps } from "./PrintDocumentEditPage";
import { useTranslation } from "react-i18next";
import Checkbox from "Components/Form/Checkbox";
import { useImmer } from "use-immer";
import { useEffect } from "react";
import type { FnskuLabelPrintPreferences } from "api/types/contracts/printing";

const FnskuLabelEditSection = (props: EditSectionProps) => {
    const [values, updateValues] = useImmer(props.value as Record<keyof FnskuLabelPrintPreferences, string | undefined>);

    const { t } = useTranslation();

    useEffect(() => {
        props.onChange(values);
    }, [values])

    return <>
        <Card>
            <CardHeader>
                <CardTitle tag="h6" className="mb-0">{t("Label Customization")}</CardTitle>
            </CardHeader>
            <CardBody>
                <FormGroup>
                    <Checkbox switch value={values.includeExpirationDate === "true"} onChange={val => updateValues(prev => {
                        prev.includeExpirationDate = val ? "true" : "false";
                        return prev;
                    })}>
                        <span>{t("Include Expiration Date")}</span>
                        <br />
                        <span className="text-muted fw-light">
                            {t("paragraphs:FnskuLabelIncludeExpirationDateDescription")}
                        </span>
                    </Checkbox>
                </FormGroup>
            </CardBody>
        </Card>
    </>;
}

export default FnskuLabelEditSection;