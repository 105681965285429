import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { UserContract } from "api/types/contracts/users";
import type { PagedList } from "helpers/api_helper";
import type { LoadingStates } from "helpers/types";

type UserLoadingStates = LoadingStates<"list" | "save">;

export type UserState = {
    list: PagedList<UserContract> | undefined,
    loading: UserLoadingStates
};

export const initialState: UserState = {
    list: undefined,
    loading: {
        list: false,
        save: false
    }
};

const UserSlice = createSlice({
    name: "UserSlice",
    initialState,
    reducers: {
        setList(state, action: PayloadAction<PagedList<UserContract>>) {
            state.list = action.payload;
        },
        setLoading(state, action: PayloadAction<[keyof UserLoadingStates, boolean]>) {
            const [key, value] = action.payload;
            state.loading[key] = value;
        }
    },
});

export const { 
    setList,
    setLoading
} = UserSlice.actions;

export default UserSlice.reducer;
