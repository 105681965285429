import SelectInput from "Components/Form/SelectInput"
import { useDebounce } from "Components/Hooks/HelperHooks";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { WarehouseContract } from "api/types/contracts/warehouses";
import { ListWarehousesQueryForSelect } from "api/types/queries";
import { EntitySelectInputProps } from "helpers/types";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { loadSelectList } from "slices/warehouse/thunk";
import { useImmer } from "use-immer";

type WarehouseSelectProps = EntitySelectInputProps<WarehouseContract, "warehouseId"> & {
    companyId?: string,
    fullName?: boolean
}

const WarehouseSelect = ({ isMulti, value, onChange, onSelect, name, companyId, fullName, ...rest }: WarehouseSelectProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [query, updateQuery] = useImmer<ListWarehousesQueryForSelect>({
        companyId: "",
        search: ""
    });

    const { warehouses, loading } = useAppSelector(
        (state) => ({
            warehouses: state.Warehouse.selectList,
            loading: state.Warehouse.loading.selectList
        })
    );

    const warehouseList = useMemo(() => warehouses.map(c => ({ 
        value: c.warehouseId, 
        label: fullName ? `${c.name} - ${c.address.address1} ${c.address.city} ${c.address.state}` : c.name
    })), [warehouses, fullName]);

    const debouncedLoadList = useDebounce(() => {
        dispatch(loadSelectList(query));
    }, 200);

    useEffect(() => {
        debouncedLoadList();
    }, [debouncedLoadList, query]);

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = warehouses.filter(w => val.includes(w.warehouseId));

        if (selected) {
            onSelect?.(selected);
            onChange?.(val);
            return;
        }

        onChange?.([]);
        onSelect?.([]);
    };

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            const selected = warehouses.find(s => s.warehouseId === val);

            if (selected) {
                onSelect?.(selected);
                onChange?.(selected.warehouseId);
                return;
            }
        }

        onChange?.(undefined);
        onSelect?.(undefined);
    };

    useEffect(() => {
        if (!value) {
            return;
        }

        if (warehouses.length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [warehouses]);

    useEffect(() => {
        updateQuery(q => {
            if (companyId) {
                q.companyId = companyId;
            }
        });
    }, [updateQuery, companyId]);
    
    const onSearch = useCallback((val?: string) => {
        updateQuery(q => {
            q.search = val;
        });
    }, [updateQuery]);

    const selectProps = {
        ...rest,
        placeholder: t("Warehouse"),
        busy: loading,
        options: warehouseList,
        showClear: true,
        onSearch: onSearch
    }

    return <>
        {isMulti && <SelectInput<string> isMulti value={value} onChange={onChangedMulti} {...selectProps} />}
        {!isMulti && <SelectInput value={value} onChange={onChangedSingle} {...selectProps} />}
    </>;
}

export default WarehouseSelect;