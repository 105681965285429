import { ColumnDef } from "@tanstack/react-table";
import ListSummaryDisplay from "Components/Common/ListSummaryDisplay";
import Restricted from "Components/Common/Restricted";
import TableContainer, { TableContainerRef } from "Components/Common/TableContainer";
import DateDisplay from "Components/Displays/DateDisplay";
import InboundCodeDisplay from "Components/Displays/InboundCodeDisplay";
import ReceivingEntryStatusBadge from "Components/Displays/ReceivingEntryStatusDisplay";
import CompanySelect from "Components/EntitySelects/CompanySelect";
import CustomerSelect from "Components/EntitySelects/CustomerSelect";
import WarehouseSelect from "Components/EntitySelects/WarehouseSelect";
import DateRangeInput from "Components/Form/DateRangeInput";
import SearchBox from "Components/Form/SearchBox";
import { useDebounce } from "Components/Hooks/HelperHooks";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { ReceivingEntryContract } from "api/types/contracts/receivings";
import { ListReceivingEntriesQuery } from "api/types/queries";
import { dotDotDot } from "helpers/string";
import _ from "lodash";
import { useEffect, useMemo, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Card, CardBody, CardHeader, Col, Label, Row } from "reactstrap";
import { loadList } from "slices/receivings/thunk";
import { useImmer } from "use-immer";

const searchBoxModes: {
    key: "inboundCode" | "trackingNumber" | "receivingBoxNumber" | "receivingNumber",
    label: string
}[] = [{
    key: "inboundCode",
    label: "Inbound Code"
}, {
    key: "trackingNumber",
    label: "Tracking Number"
}, {
    key: "receivingBoxNumber",
    label: "Box Number"
}, {
    key: "receivingNumber",
    label: "Receiving Number"
}];

const List = () => {
    const [query, updateQuery] = useImmer<ListReceivingEntriesQuery>({
        page: 1,
        pageSize: 10
    });

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();
    const tableRef = useRef<TableContainerRef>(null);

    const { list, loading } = useAppSelector(
        (state) => ({
            loading: state.Receiving.loading,
            list: state.Receiving.list,
            error: state.Receiving.error,
        })
    );

    setPageTitle(`${t("Receiving List")} - ${t("Receivings")}`);
    
    const debouncedLoadList = useDebounce(() => {
        dispatch(loadList(query)).then(() => {
            tableRef.current?.resetSelection();
        });
    }, 500);

    useEffect(() => {
        debouncedLoadList();
    }, [debouncedLoadList, dispatch, query]);

    // Column
    const columns = useMemo<ColumnDef<ReceivingEntryContract, any>[]>(() => [
        {
            header: t("ACTIONS"),
            enableHiding: false,
            cell: (cell) => {
                return <>
                    <div className="hstack gap-1">
                        <Link to={`/gate-entry/${cell.row.original.receivingEntryId}`} className="btn btn-success btn-sm text-nowrap">
                            {t("New Box")}
                        </Link>
                        <Link to={`/receivings/${cell.row.original.receivingEntryId}`} className="btn btn-primary btn-sm">
                            {t("GRN")}
                        </Link>
                    </div>
                </>;
            },
        },
        {
            header: t("RECEIVING ID"),
            accessorFn: item => item.receivingNumber,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => <span>#{cell.row.original.receivingNumber}</span>,
        },
        {
            header: t("ARRIVAL DATE"),
            accessorFn: item => item.createdAt,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => <DateDisplay date={cell.getValue<Date>()} />,
        },
        {
            header: t("BOX IDs"),
            enableColumnFilter: false,
            cell: (cell) => {
                return <ListSummaryDisplay items={cell.row.original.boxes} displayFn={b => `#${b.box.boxNumber}`} />;
            }
        },
        {
            header: t("CUSTOMER"),
            accessorFn: item => `${item.customer.name} ${item.customer.surname}`,
            enableColumnFilter: false,
        },
        {
            header: t("INBOUND ID"),
            accessorFn: item => item.inbound?.inboundCode,
            enableColumnFilter: false,
            cell: (cell) => cell.row.original.inbound ? 
                <Link to={`/inbounds/${cell.row.original.inbound.inboundId}`}>
                    <InboundCodeDisplay inboundCode={cell.getValue<string>()} />
                </Link> : "-",
        },
        {
            header: t("TRACKING NUMBERS"),
            enableColumnFilter: false,
            cell: (cell) => <ListSummaryDisplay items={cell.row.original.boxes.filter(b => b.trackingNumber)} displayFn={b => b.trackingNumber} />
        },
        {
            header: t("BOX COUNT"),
            accessorFn: item => item.boxes.length
        },
        {
            header: t("PRODUCTS"),
            cell: (cell) => <ListSummaryDisplay items={_.chain(cell.row.original.boxes).flatMap(b => b.box.contents.map(c => c.product)).uniqBy("productId").value()} displayFn={p => dotDotDot(p.name, 30)} />
        },
        {
            header: t("STATUS"),
            accessorFn: item => item.status,
            cell: (cell) => <ReceivingEntryStatusBadge value={cell.row.original.status} />
        }],
        [t]
    );

    return <>
        <Row>
            <Col lg={12}>
                <Card>
                    <CardHeader className="border-0">
                        <Row className="align-items-center gy-3">
                            <div className="col-sm">
                                <h5 className="card-title mb-0">{t("Receiving List")}</h5>
                            </div>
                            <div className="col-sm-auto">
                                <div className="d-flex gap-1 flex-wrap">
                                    <Restricted require="receiving.grn" create>
                                        <Link to="/gate-entry" className="btn btn-primary">
                                            {t("Create New Receiving")}
                                        </Link>
                                    </Restricted>
                                </div>
                            </div>
                        </Row>
                    </CardHeader>
                    <CardBody className="pt-0">
                        <div>
                            <Row className="mb-2">
                                <Restricted require="management.company" read>
                                    <Col sm={6} md={4} lg={2}>
                                        <CompanySelect onSelect={company => updateQuery(q => {
                                            q.companyId = company?.companyId;
                                        })} />
                                    </Col>
                                </Restricted>
                                <Restricted require="management.customer" read>
                                    <Col sm={6} md={4} lg={3}>
                                        <CustomerSelect companyId={query.companyId} onSelect={customer => updateQuery(q => {
                                            q.customerId = customer?.customerId;
                                        })} />
                                    </Col>
                                </Restricted>
                                <Col sm={6} md={4} lg={3}>
                                    <WarehouseSelect value={query.destinationWarehouseId} showClear
                                        onSelect={warehouse => updateQuery(q => {
                                            q.destinationWarehouseId = warehouse?.warehouseId;
                                        })} />
                                </Col>
                                {/* <Col sm={6} md={4} lg={3} className="gap-2 hstack">
                                    <Label className="mb-0 text-nowrap">{("Receiving ID")}</Label>
                                    <TextInput value={query.receivingNumber} placeholder={t("Enter Receiving ID")} onChange={val => {
                                        updateQuery(q => {
                                            q.receivingNumber = val;
                                        })
                                    }} />
                                </Col> */}
                                <Col sm={6} md={4} lg={4} className="gap-2 hstack">
                                    <Label className="mb-0 text-nowrap">{("Arrival Date")}</Label>
                                    <DateRangeInput value={query.createdRange} onChange={val => {
                                        updateQuery(q => {
                                            q.createdRange = val;
                                        })
                                    }} />
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    <SearchBox value=""
                                        placeholder="Search by tracking number, box number (BOX- prefix), receiving number (RCV- prefix), inbound code (INB- prefix)" 
                                        modes={searchBoxModes}
                                        onChange={(val, mode) => updateQuery(q => {
                                            q.search = q.receivingBoxNumber = q.inboundCode = q.trackingNumber = undefined;

                                            if (!mode) {
                                                q.search = val;
                                            }
                                            else {
                                                q[mode] = val;
                                            }
                                        })} />
                                </Col>
                                {/* <Col sm={6} md={4} lg={3} className="gap-2 hstack">
                                    <Label className="mb-0 text-nowrap">{("Barcode")}</Label>
                                    <TextInput value={query.receivingBoxNumber} placeholder={t("Enter Barcode")} onChange={val => {
                                        updateQuery(q => {
                                            q.receivingBoxNumber = val;
                                        })
                                    }} />
                                </Col>
                                <Col sm={6} md={4} lg={2} className="gap-2 hstack">
                                    <Label className="mb-0 text-nowrap">{("Inbound ID")}</Label>
                                    <TextInput value={query.inboundCode} placeholder={t("Enter Inbound ID")} onChange={val => {
                                        updateQuery(q => {
                                            q.inboundCode = val;
                                        })
                                    }} />
                                </Col>
                                <Col sm={6} md={4} lg={3} className="gap-2 hstack">
                                    <Label className="mb-0 text-nowrap">{("Track ID")}</Label>
                                    <TextInput value={query.trackingNumber} placeholder={t("Enter Track ID")} onChange={val => {
                                        updateQuery(q => {
                                            q.trackingNumber = val;
                                        })
                                    }} />
                                </Col> */}
                            </Row>
                            <TableContainer
                                ref={tableRef}
                                busy={loading.list}
                                columns={columns}
                                nowrap
                                data={(list?.items || [])}
                                totalDataLength={list?.totalCount}
                                pagination={{
                                    pageIndex: query.page - 1,
                                    pageSize: query.pageSize
                                }}
                                onPaginationChanged={pagination => updateQuery(q => {
                                    q.page = pagination.pageIndex + 1;
                                    q.pageSize = pagination.pageSize;
                                })}
                                divClass="mb-1"
                                tableClass="align-middle"
                            />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </>;
}

export default List;