import Dialog, { DialogRef } from "Components/Common/Dialog";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { PropsWithChildren, useRef } from "react"
import { useTranslation } from "react-i18next";
import { Button } from "reactstrap";
import { finalizeReceivingEntry, loadReceivingEntry } from "slices/receivings/thunk";

type CompleteButtonProps = PropsWithChildren & {
    disabled?: boolean
};

const CompleteButton = (props: CompleteButtonProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const dialogRef = useRef<DialogRef>(null);

    const { processedEntry, selectedBox, loading } = useAppSelector(
        (state) => ({
            processedEntry: state.Receiving.processedEntry!,
            selectedBox: state.Receiving.selectedBox,
            loading: state.Receiving.loading
        })
    );
    
    const finalize = async () => {
        await dispatch(finalizeReceivingEntry({ receivingEntryId: processedEntry.receivingEntryId }));
        await dispatch(loadReceivingEntry({ receivingEntryId: processedEntry.receivingEntryId }));
    };

    return <>
        <Button color="success" disabled={props.disabled} onClick={() => dialogRef.current?.show()}>{props.children}</Button>
        <Dialog ref={dialogRef} color="success" buttons={["yes", "no"]} busy={loading.save || loading.load} iconClass="ri-checkbox-circle-line"  
            message={t('The receiving process will be completed')} title={`Do you want to continue?`}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    finalize().then(() => hide())
                }
                else {
                    hide();
                }
            }} />
    </>
}

export default CompleteButton;