import TableContainer, { TableContainerRef, selectRowColumn } from "Components/Common/TableContainer";
import { Ref, RefAttributes, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { ColumnDef } from "@tanstack/react-table";
import { useTranslation } from "react-i18next";
import { loadList, removeCompany, setCompanyStatus } from "slices/company/thunk";
import SearchBox from "Components/Form/SearchBox";
import { useDebounce } from "Components/Hooks/HelperHooks";
import { ListCompaniesQuery } from "api/types/queries";
import { CompanyContract, CompanyStatus, CompanyType } from "api/types/contracts/companies";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import { useImmer } from "use-immer";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import Restricted from "Components/Common/Restricted";
import CompanyStatusSelect from "Components/EnumSelects/CompanyStatusSelect";
import InlineAddressDisplay from "Components/Displays/InlineAddressDisplay";
import BusyOverlay from "Components/Common/BusyOverlay";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";

export type CompanyListRef = {
    reload: VoidFunction
}

const List = (props: {}, ref: Ref<CompanyListRef>) => {
    const [deleteMode, setDeleteMode] = useState<"single" | "multi">();
    const [selectedItems, setSelectedItems] = useState<CompanyContract[]>([]);
    const company = useMemo(() => selectedItems[0], [selectedItems]);
    
    const [query, updateQuery] = useImmer<ListCompaniesQuery>({
        page: 1,
        pageSize: 10
    })

    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();
    const tableRef = useRef<TableContainerRef>(null);
    const dialogRef = useRef<DialogRef>(null);

    const { list, loading } = useAppSelector(
        (state) => ({
            loading: state.Company.loading,
            list: state.Company.list,
            error: state.Company.error
        })
    );

    setPageTitle(`${t("Company List")} - ${t("Management")}`);
    
    const debouncedLoadList = useDebounce(() => {
        dispatch(loadList(query)).then(() => {
            tableRef.current?.resetSelection();
        });
    }, 200);

    useEffect(() => {
        debouncedLoadList();
    }, [debouncedLoadList, query]);

    useImperativeHandle(ref, () => {
        return {
            reload: () => {
                debouncedLoadList();
            }
        };
    }, [debouncedLoadList]);

    const deleteCompany = useCallback(async () => {
        if (deleteMode === "multi" && selectedItems.length > 0) {
            let success = false;
            for (const item of selectedItems) {
                success &&= await dispatch(removeCompany({
                    companyId: item.companyId
                }));
            }

            return success;
        }
        else if (deleteMode === "single" && company) {
            return await dispatch(removeCompany({
                companyId: company.companyId
            }));
        }

        return false;
    }, [company, deleteMode, selectedItems, dispatch]);

    const handleDeleteClick = (arg: CompanyContract) => {
        setSelectedItems([arg]);
        setDeleteMode("single");
    };

    const handleMultiDeleteClick = () => {
        setDeleteMode("multi");
    };

    useEffect(() => {
        if (deleteMode) {
            dialogRef.current?.show();
        }
        else {
            dialogRef.current?.hide();
        }
    }, [deleteMode]);

    const onCompanyStatusChange = useCallback((companyId: string, status: CompanyStatus) => {
        dispatch(setCompanyStatus({ companyId, status })).then(success => {
            debouncedLoadList();
        });
        }, [dispatch, debouncedLoadList]);

    // Column
    const columns = useMemo<ColumnDef<CompanyContract, any>[]>(() => [
        selectRowColumn<CompanyContract>(),
        {
            header: t("ACTIONS"),
            enableHiding: false,
            cell: (cell) => {
                return <>
                    <div className="hstack gap-1">
                        <Restricted require={"management.company"} write ownership={cell.row.original}>
                            <Link to={`/companies/edit/${cell.row.original.companyId}`} className="btn btn-ghost-dark px-1 py-0">
                                <i className="ri-pencil-fill fs-16"></i>
                            </Link>
                        </Restricted>
                        <Restricted require={"management.company"} delete>
                            <Button color="ghost-danger" className="px-1 py-0"
                                onClick={() => handleDeleteClick(cell.row.original)}>
                                <i className="ri-delete-bin-5-fill fs-16"></i>
                            </Button>
                        </Restricted>
                    </div>
                </>;
            },
        },
        {
            header: t("NAME"),
            accessorFn: item => item.name,
            enableHiding: false,
            enableColumnFilter: false,
            cell: (cell) => {
                return <Link to={`/companies/edit/${cell.row.original.companyId}`} className="fw-medium link-secondary">{cell.row.original.name}</Link>;
            },
        },
        {
            header: t("PHONE"),
            accessorFn: item => item.contactAddress.phone,
            enableColumnFilter: false,
        },
        {
            header: t("EMAIL"),
            accessorFn: item => item.contactAddress.email,
            enableColumnFilter: false,
        },
        {
            header: t("ADDRESS"),
            accessorFn: item => <InlineAddressDisplay address={item.contactAddress} />,
            enableColumnFilter: false,
            cell: (cell) => (
                <>
                    <span className="text-muted fs-10 text-wrap">{cell.getValue()}</span>
                </>
            ),
        },
        {
            header: t("# WAREHOUSES"),
            accessorFn: item => item.warehouses.length,
            enableColumnFilter: false,
            cell: (cell) => {
                return <Link to={`/warehouses?company=${cell.row.original.companyId}`} className="fw-medium link-secondary">{cell.getValue()}</Link>;
            },
        },
        {
            header: t("# CUSTOMERS"),
            accessorFn: item => item.customers.length,
            enableColumnFilter: false,
            cell: (cell) => {
                return <Link to={`/customers?company=${cell.row.original.companyId}`} className="fw-medium link-secondary">{cell.getValue()}</Link>;
            },
        },
        {
            header: t("STATUS"),
            accessorFn: item => item.companyStatus,
            cell: (cell) => {
                return <>
                    <Restricted require="management.company" write fallback={() => <>
                            {t(`enums:CompanyStatus.${cell.row.original.companyStatus}`)}
                        </>}>
                        <BusyOverlay busy={loading.status} size="sm">
                            <CompanyStatusSelect name="status" className="mb-0" value={cell.row.original.companyStatus} onChange={val => {
                                if (val) {
                                    onCompanyStatusChange(cell.row.original.companyId, val);
                                }
                            }}></CompanyStatusSelect>
                        </BusyOverlay>
                    </Restricted>
                </>;
            }
        },
        {
            header: t("TYPE"),
            accessorFn: item => item.companyType,
            enableColumnFilter: false,
            cell: (cell) => {
                switch (cell.getValue<CompanyType>()) {
                    case "regular":
                        return <span className="badge text-uppercase bg-info-subtle text-info">{t("Regular")}</span>;
                    case "demo":
                        return <span className="badge text-uppercase bg-warning-subtle text-warning">{t("Demo")}</span>;
                }
            }
        }],
        [t, loading.status, onCompanyStatusChange]
    );

    return <>
        <Row>
            <Col lg={12}>
                <Card id="companyList">
                    <CardHeader className="border-0">
                        <Row className="align-items-center gy-3">
                            <div className="col-sm">
                                <h5 className="card-title mb-0">{t("Company List")}</h5>
                            </div>
                            <div className="col-sm-auto">
                                <div className="d-flex gap-1 flex-wrap">
                                    <Restricted require="management.company" write>
                                        <Link to="/companies/new" className="btn btn-info add-btn">
                                            <i className="ri-add-line align-bottom me-1"></i> {t("Create")}
                                        </Link>
                                    </Restricted>
                                    {selectedItems.length > 0 && deleteMode !== "single" && <>
                                        <Restricted require="management.company" delete>
                                            <button className="btn btn-soft-danger" onClick={() => handleMultiDeleteClick()}><i
                                                className="ri-delete-bin-2-line"></i></button>
                                        </Restricted>
                                    </>}
                                </div>
                            </div>
                        </Row>
                    </CardHeader>
                    <CardBody className="pt-0">
                        <div>
                            {/* <Nav className="nav-tabs nav-tabs-custom nav-success" role="tablist">
                                <NavItem>
                                    <NavLink className={classnames(
                                            { active: selectedStatus === "active" },
                                            "fw-semibold"
                                        )} onClick={() => { setSelectedStatus("active"); }} href="#">
                                        <i className="ri-store-2-fill me-1 align-bottom"></i>
                                        {t("Active")}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames(
                                            { active: selectedStatus === "passive" },
                                            "fw-semibold"
                                        )}
                                        onClick={() => { setSelectedStatus("passive"); }} href="#">
                                        <i className="ri-checkbox-circle-line me-1 align-bottom"></i>
                                        {t("Passive")}
                                    </NavLink>
                                </NavItem>
                            </Nav> */}
                            <Row>
                                <Col sm={6} md={4} lg={2}>
                                    <Restricted require="management.company" read>
                                        <CompanyStatusSelect value={query.companyStatus} placeholder="Select status" showClear
                                            onChange={(val: any) => updateQuery(q => {
                                                q.companyStatus = val;
                                            })} ></CompanyStatusSelect>
                                    </Restricted>
                                </Col>
                                <Col sm={6} lg={4} className="ms-auto">
                                    <SearchBox value={query.search || ""} placeholder="Search name, email, phone or address"
                                        onChange={val => updateQuery(q => {
                                            q.search = val;
                                        })}></SearchBox>
                                </Col>
                            </Row>
                            <TableContainer
                                ref={tableRef}
                                busy={loading.list}
                                columns={columns}
                                nowrap
                                data={(list?.items || [])}
                                totalDataLength={list?.totalCount}
                                pagination={{
                                    pageIndex: query.page - 1,
                                    pageSize: query.pageSize
                                }}
                                onPaginationChanged={pagination => updateQuery(q => {
                                    q.page = pagination.pageIndex + 1;
                                    q.pageSize = pagination.pageSize;
                                })}
                                onSelectionChanged={selection => {
                                    setSelectedItems(selection);
                                }}
                                divClass="mb-1"
                                tableClass="align-middle"
                            />
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </Row>
        {deleteMode && <Dialog ref={dialogRef} color="warning" buttons={["yes", "no"]} busy={loading.delete} iconClass="ri-delete-bin-line"  
            message={`Do you want to continue?`} title={t("Deleting company '{{name}}'", { name: company?.name, count: deleteMode === "multi" ? selectedItems.length : 1 })}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    deleteCompany().then(success => {
                        if (success) {
                            debouncedLoadList();
                        }
                    }).finally(() => {
                        setDeleteMode(undefined);
                        setSelectedItems([]);
                    });
                }
                else {
                    setDeleteMode(undefined);
                }
            }} />}
    </>;
};

export default forwardRef(List) as (
    props: RefAttributes<CompanyListRef>
) => ReturnType<typeof List>;