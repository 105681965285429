import { setList, loading, apiError } from "./reducer";
import { ListUnusableProductReasonsQuery } from "api/types/queries";
import { CreateUnusableProductReasonCommand, DeleteUnusableProductReasonCommand, UpdateUnusableProductReasonCommand } from "api/types/commands";
import { ApiError } from "helpers/types";
import { toast } from "react-toastify";
import i18n from 'i18n';
import { deleteUnusableProductReason, getUnusableProductReasonList, postUnusableProductReason, putUnusableProductReason } from "api/unusableProductReason";
import { AppDispatch, RootState } from "slices";

export const loadUnusableProductReasonList = (params: ListUnusableProductReasonsQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["list", true]));

        var result = await getUnusableProductReasonList(params);
        
        dispatch(setList(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["list", false]));
    }
};

export const createUnusableProductReason = (params: CreateUnusableProductReasonCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true]));

        await postUnusableProductReason(params);
        toast.success(i18n.t("Unusable product reason created"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const updateUnusableProductReason = (params: UpdateUnusableProductReasonCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true]));

        await putUnusableProductReason(params);
        toast.success(i18n.t("Unusable product reason updated"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const removeUnusableProductReason = (params: DeleteUnusableProductReasonCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["delete", true]));

        await deleteUnusableProductReason(params);

        toast.success(i18n.t("Unusable product reason deleted", { count: 1 }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const removeMultiUnusableProductReasons = (params: DeleteUnusableProductReasonCommand[]) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["delete", true]));

        for (const item of params) {
            await deleteUnusableProductReason(item);
        }

        toast.success(i18n.t("Unusable product reasons deleted", { count: params.length }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}
