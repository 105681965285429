import React, { useEffect, useCallback, MouseEvent } from 'react';
import { Link } from "react-router-dom";
import { Badge, Collapse } from 'reactstrap';
//i18n
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createSelector } from 'reselect';
import { useRouter } from 'Components/Hooks/RouterHooks';
import { useMenu } from 'Components/Hooks/MenuHooks';
import { MenuItem } from 'Routes/navigation_data';
import { RootState } from 'slices';
import classNames from 'classnames';
import { useProfile } from 'Components/Hooks/ProfileHooks';

type VerticalLayoutProps = {

}

const EMPTY_LINK = "/#";

const VerticalLayout = (props: VerticalLayoutProps) => {
    const { location } = useRouter();

    const { menu, openMenuItem } = useMenu();
    const path = location.pathname;
    const { t } = useTranslation();
    const { hasPermission, userProfile } = useProfile();

    /*
    layout settings
    */
    const selectLayoutProperties = createSelector(
        (state: RootState) => state.Layout,
        (layout) => ({
            leftsidbarSizeType: layout.leftsidbarSizeType,
            sidebarVisibilitytype: layout.sidebarVisibilitytype,
            layoutType: layout.layoutType
        })
    );
    // Inside your component
    const { leftsidbarSizeType, sidebarVisibilitytype, layoutType } = useSelector(selectLayoutProperties);

    //vertical and semibox resize events
    const resizeSidebarMenu = useCallback(() => {
        var windowSize = document.documentElement.clientWidth;
        const humberIcon = document.querySelector(".hamburger-icon") as HTMLElement;
        var hamburgerIcon = document.querySelector(".hamburger-icon");
        if (windowSize >= 1025) {
            if (document.documentElement.getAttribute("data-layout") === "vertical") {
                document.documentElement.setAttribute("data-sidebar-size", leftsidbarSizeType);
            }
            if (document.documentElement.getAttribute("data-layout") === "semibox") {
                document.documentElement.setAttribute("data-sidebar-size", leftsidbarSizeType);
            }
            if ((sidebarVisibilitytype === "show" || layoutType === "vertical" || layoutType === "twocolumn") && document.querySelector(".hamburger-icon")) {
                if (hamburgerIcon !== null) {
                    hamburgerIcon.classList.remove("open");
                }
            } else {
                // var hamburgerIcon = document.querySelector(".hamburger-icon");
                if (hamburgerIcon !== null) {
                    hamburgerIcon.classList.add("open");
                }
            }

        } else if (windowSize < 1025 && windowSize > 767) {
            document.body.classList.remove("twocolumn-panel");
            if (document.documentElement.getAttribute("data-layout") === "vertical") {
                document.documentElement.setAttribute("data-sidebar-size", "sm");
            }
            if (document.documentElement.getAttribute("data-layout") === "semibox") {
                document.documentElement.setAttribute("data-sidebar-size", "sm");
            }
            if (humberIcon) {
                humberIcon.classList.add("open");
            }
        } else if (windowSize <= 767) {
            document.body.classList.remove("vertical-sidebar-enable");
            if (document.documentElement.getAttribute("data-layout") !== "horizontal") {
                document.documentElement.setAttribute("data-sidebar-size", "lg");
            }
            if (humberIcon) {
                humberIcon.classList.add("open");
            }
        }
    }, [leftsidbarSizeType, sidebarVisibilitytype, layoutType]);

    useEffect(() => {
        window.addEventListener("resize", resizeSidebarMenu, true);
    }, [resizeSidebarMenu]);

    useEffect(() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
        // const initMenu = () => {
        //     const pathName = process.env.PUBLIC_URL + path;
        //     const ul = document.getElementById("navbar-nav") as HTMLElement;
        //     const items: any = ul.getElementsByTagName("a");
        //     let itemsArray = [...items]; // converts NodeList to Array
        //     removeActivation(itemsArray);
        //     let matchingMenuItem = itemsArray.find((x) => {
        //         return x.pathname === pathName;
        //     });
        //     if (matchingMenuItem) {
        //         activateParentDropdown(matchingMenuItem);
        //     }
        // };
        // if (layoutType === "vertical") {
        //     initMenu();
        // }
    }, [path, layoutType]);

    const createClickHandler = useCallback((item: MenuItem) => (e: MouseEvent) => {
        if (item.link === EMPTY_LINK) {
            e.preventDefault();
        }

        openMenuItem(item);
    }, [openMenuItem]);

    return <>
        {/* menu Items */}
        {menu.map((item, key) => <React.Fragment key={key}>
            {/* Main Header */}
            {item.isHeader ?
                (item.permissions.length === 0 || item.permissions.some(p => hasPermission(p))) && <li className="menu-title"><span>{t(item.label)} </span></li>
                : item.children ?
                    <li className="nav-item">
                        <Link onClick={createClickHandler(item)} to={item.link || EMPTY_LINK} data-bs-toggle="collapse"
                            data-asd="d" className={classNames("nav-link menu-link", {
                                active: item.openState
                            })}>
                            <i className={item.icon}></i>
                            <span>{t(item.label)}</span>
                            {item.badgeName && 
                                <Badge pill color={item.badgeColor}>{item.badgeName}</Badge>}
                        </Link>
                        <Collapse className="menu-dropdown" isOpen={item.openState}>
                            <ul className="nav nav-sm flex-column test">
                                {/* subItms  */}
                                {item.children && (item.children.map((subItem, key) => <React.Fragment key={key}>
                                    {!subItem.children ? <>
                                        <li className="nav-item">
                                            <Link
                                                to={subItem.link || EMPTY_LINK}
                                                className={classNames("nav-link", {
                                                    active: subItem.openState
                                                })}
                                            >
                                                {t(subItem.label)}
                                                {subItem.badgeName && 
                                                    <Badge pill color={subItem.badgeColor}>{subItem.badgeName}</Badge>}
                                            </Link>
                                        </li>
                                    </> : <>
                                        <li className="nav-item">
                                            <a onClick={createClickHandler(subItem)} className="nav-link" href={EMPTY_LINK} data-bs-toggle="collapse">
                                                {t(subItem.label)}
                                                {subItem.badgeName && 
                                                    <Badge pill color={subItem.badgeColor}>{subItem.badgeName}</Badge>}
                                            </a>
                                            <Collapse className="menu-dropdown" isOpen={subItem.openState}>
                                                <ul className="nav nav-sm flex-column">
                                                    {/* child subItms  */}
                                                    {subItem.children && (
                                                        subItem.children.map((childItem, key) => <React.Fragment key={key}>
                                                            {!childItem.children ?
                                                                <li className="nav-item">
                                                                    <Link to={childItem.link || EMPTY_LINK} className={classNames("nav-link", {
                                                                        active: childItem.openState
                                                                    })}>
                                                                        {t(childItem.label)}
                                                                    </Link>
                                                                </li>
                                                                : <li className="nav-item">
                                                                    <a href={EMPTY_LINK} className="nav-link" onClick={createClickHandler(childItem)} data-bs-toggle="collapse">
                                                                        {t(childItem.label)}
                                                                    </a>
                                                                    <Collapse className="menu-dropdown" isOpen={childItem.openState}>
                                                                        <ul className="nav nav-sm flex-column">
                                                                            {childItem.children.map((subChildItem, key) => (
                                                                                <li className="nav-item" key={key}>
                                                                                    <Link to={subChildItem.link || EMPTY_LINK} className="nav-link">{t(subChildItem.label)} </Link>
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </Collapse>
                                                                </li>
                                                            }
                                                        </React.Fragment>))}
                                                </ul>
                                            </Collapse>
                                        </li>
                                    </>}
                                </React.Fragment>))}
                            </ul>
                        </Collapse>
                    </li>
                    :
                    (!item.permission || ((hasPermission(item.permission) && (!item.operation || userProfile!.user!.permissions![item.permission].includes(item.operation))))) && <li className="nav-item">
                        <Link className={classNames("nav-link menu-link", {
                            active: item.openState
                        })} to={item.link ? item.link : EMPTY_LINK}>
                            <i className={item.icon}></i> <span>{t(item.label)}</span>
                            {item.badgeName && 
                                <Badge pill color={item.badgeColor}>{item.badgeName}</Badge>}
                        </Link>
                    </li>
            }
        </React.Fragment>)}
    </>;
};

export default VerticalLayout;