import { APIClient, PagedList } from "helpers/api_helper";
import type { ListCreditCardsQuery, ListTransactionHistoryQuery } from "./types/queries";
import type { LoadCreditWithStripeCommand, RequestCreditCommand } from "./types/commands";
import { TransactionHistoryContract, type CreditCardContract } from "./types/contracts/payment";

const api = new APIClient();

export const getCardList = async (data: ListCreditCardsQuery) => {
    const result = await api.get<CreditCardContract[]>(`/api/payment/card`, data);
    return result;
}

export const postRequestCredit = async (data: RequestCreditCommand) => {
    await api.post(`/api/payment/request-credit`, { ...data });
}

export const postLoadCreditWithStripe = async (data: LoadCreditWithStripeCommand) => {
    await api.post(`/api/payment/load-credit/stripe`, { ...data });
}

export const getTransactionHistory = async (data: ListTransactionHistoryQuery) => {
    return await api.get<PagedList<TransactionHistoryContract>>(`/api/payment/history`, { ...data });
}