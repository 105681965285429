import { CSSProperties } from "react";
import { Input, InputProps } from "reactstrap";
import { ValidatableInputProps } from "helpers/types";
import classNames from "classnames";

export type NumberInputProps = ValidatableInputProps<number> & {
    id?: string,
    min?: number,
    max?: number,
    className?: string,
    decimalPlaces?: number,
    size?: "sm" | "lg",
    style?: CSSProperties,
    disabled?: boolean,
    placeholder?: string,
    readOnly?: boolean,
    onBlur?: InputProps["onBlur"],
    onInput?: InputProps["onInput"],
}

const NumberInput = ({ isValid, onChange, value, size, className, decimalPlaces, ...rest }: NumberInputProps) =>  {
    const onChangeHandler = (value: string | undefined) => {
        if (value === undefined || value === "") {
            onChange?.(undefined);
        } else {
            let numberValue = Number(value);

            onChange?.(numberValue);
        }
    }

    return <>
        <Input
            //valid={isValid === true}
            invalid={isValid === false}
            type="number"
            onChange={e => onChangeHandler(e.target.value)}
            value={value ?? ""}
            bsSize={size}
            className={classNames(className)}
            { ...rest } />
    </>;
}

export default NumberInput;