import SelectInput from "Components/Form/SelectInput";
import { ShippingLabelCustomFieldList, type ShippingLabelCustomField } from "api/types/contracts/printing";
import { EnumSelectInput, SelectOption } from "helpers/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const ShippingLabelCustomFieldSelect = (props: EnumSelectInput<ShippingLabelCustomField>) => {
    const { t } = useTranslation();
    const printOrientationOptions = useMemo<SelectOption<ShippingLabelCustomField>[]>(() => ShippingLabelCustomFieldList.map((item) => ({ 
        value: item, 
        label: t(`enums:ShippingLabelCustomField.${item}`) 
    })), [t]);

    const { isMulti, value, onChange, ...rest } = props;

    return <>
        {isMulti && <SelectInput<ShippingLabelCustomField> isMulti value={value} onChange={onChange} {...rest} options={printOrientationOptions} placeholder={t("Custom Field")} />}
        {!isMulti && <SelectInput {...rest} value={value} onChange={onChange} options={printOrientationOptions} placeholder={t("Custom Field")} />}
    </>;
};

export default ShippingLabelCustomFieldSelect;