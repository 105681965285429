import SelectInput from "Components/Form/SelectInput";
import { useDebounce } from "Components/Hooks/HelperHooks";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { CustomerContract } from "api/types/contracts/customers";
import { ListCustomersQueryForSelect } from "api/types/queries";
import { EntitySelectInputProps } from "helpers/types";
import { useCallback, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { loadSelectList } from "slices/customer/thunk";
import { useImmer } from "use-immer";

type CustomerSelectProps = EntitySelectInputProps<CustomerContract, "customerId"> & {
    companyId?: string
}

const CustomerSelect = ({ isMulti, onChange, onSelect, isValid, companyId, value, ...rest }: CustomerSelectProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [query, updateQuery] = useImmer<ListCustomersQueryForSelect>({
        companyId: "",
        search: ""
    });

    const { customers, loading } = useAppSelector(
        (state) => ({
            customers: state.Customer.selectList,
            loading: state.Customer.loading.selectList
        })
    );

    const customerList = useMemo(() => customers.map(c => ({ value: c.customerId, label: `${c.name} ${c.surname}`})), [customers]);

    const debouncedLoadList = useDebounce(() => {
        dispatch(loadSelectList(query));
    }, 200);

    useEffect(() => {
        debouncedLoadList();
    }, [debouncedLoadList, query]);

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = customers.filter(c => val.includes(c.customerId));

        if (selected) {
            onSelect?.(selected);
            onChange?.(val);
            return;
        }

        onChange?.([]);
        onSelect?.([]);
    };

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            const selected = customers.find(c => c.customerId === val);

            if (selected) {
                onSelect?.(selected);
                onChange?.(selected.customerId);
                return;
            }
        }

        onChange?.(undefined);
        onSelect?.(undefined);
    };

    useEffect(() => {
        updateQuery(q => {
            if (companyId) {
                q.companyId = companyId;
            }
        });
    }, [updateQuery, companyId]);

    const onSearch = useCallback((val?: string) => {
        updateQuery(q => {
            q.search = val;
        });
    }, [updateQuery]);

    useEffect(() => {
        if (!value) {
            return;
        }

        if (customers.length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [customers]);

    const selectProps = {
        ...rest,
        placeholder: t("Customer"),
        busy: loading,
        options: customerList,
        onSearch: onSearch,
        showClear: true,
        isValid: isValid
    }

    return <>
        {isMulti && <SelectInput<string> isMulti value={value} onChange={onChangedMulti} {...selectProps} />}
        {!isMulti && <SelectInput value={value} onChange={onChangedSingle} {...selectProps} />}
    </>;
}

export default CustomerSelect;