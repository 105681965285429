import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import { Container } from "reactstrap";
import { useLoaderData } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ResolveWrapper from "Components/Common/ResolveWrapper";
import EditForm from "./_EditForm";
import View from "./_View";
import { FbaShipmentContract } from "api/types/contracts/shipping";
import type { InvoiceContract } from "api/types/contracts/payment";

type InvoicePageProps = {
    create?: boolean
}

type LoaderData = {
    fbaShipment: Promise<FbaShipmentContract>,
    invoice: Promise<InvoiceContract>
} | undefined;

const InvoicePage = (props: InvoicePageProps) => {
    const { t } = useTranslation();
    const loaderData = useLoaderData() as LoaderData;

    return <>
        <div className="page-content">
            <Container fluid>
                <TitleBreadcrumb active={t("Invoice")} parents={[t("FBA Prep & Shipping")]} />
                {props.create ? <>
                    <ResolveWrapper promise={loaderData?.fbaShipment}>
                        {(resolvedFbaShipment) => <>
                            <EditForm fbaShipment={resolvedFbaShipment} />
                        </>}
                    </ResolveWrapper>
                </> : <>
                    <ResolveWrapper promise={loaderData?.invoice}>
                        {(resolvedInvoice) => <>
                            <View invoice={resolvedInvoice} />
                        </>}
                    </ResolveWrapper>
                </>}
            </Container>
        </div>
    </>;
}

export default InvoicePage;