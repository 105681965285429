import { UserContract } from "./users";

export const PrintOrientationList = ["portrait", "landscape"] as const;
export const LocationLabelArrowDirectionList = ["up", "down"] as const;
export const ShippingLabelCustomFieldList = ["orderNumber", "sku1", "sku2", "sku3", "quantityAndSku1", "quantityAndSku2", "quantityAndSku3", "productName1", "productName2", "productName3"] as const;
export type PrintOrientation = typeof PrintOrientationList[number];
export type ShippingLabelCustomField = typeof ShippingLabelCustomFieldList[number];
export type LocationLabelArrowDirection = typeof LocationLabelArrowDirectionList[number];
export type PrintTemplateType = "other" | "shippingLabel" | "fnskuLabel" | "invoice" | "pickList" | "packingSlip" | "binLocationLabel" | "binLabel" | "locationLabel";

export type DesktopClientInstanceContract = {
    desktopClientInstanceId: string,
    deviceName: string,
    queueName: string,
    operatingSystem: string,
    companyId: string,
    lastOnlineAt: Date,
    createdAt: Date,
    owner: UserContract | undefined,
    clientPrinters: ClientPrinterContract[]
};

export type ClientPrinterContract = {
    clientPrinterId: string,
    displayName: string,
    name: string,
    isShared: boolean,
    isDisabled: boolean,
    isConnected: boolean,
    createdAt: Date,
    owner: UserContract | undefined,
    desktopClientInstance: DesktopClientInstanceContract
};

export type PrinterInfo = {
    name: string,
    displayName: string
};

export type BinLabelPrintPreferences = {
    showBinType: boolean
};

export type ShippingLabelPrintPreferences = {
    enableCustomMessages: boolean,
    field1: ShippingLabelCustomField | undefined,
    field2: ShippingLabelCustomField | undefined,
    field3: ShippingLabelCustomField | undefined
};

export type FnskuLabelPrintPreferences = {
    includeExpirationDate: boolean
};

export type InvoicePrintPreferences = {

};

export type PickListPrintPreferences = {

};

export type PackingSlipPrintPreferences = {

};

export type BinLocationLabelPrintPreferences = {
    showArrow: boolean,
    arrowDirection: LocationLabelArrowDirection
};

export type LocationLabelPrintPreferences = {

};

export type PrintPreferenceContract<TPreference = Record<string, string>> = {
    printPreferenceId: string,
    templateType: PrintTemplateType,
    preferences: TPreference,
    orientation: PrintOrientation,
    createdAt: Date,
    owner: UserContract | undefined,
    printSize: PrintSizeContract,
    preferredClientPrinter: ClientPrinterContract | undefined
};

export type PrintSizeContract = {
    printSizeId: string,
    name: string,
    width: number,
    height: number,
    templateType: PrintTemplateType
};

export type PrintingSetupContract = {
    binLabel: PrintPreferenceContract<BinLabelPrintPreferences>,
    shippingLabel: PrintPreferenceContract<ShippingLabelPrintPreferences>,
    fnskuLabel: PrintPreferenceContract<FnskuLabelPrintPreferences>,
    invoice: PrintPreferenceContract<InvoicePrintPreferences>,
    pickList: PrintPreferenceContract<PickListPrintPreferences>,
    packingSlip: PrintPreferenceContract<PackingSlipPrintPreferences>,
    binLocationLabel: PrintPreferenceContract<BinLocationLabelPrintPreferences>,
    locationLabel: PrintPreferenceContract<LocationLabelPrintPreferences>
};

export type PrintResultContract = {
    printQueued: boolean,
    filePath: string
};