import React, { PropsWithChildren, useCallback, useEffect, useState } from 'react';

//import Components
import Header from './Header';
import Sidebar from './Sidebar';
import Footer from './Footer';
// import RightSidebar from '../Components/Common/RightSidebar';

//import actions
import {
    changeLayout,
    changeSidebarTheme,
    changeLayoutMode,
    changeLayoutWidth,
    changeLayoutPosition,
    changeTopbarTheme,
    changeLeftsidebarSizeType,
    changeLeftsidebarViewType,
    changeSidebarImageType,
    changeSidebarVisibility
} from "../slices/thunks";

import { useAppDispatch, useAppSelector } from 'Components/Hooks/StoreHooks';
import Preloader from 'Components/Preloader';
import { useTenant } from 'Components/Hooks/TenantHooks';
import Offline from 'pages/Errors/Offline';

type MainLayoutProps = PropsWithChildren & {

}

const MainLayout = (props: MainLayoutProps) => {
    const [headerClass, setHeaderClass] = useState("");
    const dispatch = useAppDispatch();
    const { loadingState } = useTenant();

    const layout = useAppSelector(
        (state) => ({
            ...state.Layout
        })
    );

    /*
    layout settings
    */
    useEffect(() => {
        if (
            layout.layoutType ||
            layout.leftSidebarType ||
            layout.layoutModeType ||
            layout.layoutWidthType ||
            layout.layoutPositionType ||
            layout.topbarThemeType ||
            layout.leftsidbarSizeType ||
            layout.leftSidebarViewType ||
            layout.leftSidebarImageType ||
            layout.sidebarVisibilitytype
        ) {
            window.dispatchEvent(new Event('resize'));
            
            dispatch(changeLeftsidebarViewType(layout.leftSidebarViewType));
            dispatch(changeLeftsidebarSizeType(layout.leftsidbarSizeType));
            dispatch(changeSidebarTheme(layout.leftSidebarType));
            dispatch(changeLayoutMode(layout.layoutModeType));
            dispatch(changeLayoutWidth(layout.layoutWidthType));
            dispatch(changeLayoutPosition(layout.layoutPositionType));
            dispatch(changeTopbarTheme(layout.topbarThemeType));
            dispatch(changeLayout(layout.layoutType));
            dispatch(changeSidebarImageType(layout.leftSidebarImageType));
            dispatch(changeSidebarVisibility(layout.sidebarVisibilitytype));
        }
    }, [layout, dispatch]);

    const scrollNavigation = useCallback(() => {
        var scrollup = document.documentElement.scrollTop;
        if (scrollup > 50) {
            setHeaderClass("topbar-shadow");
        } else {
            setHeaderClass("");
        }
    }, [setHeaderClass]);

    // class add remove in header 
    useEffect(() => {
        window.addEventListener("scroll", scrollNavigation, true);

        return () => {
            window.removeEventListener("scroll", scrollNavigation, true);
        }
    });

    useEffect(() => {
        const humberIcon = document.querySelector(".hamburger-icon");
        
        if (layout.sidebarVisibilitytype === 'show' || layout.layoutType === "vertical" || layout.layoutType === "twocolumn") {
            humberIcon?.classList.remove('open');
        } else {
            humberIcon?.classList.add('open');
        }
    }, [layout.sidebarVisibilitytype, layout.layoutType]);

    return (
        <>
        {(loadingState === "loading" || loadingState === "notloaded") && <Preloader />}
        {loadingState === "failed" ? <Offline /> : 
            <div id="layout-wrapper">
                <Header headerClass={headerClass} />
                <Sidebar />
                <div className="main-content">
                    { props.children }
                    <Footer />
                </div>
            </div>}
        </>
    );
};

export default MainLayout;