import { getPrinterList, getPrinterPreferences, getPrintSizeList, patchClientPrinter, patchPrinterPreferences, patchPrintPreferences, postTestPrint } from "api/printing";
import type { GetPrinterPreferencesQuery, ListClientPrintersQuery, ListPrintSizesQuery } from "api/types/queries";
import type { AppDispatch, RootState } from "slices";
import { setLoading, setPrinterPreferences, setPrinters, setPrintSizes } from "./reducer";
import type { PrintTestPageCommand, SavePrinterPreferenceCommand, SavePrintPreferencesCommand, UpdateClientPrinterCommand } from "api/types/commands";
import { toast } from "react-toastify";
import i18n from "i18n";

export const loadPrinterList = (params: ListClientPrintersQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    if (getState().Printers.loading.list) {
        return;
    }

    try {
        dispatch(setLoading(["list", true]));

        const result = await getPrinterList(params);

        dispatch(setPrinters(result));
    } catch (e) {
        
    }
    finally {
        dispatch(setLoading(["list", false]));
    }
};

export const printTestPage = (params: PrintTestPageCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(setLoading(["test", true]));

        const result = await postTestPrint(params);
    } catch (e) {
        
    }
    finally {
        dispatch(setLoading(["test", false]));
    }
}

export const loadPrintingPreferences = (params: GetPrinterPreferencesQuery, slient?: boolean) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        if (!slient) {
            dispatch(setLoading(["preferenceList", true]));
        }

        const result = await getPrinterPreferences(params);

        dispatch(setPrinterPreferences(result));
    } catch (e) {
        
    }
    finally {
        if (!slient) {
            dispatch(setLoading(["preferenceList", false]));
        }
    }
}

export const loadPrintSizes = (params: ListPrintSizesQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(setLoading(["printSizeList", true]));

        const result = await getPrintSizeList(params);

        dispatch(setPrintSizes(result));
    } catch (e) {
        
    }
    finally {
        dispatch(setLoading(["printSizeList", false]));
    }
}

export const savePrintingPreferences = (params: SavePrintPreferencesCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(setLoading(["preferenceSave", true]));

        await patchPrintPreferences(params);

        toast.success(i18n.t("Printing preference saved"));
    } catch (e) {
        
    }
    finally {
        dispatch(setLoading(["preferenceSave", false]));
    }
}

export const savePrinterPreference = (params: SavePrinterPreferenceCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(setLoading(["preferenceSave", true]));

        await patchPrinterPreferences(params);

        toast.success(i18n.t("Printer preference saved"));
    } catch (e) {
        
    }
    finally {
        dispatch(setLoading(["preferenceSave", false]));
    }
}

export const updatePrinter = (params: UpdateClientPrinterCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(setLoading(["savePrinter", true]));

        await patchClientPrinter(params);

        const result = await getPrinterList(params);

        dispatch(setPrinters(result)); 
    } catch (e) {
        
    }
    finally {
        dispatch(setLoading(["savePrinter", false]));
    }
}