import BusyOverlay from "Components/Common/BusyOverlay";
import RequiredLabel from "Components/Common/RequiredLabel";
import Restricted from "Components/Common/Restricted";
import ProductCategorySelect from "Components/EntitySelects/ProductCategorySelect";
import Checkbox from "Components/Form/Checkbox";
import ValidatedInput from "Components/Form/Validated/ValidatedInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { ProductSizeContract, ProductSizeForm } from "api/types/contracts/products";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import { useTranslation } from "react-i18next";
import { Col, Container, Form, Label, Row } from "reactstrap";
import { createProductSize, updateProductSize } from "slices/product/size/thunk";
import * as Yup from "yup";

type FormProps = {
    productSize?: ProductSizeContract,
    onSuccess: (productSizeId: string) => void
}

const defaultForm: Partial<ProductSizeForm> = {
    isStandart: false
};

const createProductSizeForm = (productSize: ProductSizeContract): ProductSizeForm => {
    return {
        isStandart: productSize.isStandart,
        name: productSize.name,
        relatedCategoryId: productSize.relatedCategories?.[0]?.categoryId,
    };
};

const SizeForm = (props: FormProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const product = props.productSize ? createProductSizeForm(props.productSize) : defaultForm;

    const { loading, error } = useAppSelector(
        (state) => ({
            error: state.ProductSize.error,
            loading: state.ProductSize.loading
        })
    );

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: product,
        validationSchema: Yup.object<ProductSizeForm, TypedShape<ProductSizeForm>>({
            name: Yup.string().required(t("Name is required")),
            isStandart: Yup.boolean().required(),
            relatedCategoryId: Yup.string().nullable()
        }),
        onSubmit: async (values) => {
            const productSize = values as ProductSizeForm;
            const productSizeId = props.productSize?.sizeId;
            
            if (productSizeId) {
                const success = await dispatch(updateProductSize({
                    productSizeId: productSizeId,
                    productSize
                }));

                if (success) {
                    props.onSuccess(productSizeId);
                }
            }
            else {
                const created = await dispatch(createProductSize({
                    productSize
                }));

                if (created) {
                    props.onSuccess(created.sizeId)
                }
            }
        },
    });

    return <>
        <Form className="needs-validation form-steps" action="#" onSubmit={validation.handleSubmit}>
            <Container>
                <Row>
                    <Col className="g-3">
                        <RequiredLabel>{t("Name")}</RequiredLabel>
                        <ValidatedInput validation={validation} field="name" />
                    </Col>
                    <Restricted require="management.system" create={!props.productSize} write={!!props.productSize}>
                        <Col xs="auto" className="g-3 d-flex align-items-end pb-2">
                            <ValidationWrapper validation={validation} field="isStandart">
                                <Checkbox>{t("Is Standart")}</Checkbox>
                            </ValidationWrapper>
                        </Col>
                    </Restricted>
                    <Col xs={12} className="g-3">
                        <Label>{t("Related Category")}</Label>
                        <ValidationWrapper validation={validation} field="relatedCategoryId">
                            <ProductCategorySelect showClear />
                        </ValidationWrapper>
                    </Col>
                    <Row>
                        <Col xs="auto" className="g-3 ms-auto">
                            <BusyOverlay busy={loading.save} size="sm">
                                <ValidatorButton validation={validation} color="primary" type="submit">
                                    {t("Save")}
                                </ValidatorButton>
                            </BusyOverlay>
                        </Col>
                    </Row>
                </Row>
            </Container>
        </Form>
    </>;
}

export default SizeForm;
