import type { CreditCardContract, PaymentMethod } from "api/types/contracts/payment";
import type { WarehouseContract } from "api/types/contracts/warehouses";
import { InfoAlert } from "Components/Alerts";
import { useFormik } from "formik";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardHeader, Col, Form, Label, Row } from "reactstrap";
import CreditCardSelect from "./_CreditCardSelect";
import * as Yup from "yup";
import { useMutation } from "@tanstack/react-query";
import { postLoadCreditWithStripe, postRequestCredit } from "api/payment";
import RequiredLabel from "Components/Common/RequiredLabel";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import CurrencyInput from "Components/Form/UnitInputs/CurrencyInput";
import Currency from "Components/Displays/UnitDisplay/Currency";
import ValidatedFileUpload from "Components/Form/Validated/ValidatedFileUpload";
import BusyOverlay from "Components/Common/BusyOverlay";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { useProfile } from "Components/Hooks/ProfileHooks";
import WiseInfo from "./_WiseInfo";
import PayoneerInfo from "./_PayoneerInfo";
import WireTransferInfo from "./_WireTransferInfo";
import ZelleInfo from "./_ZelleInfo";

type AddCreditFormProps = {
    warehouse: WarehouseContract,
    paymentMethod: PaymentMethod,
}

const AddCreditForm = (props: AddCreditFormProps) => {
    const { t } = useTranslation();
    const [selectedCard, setSelectedCard] = useState<CreditCardContract>();
    const { customer } = useProfile();

    const processingFee = useMemo(() => {
        switch (props.paymentMethod) {
            case "stripe":
                return props.warehouse.settings.stripe?.processingFee ?? 0;
            case "payoneer":
                return props.warehouse.settings.payoneer?.processingFee ?? 0;
            case "wireTransfer":
                return props.warehouse.settings.wireTransfer?.processingFee ?? 0;
            case "wise":
                return props.warehouse.settings.wise?.processingFee ?? 0;
            case "zelle":
                return props.warehouse.settings.zelle?.processingFee ?? 0;
            default:
                return 0;
        }
    }, [props.paymentMethod, props.warehouse.settings]);

    const loadCreditWithStripeMutation = useMutation({
        mutationFn: postLoadCreditWithStripe
    });

    const requestCreditMutation = useMutation({
        mutationFn: postRequestCredit
    });

    const validation = useFormik({
        initialValues: {
            amount: 0,
            proofOfPayment: undefined
        },
        validationSchema: Yup.object({
            amount: Yup.number().min(0).required(t("Amount is required"))
        }),
        onSubmit: values => {
            if (props.paymentMethod === "stripe") {
                loadCreditWithStripeMutation.mutate({
                    warehouseId: props.warehouse.warehouseId,
                    stripeCardId: "",
                    amount: values.amount
                });
            }
            else {
                requestCreditMutation.mutate({
                    warehouseId: props.warehouse.warehouseId,
                    paymentMethod: props.paymentMethod,
                    amount: values.amount,
                    proofOfPayment: values.proofOfPayment
                })
            }
        }
    });

    return <div className="pb-3">
        <Row>
            <Col xs={6}>
                <Card className="shadow-lg h-100 mb-0">
                    <CardHeader className="border-bottom-0 pb-0">
                        <h5 className="card-title">{t(`enums:PaymentMethod.${props.paymentMethod}`)}</h5>
                    </CardHeader>
                    <CardBody>
                        {props.paymentMethod === "stripe" && <CreditCardSelect value={selectedCard?.creditCardId} onSelect={setSelectedCard} />}
                        {props.paymentMethod === "wise" && <WiseInfo warehouse={props.warehouse} />}
                        {props.paymentMethod === "payoneer" && <PayoneerInfo warehouse={props.warehouse} />}
                        {props.paymentMethod === "wireTransfer" && <WireTransferInfo warehouse={props.warehouse} />}
                        {props.paymentMethod === "zelle" && <ZelleInfo warehouse={props.warehouse} />}
                    </CardBody>
                </Card>
            </Col>
            <Col xs={6}>
                <Card className="shadow-lg h-100 mb-0">
                    <CardHeader className="border-bottom-0 pb-0">
                        <h5 className="card-title">{t("Add Credit")}</h5>
                    </CardHeader>
                    <CardBody>
                        {processingFee > 0 && <InfoAlert kind="top-border">
                            {t("paragraphs:AddCreditProcessingFeeStatement", { fee: processingFee, paymentType: t(`enums:PaymentMethod.${props.paymentMethod}`) })}
                        </InfoAlert>}

                        <Form onSubmit={validation.handleSubmit}>
                            <div className="vstack gap-4">
                                <div>
                                    <RequiredLabel>{t("Amount")}</RequiredLabel>
                                    <ValidationWrapper validation={validation} field="amount">
                                        <CurrencyInput currency={props.warehouse.settings.unitOfCurrency} />
                                    </ValidationWrapper>
                                </div>
                                <Row>
                                    <Col xs="auto">
                                        <span>{t("Amount")}</span>
                                    </Col>
                                    <Col xs="auto" className="ms-auto">
                                        <Currency value={validation.values.amount} currency={props.warehouse.settings.unitOfCurrency} />
                                    </Col>
                                </Row>
                                {processingFee > 0 && <Row>
                                    <Col xs="auto">
                                        <span>{t("Processing Fee ({{fee}})", { fee: processingFee })}</span>
                                        <p>{t("paragraphs:AddCreditProcessingFeeDescription", { fee: processingFee })}</p>
                                    </Col>
                                    <Col xs="auto" className="ms-auto">
                                        <Currency value={processingFee} currency={props.warehouse.settings.unitOfCurrency} />
                                    </Col>
                                </Row>}
                                <Row>
                                    <Col xs="auto">
                                        <span>{t("Total Charge")}</span>
                                    </Col>
                                    <Col xs="auto" className="ms-auto">
                                        <Currency value={validation.values.amount + processingFee} currency={props.warehouse.settings.unitOfCurrency} />
                                    </Col>
                                </Row>
                                {props.paymentMethod !== "stripe" && <>
                                    <Row>
                                        <Col>
                                            <Label>{t("Upload Proof of Payment")} ({t("Optional")})</Label>
                                            <ValidatedFileUpload validation={validation} field="proofOfPayment" />
                                        </Col>
                                    </Row>
                                </>}
                                <Row>
                                    <Col>
                                        <BusyOverlay busy={loadCreditWithStripeMutation.isPending || requestCreditMutation.isPending} size="sm" inline>
                                            <ValidatorButton type="submit" color="primary" disabled={!customer?.invoiceAddress} validation={validation}>
                                                {t("Confirm Payment")}
                                            </ValidatorButton>
                                        </BusyOverlay>
                                    </Col>
                                </Row>
                            </div>
                        </Form>
                    </CardBody>
                </Card>
            </Col>
        </Row>
    </div>;
}

export default AddCreditForm;