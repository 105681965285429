import { useMetadata } from "Components/Hooks/MetadataHooks";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Form, InputGroup, Label, Row, Table } from "reactstrap";
import LocationSelect from "./_LocationSelect";
import TextInput from "Components/Form/TextInput";
import { useFormik } from "formik";
import * as Yup from "yup";
import { AssignPutawayBinCommand } from "api/types/commands";
import { TypedShape } from "helpers/types";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import BusyOverlay from "Components/Common/BusyOverlay";
import { assignPutawayBin, loadPutawayBinHistory, undoLocationHistory } from "slices/inventory/thunk";
import { useEffect, useMemo, useRef, useState } from "react";
import { locationOf } from "helpers/locationOf";
import WarehouseSelect from "Components/EntitySelects/WarehouseSelect";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import { LocationHistoryContract } from "api/types/contracts/inventory";
import _ from "lodash";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";

type BinPutawayProps = {
    
};

const focusOnBinOrBoxLabelInput = () => document.querySelector<HTMLInputElement>("#binOrBoxLabel")!.focus();

const BinPutaway = (props: BinPutawayProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { setPageTitle } = useMetadata();
    const dialogRef = useRef<DialogRef>(null);
    const [selectedHistory, setSelectedHistory] = useState<LocationHistoryContract>();

    setPageTitle(`${t("Putaway Bin")} - ${t("Management")}`);

    const { loading, lastbinPutaways } = useAppSelector(
        (state) => ({
            loading: state.Inventory.loading,
            error: state.Inventory.error,
            lastbinPutaways: state.Inventory.lastBinPutaways
        })
    );

    const list = useMemo(() => _.chain(lastbinPutaways)
        .groupBy(x => x.bin?.binId ?? x.box?.boxId ?? x.productLabel?.productLabelId)
        .flatMap((x, key) => {
            return [{
                ...x[0],
                lastEntry: true
            }, ...x.slice(1).map(y => ({
                ...y,
                lastEntry: false
            }))];
        })
        .value(), [lastbinPutaways]);

    useEffect(() => {
        dispatch(loadPutawayBinHistory());
    }, [dispatch]);

    const validation = useFormik({
        initialValues: {
            binTargetId: undefined,
            binLocationTargetId: undefined,
            binOrBoxLabel: ""
        } as AssignPutawayBinCommand,
        validationSchema: Yup.object<AssignPutawayBinCommand, TypedShape<AssignPutawayBinCommand>>({
            warehouseId: Yup.string().required(t("You must select a warehouse")),
            binTargetId: Yup.string().notRequired().when("binLocationTargetId", ([binLocationTargetId], schema) => binLocationTargetId ? schema : schema.required(t("You must select a bin or bin location"))),
            binLocationTargetId: Yup.string().notRequired(),
            binOrBoxLabel: Yup.string().required(t("You must enter a bin or box label"))
        }),
        onSubmit: async values => {
            await dispatch(assignPutawayBin(values));
            await validation.setFieldValue("binOrBoxLabel", "");
            await validation.setFieldTouched("binOrBoxLabel", false);

            await dispatch(loadPutawayBinHistory());
        }
    });

    const undoHistory = (history: LocationHistoryContract) => {
        setSelectedHistory(history);
        dialogRef.current?.show();
    }

    return <>
        <h5>{t("Bin Putaway")}</h5>
        <p className="text-muted">{t("paragraphs:BinPutawayDescription")}</p>
        <Card body>
            <Form onSubmit={validation.handleSubmit} className="">
                <Row className="g-3">
                    <Col xl={3}>
                    <Label className="mb-2">{t("Warehouse")}</Label>
                        <ValidationWrapper validation={validation} field="warehouseId">
                            <WarehouseSelect />
                        </ValidationWrapper>
                    </Col>
                    <Col xl={9}>
                        <Label className="mb-2">{t("Bin / Bin Location")}</Label>
                        <LocationSelect warehouseId={validation.values.warehouseId} onSelect={location => {
                            if (!location) {
                                validation.setFieldValue("binTargetId", undefined);
                                validation.setFieldValue("binLocationTargetId", undefined);
                            }
                            else if ("binId" in location) {
                                validation.setFieldValue("binTargetId", location.binId);
                                validation.setFieldValue("binLocationTargetId", undefined);
                                focusOnBinOrBoxLabelInput();
                            }
                            else {
                                validation.setFieldValue("binTargetId", undefined);
                                validation.setFieldValue("binLocationTargetId", location.binLocationId);
                                focusOnBinOrBoxLabelInput();
                            }
                        }} />
                    </Col>
                    <Col xs={6}>
                        <Label className="mb-2">{t("Bin / Box")}</Label>
                        <InputGroup>
                            <ValidationWrapper validation={validation} field="binOrBoxLabel">
                                <TextInput id="binOrBoxLabel" />
                            </ValidationWrapper>
                            <BusyOverlay busy={loading.putaway} size="sm">
                                <ValidatorButton validation={validation} type="submit" color="secondary">{t("Putaway")}</ValidatorButton>
                            </BusyOverlay>
                        </InputGroup>
                    </Col>
                </Row>
            </Form>
        </Card>
        <Card>
            <CardHeader>
                <CardTitle tag="h6" className="mb-0">{t("Last 10 Bin Putaways")}</CardTitle>
            </CardHeader>
            <CardBody className="">
                <div className="table-responsive table-card">
                    <Table className="mb-0 align-middle">
                        <thead className="table-light text-muted">
                            <tr>
                                <th>{t("Action")}</th>
                                <th>{t("Bin / Box Label")}</th>
                                <th>{t("Previous Location")}</th>
                                <th>{t("New Location")}</th>
                            </tr>
                        </thead>
                        <tbody>
                            {list.length > 0 ? list.map((history, index) => <tr key={index}>
                                <td>
                                    <div className="d-flex gap-1">
                                        <Button type="button" color="warning" size="sm" onClick={() => undoHistory(history)}>
                                            {t("Undo")}
                                        </Button>
                                    </div>
                                </td>
                                <td>
                                    {history.bin ? <>
                                        <strong>{t("Bin")}: </strong> #{history.bin.binCode}
                                    </> : history.box ? <>
                                        <strong>{t("Box")}: </strong> #{history.box.boxNumber}
                                    </> : ""}
                                </td>
                                <td>
                                    {history.oldBinTarget ? 
                                        `${locationOf(history.oldBinTarget)} #${history.oldBinTarget?.binCode}` 
                                    :
                                    history.oldBinLocationTarget ? 
                                        `${locationOf(history.oldBinLocationTarget)}-${history.oldBinLocationTarget?.name}` 
                                    : "-"}
                                </td>
                                <td>
                                    {history.binTarget ? 
                                        `${locationOf(history.binTarget)} #${history.binTarget?.binCode}` 
                                    :
                                    history.binLocationTarget ? 
                                        `${locationOf(history.binLocationTarget)}-${history.binLocationTarget?.name}` 
                                    : "-"}
                                </td>
                            </tr>) : <tr>
                                <td colSpan={4} className="text-center text-muted">{t("No putaway history")}</td>
                            </tr>}
                        </tbody>
                    </Table>
                </div>
            </CardBody>
        </Card>
        <Dialog ref={dialogRef} color="warning" buttons={["yes", "no"]} busy={loading.historyUndo || loading.list} iconClass="ri-arrow-go-back-line"  
            message={`Do you want to continue?`} title={t("Undone location changes for '{{item}}'", { item: selectedHistory?.bin?.binCode || selectedHistory?.box?.boxNumber })}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    dispatch(undoLocationHistory({ locationHistoryId: selectedHistory!.locationHistoryId }))
                        .then(() => dispatch(loadPutawayBinHistory()))
                        .then(() => hide());
                }
                else {
                    hide();
                }
            }} />
    </>;
}

export default BinPutaway;