import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import { useRef } from "react";
import { useTranslation } from "react-i18next";
import {  useLoaderData, useNavigate } from "react-router-dom";
import { Container, Modal, ModalBody } from "reactstrap";
import List, { ProductListRef } from "./_List";
import ProductEditForm from "../../Components/Shared/ProductEditForm";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import ResolveWrapper from "Components/Common/ResolveWrapper";
import { ProductContract } from "api/types/contracts/products";

type ProductsPageProps = {
    edit?: boolean
}

type LoaderData = {
    product: Promise<ProductContract>
} | undefined;

const ProductsPage = (props: ProductsPageProps) => {
    const loaderData = useLoaderData() as LoaderData;
    const { t } = useTranslation();
    const navigate = useNavigate();
    const listRef = useRef<ProductListRef>(null);

    return <>
        <div className="page-content">
            <Container fluid>
                <TitleBreadcrumb active={t("Products")} parents={[t("Inventory")]} />
                <List ref={listRef} />
                {props.edit && 
                    <Modal size="xl" isOpen toggle={() => navigate("/products")} unmountOnClose>
                        <ModalBody className="p-0">
                            <ResolveWrapper promise={loaderData?.product}>
                                {(resolvedProduct) => <>
                                    <ModalCloseButton onClick={() => navigate("/products")} />
                                    <ProductEditForm product={resolvedProduct} onSuccess={() => {
                                        navigate("/products");
                                        listRef.current?.reload();
                                    }} />
                                </>}
                            </ResolveWrapper>
                        </ModalBody>
                    </Modal>
                }
            </Container>
        </div>
    </>;
}

export default ProductsPage;