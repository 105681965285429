import ValidatedInput from "Components/Form/Validated/ValidatedInput";
import { Col, Form, Label, Row } from "reactstrap";
import { CustomerContract, CustomerForm } from "api/types/contracts/customers";
import { useTranslation } from "react-i18next";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import * as Yup from "yup";
import { updateCustomer } from "slices/customer/thunk";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import CountrySelect from "Components/EntitySelects/CountrySelect";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import BusyOverlay from "Components/Common/BusyOverlay";
import WarehouseSelect from "Components/EntitySelects/WarehouseSelect";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";

type FormProps = {
    customer: CustomerContract,
    onSuccess?: VoidFunction
}

const createCustomerForm = (customer: CustomerContract): CustomerForm => {
    return {
        name: customer.name,
        surname: customer.surname,
        email: customer.user.email,
        firmName: customer.firmName,
        phone: customer.phone,
        countryCode: customer.country.code,
        availableWarehouses: customer.properties.availableWarehouses
    }
};

const UpdateForm = (props: FormProps) => {
    const customer = createCustomerForm(props.customer);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { loading, error } = useAppSelector(
        (state) => ({
            error: state.Customer.error,
            loading: state.Customer.loading
        })
    );

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: customer,
        validationSchema: Yup.object<CustomerForm, TypedShape<CustomerForm>>({
            name: Yup.string().required(t("Name is required")),
            surname: Yup.string().required(t("Surname is required")),
            email: Yup.string().required(t("Email is required")),
            firmName: Yup.string().notRequired(),
            phone: Yup.string().required(t("Phone is required")),
            countryCode: Yup.string().required(t("Country is required")),
            availableWarehouses: Yup.array().required(t("Warehouses are required")),
        }),
        onSubmit: async (values) => {
            const success = await dispatch(updateCustomer({
                customerId: props.customer.customerId,
                customer: values
            }));

            if (success) {
                props.onSuccess?.();
            }
        },
    });

    return <>
        <Form onSubmit={validation.handleSubmit} className="needs-validation">
            <Row>
                <Col lg={6}>
                    <Row>
                        <Col lg={6} className="g-3">
                            <Label htmlFor="name">First Name</Label>
                            <ValidatedInput validation={validation} field="name" />
                        </Col>
                        <Col lg={6} className="g-3">
                            <Label htmlFor="surname">Surname</Label>
                            <ValidatedInput validation={validation} field="surname" />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="g-3">
                            <Label htmlFor="firmName">Company</Label>
                            <ValidatedInput validation={validation} field="firmName" />
                        </Col>
                    </Row>
                    <Row>
                        <Col className="g-3">
                            <Label htmlFor="phone">Phone</Label>
                            <ValidatedInput validation={validation} field="phone" />
                        </Col>
                    </Row>
                </Col>
                <Col lg={6}>
                    <Row>
                        <Col className="g-3">
                            <Label htmlFor="countryCode">Country</Label>
                            <ValidationWrapper validation={validation} field="countryCode">
                                <CountrySelect />
                            </ValidationWrapper>
                        </Col>
                    </Row>
                    <Row>
                        <Col className="g-3">
                            <Label htmlFor="availableWarehouses">Warehouses</Label>
                            <ValidationWrapper validation={validation} field="availableWarehouses">
                                <WarehouseSelect isMulti />
                            </ValidationWrapper>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <Col lg={12} className="mt-auto">
                    <div className="hstack gap-2 justify-content-end">
                        <BusyOverlay busy={loading.save} size="sm">
                            <ValidatorButton type="submit" className="btn btn-primary" validation={validation}>{t("Save")}</ValidatorButton>
                        </BusyOverlay>
                    </div>
                </Col>
            </Row>
        </Form>
    </>;
}

export default UpdateForm;