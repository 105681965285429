import { Maybe } from "helpers/types";
import { CarrierContract } from "./carrier";
import { CustomerContract } from "./customers";
import { ProductContract, ProductSupplierContract } from "./products";
import { UserContract } from "./users";
import { WarehouseContract } from "./warehouses";

export const InboundStatusList = ["draft", "confirmed", "checkIn", "receiving", "completed"] as const;
export const ShippingPurposeList = ["storage", "fba", "dropship"] as const;
export const TrackingStatusList = ["waiting", "completed"] as const;

export type InboundStatus = typeof InboundStatusList[number];
export type ShippingPurpose = typeof ShippingPurposeList[number];
export type TrackingStatus = typeof TrackingStatusList[number];

export type InboundContract = {
    inboundId: string,
    inboundCode: string,
    shippingPurpose: ShippingPurpose,
    notes?: string,
    estimatedArrivalDate?: Date,
    inboundStatus: InboundStatus,
    createdAt: Date,
    checkInDate?: Date,
    completionDate?: Date,
    owner: UserContract,
    warehouse: WarehouseContract,
    fromAmazon: boolean,
    trackings: InboundTrackingContract[],
    items: InboundItemContract[],
    suppliers: ProductSupplierContract[],
    customer?: CustomerContract
}

export type InboundTrackingContract = {
    inboundTrackingId: string,
    trackingNumber: string,
    status: TrackingStatus,
    createdAt: Date,
    carrier: CarrierContract
}

export type InboundItemContract = {
    quantity: number,
    received: number,
    product: ProductContract
}

export type InboundForm = {
    inboundCode: string,
    shippingPurpose: ShippingPurpose,
    notes: Maybe<string>,
    estimatedArrivalDate: Maybe<Date>,
    warehouseId: string,
    items: InboundFormItem[],
    trackings: InboundFormTracking[],
    suppliers: Maybe<string[]>,
    fromAmazon: boolean,
    isDraft: boolean
}

export type InboundFormItem = {
    productId: string,
    quantity: number
}

export type InboundFormTracking = {
    trackingNumber: string,
    carrierId: string
}