import { InfoAlert } from "Components/Alerts"
import TrackingStatusBadge from "Components/Displays/TrackingStatusBadge"
import { useAppSelector } from "Components/Hooks/StoreHooks"
import { useTranslation } from "react-i18next"
import { Table } from "reactstrap"

type TrackingsProps = {

}

const Trackings = (props: TrackingsProps) => {
    const { t } = useTranslation();
    const { loading, inbound, error } = useAppSelector(
        (state) => ({
            error: state.Inbound.error,
            inbound: state.Inbound.processingItem!,
            loading: state.Inbound.loading
        })
    ); 

    return <>
        {inbound.trackings.length > 0 ? <>
            <div className="table-responsive table-card">
                <Table striped borderless className="align-middle no-wrap mb-0">
                    <thead>
                        <tr>
                            <th>{t("Tracking Number")}</th>
                            <th>{t("Carrier")}</th>
                            <th>{t("Status")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {inbound.trackings.map((tracking, index) => {
                            return <tr key={index}>
                                <td>{tracking.trackingNumber}</td>
                                <td>{tracking.carrier.name}</td>
                                <td><TrackingStatusBadge value={tracking.status} /></td>
                            </tr>;
                        })}
                    </tbody>
                </Table>
            </div>
        </>
        : <>
            <InfoAlert className="mb-0">{t("No trackings found")}</InfoAlert>
        </>}
    </>
}

export default Trackings;
