import { BayContract } from "api/types/contracts/locations";
import ShelfItem from "./_ShelfItem";
import { WarningAlert } from "Components/Alerts";
import { useTranslation } from "react-i18next";

type GridProps = {
    bay: BayContract,
    mini?: boolean
}

const Grid = (props: GridProps) => {
    const { t } = useTranslation();

    return <div className="shelf-grid rounded shadow shadow-lg flex-shrink-0 pb-2">
        {props.bay.shelves && props.bay.shelves.length > 0 ? 
            <div className="vstack gap-2">
                {props.bay.shelves.map(shelf => <ShelfItem mini={props.mini} key={shelf.shelfId} shelf={shelf} bay={props.bay} />)}
            </div>
        :
            <WarningAlert>{t("There are no defined shelves yet")}</WarningAlert>
        }
    </div>;
};

export default Grid;