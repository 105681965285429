import { Row, Col, Label, Form } from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import { BinType } from "api/types/contracts/bin";

import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { createBin } from "slices/bins/thunk";
import BusyOverlay from "Components/Common/BusyOverlay";
import { TypedShape } from "helpers/types";
import { DangerAlert } from "Components/Alerts";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import { CreateBinsCommand } from "api/types/commands";
import CompanySelect from "Components/EntitySelects/CompanySelect";
import WarehouseSelect from "Components/EntitySelects/WarehouseSelect";
import NumberInput from "Components/Form/NumberInput";
import { CompanyContract } from "api/types/contracts/companies";
import Restricted from "Components/Common/Restricted";
import BinTypeSelect from "Components/EnumSelects/BinTypeSelect";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";

type FormProps = {
    onSuccess?: VoidFunction
}

type FormType = CreateBinsCommand;

const CreateBinForm = (props: FormProps) => {
    const [company, setCompany] = useState<CompanyContract>();
    const dispatch = useAppDispatch();
    const { setPageTitle } = useMetadata();
    const { t } = useTranslation();

    const { bin } = useAppSelector(
        (state) => ({
            bin: {
                error: state.Bin.error,
                loading: {
                    ...state.Bin.loading
                }
            }
        })
    );

    setPageTitle(`${t("Create Bin")} - ${t("Management")}`);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {} as FormType,
        validationSchema: Yup.object<FormType, TypedShape<FormType>>({
            count: Yup.number().required(t("You must enter bin count")).min(1, t("Bin count must be greater than zero")),
            width: Yup.number().notRequired(),
            height: Yup.number().notRequired(),
            length: Yup.number().notRequired(),
            warehouseId:  Yup.string().required(t("Please select a warehouse")),
            binType: Yup.string<BinType>().required(t("Please select a bin type")),
        }),
        onSubmit: async (values) => {
            const success = await dispatch(createBin({
                ...values
            }));

            if (success) {
                props.onSuccess?.();
            }
        },
    });

    return (
        <>
            <Form className="needs-validation" action="#" onSubmit={validation.handleSubmit}>
                <div>
                    <div className="mb-4">
                        <div>
                            <h5 className="mb-1">{t("Add Bins")}</h5>
                        </div>
                    </div>
                    <Row>
                        <Restricted require={"management.company"} read>
                            <Col lg={4} className="g-3">
                                <Label htmlFor="company">{t("Company")}</Label>
                                <CompanySelect name="company" onSelect={setCompany} />
                            </Col>
                        </Restricted>
                        <Col lg={4} className="g-3">
                            <Label htmlFor="warehouseId">{t("Warehouse")}</Label>
                            <ValidationWrapper validation={validation} field="warehouseId">
                                <WarehouseSelect companyId={company?.companyId} />
                            </ValidationWrapper>
                        </Col>
                        <Col lg={4} className="g-3">
                            <Label htmlFor="count">{t("Bin Count")}</Label>
                            <ValidationWrapper validation={validation} field="count">
                                <NumberInput placeholder="Enter bin count" />
                            </ValidationWrapper>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={3} className="g-3">
                            <Label htmlFor="binType">{t("Type")}</Label>
                            <ValidationWrapper validation={validation} field="binType">
                                <BinTypeSelect />
                            </ValidationWrapper>
                        </Col>
                        <Col lg={3} className="g-3">
                            <Label htmlFor="width">{t("Width ({{unit}})", { unit: "inch" })} ({t("Optional")})</Label>
                            <ValidationWrapper validation={validation} field="width">
                                <NumberInput placeholder="Enter width" />
                            </ValidationWrapper>
                        </Col>
                        <Col lg={3} className="g-3">
                            <Label htmlFor="height">{t("Height ({{unit}})", { unit: "inch" })} ({t("Optional")})</Label>
                            <ValidationWrapper validation={validation} field="height">
                                <NumberInput placeholder="Enter height" />
                            </ValidationWrapper>
                        </Col>
                        <Col lg={3} className="g-3">
                            <Label htmlFor="length">{t("Length ({{unit}})", { unit: "inch" })} ({t("Optional")})</Label>
                            <ValidationWrapper validation={validation} field="length">
                                <NumberInput placeholder="Enter length" />
                            </ValidationWrapper>
                        </Col>
                    </Row>
                </div>
                <div className="mt-4">{bin.error && <DangerAlert>{bin.error.message}</DangerAlert>}</div>
                <div className="d-flex justify-content-end">
                    <BusyOverlay busy={bin.loading.save} size="sm">
                        <ValidatorButton type="submit" validation={validation} className="btn btn-primary btn-label right nexttab">
                            <i className="ri-save-3-line label-icon align-middle fs-16 ms-2"></i>
                            {t("Save")}
                        </ValidatorButton>
                    </BusyOverlay>
                </div>
            </Form>
        </>
    );
};

export default CreateBinForm;
