import { Maybe } from "helpers/types";
import { AddressContract } from "./common";
import { CustomerContract } from "./customers";
import { WarehouseContract } from "./warehouses";
import { UserContract } from "./users";

export const CompanyStatusList = ["active", "passive"] as const;
export const CompanyTypeList = ["regular", "demo"] as const;

export type CompanyStatus = typeof CompanyStatusList[number];
export type CompanyType = typeof CompanyTypeList[number];

export type TenantContract = {
    name: string,
    domain: string,
    isCustomDomain: boolean,
    preferences: TenantPreferences,
};

export type TenantInfo = TenantPreferences & {
    identifier: string,
    name: string,
    code: string,
    domain: string,
    isCustomDomain: boolean
};

export type CompanyContract = {
    companyId: string,
    tenant: TenantContract,
    name: string,
    legalName: string,
    contactAddress: AddressContract,
    settings: CompanySettingsContract,
    companyStatus: CompanyStatus,
    companyType: CompanyType,
    createdAt: Date,
    customers: CustomerContract[],
    warehouses: WarehouseContract[],
    owner: UserContract
}

export type CompanySettingsContract = {
    maxCustomerAmount: number,
    maxWarehouseCount: number,
    maxDailyTransactions: number
}

export type TenantPreferences = {
    logo?: string,
    favicon?: string,
    backgroundImage?: string,
    primaryColor?: string,
    secondaryColor?: string
    coverPageText?: string,
    enableCustomerRegistration: boolean
}

export type CompanyForm = {
    name: string,
    legalName: string,
    countryCode: string,
    state: Maybe<string>,
    stateCode: Maybe<string>,
    city: string,
    address1: string,
    address2: Maybe<string>,
    zipCode: string,
    province: Maybe<string>,
    email: string,
    phone: string,
    domain: string,
    isCustomDomain: boolean,
    logo: Maybe<string>,
    favicon: Maybe<string>,
    backgroundImage: Maybe<string>,
    primaryColor: Maybe<string>,
    secondaryColor: Maybe<string>
    coverPageText: Maybe<string>,
    enableCustomerRegistration: boolean
    companyType: CompanyType,
    companyStatus: CompanyStatus,
    maxCustomerAmount: number,
    maxWarehouseCount: number,
    maxDailyTransactions: number
}