import { APIClient, type PagedList } from "helpers/api_helper";
import { GetPrepServiceQuery, ListPrepServicesQuery } from "./types/queries";
import { CreatePrepServiceCommand, DeletePrepServiceCommand, UpdatePrepServiceCommand } from "./types/commands";
import type { PrepServiceContract } from "./types/contracts/shipping";

const api = new APIClient();

export const getPrepService = async (data: GetPrepServiceQuery) => {
    const result = await api.get(`/api/prepservice/${data.prepServiceId}`) as PrepServiceContract | undefined;
    return result;
}

export const getPrepServiceList = async (data: ListPrepServicesQuery) => {
    const result = await api.get(`/api/prepservice`, data) as PagedList<PrepServiceContract>;
    return result;
}

export const postPrepService = async (data: CreatePrepServiceCommand) => {
    const result = await api.post(`/api/prepservice`, data);
    return result;
}

export const putPrepService = async (data: UpdatePrepServiceCommand) => {
    const result = await api.put(`/api/prepservice/${data.prepServiceId}`, { ...data.prepService });
    return result;
}

export const deletePrepService = async (data: DeletePrepServiceCommand) => {
    const result = await api.delete(`/api/prepservice/${data.prepServiceId}`);
    return result;
}