import { Trans, useTranslation } from "react-i18next";

import logo from "assets/images/payment/zelle.svg";
import type { WarehouseContract } from "api/types/contracts/warehouses";

type ZelleInfoProps = {
    warehouse: WarehouseContract
}

const ZelleInfo = (props: ZelleInfoProps) => {
    const { t } = useTranslation();

    return <div className="vstack gap-2">
        <p>{t("paragraphs:PaymentMethodDescription.zelle")}</p>
        <p className="text-center">
            <img src={logo} style={{ height: "100px" }} />
        </p>
        <h6>{t("Receipient Details")}:</h6>
        <blockquote className="blockquote blockquote-secondary rounded py-2">
            <dl className="row">
                <dt className="col">{t("Email/Phone Number")}</dt>
                <dd className="col">{props.warehouse.settings.zelle?.emailOrPhoneNumber}</dd>
            </dl>
        </blockquote>
        <hr />
        <p className="mt-auto mb-0">
            <Trans i18nKey="paragraphs:PaymentMethodFootnote"
                values={{
                    billingEmail: props.warehouse.settings.invoice?.paymentSupportEmail,
                }}
                components={[<a href={`mailto:${props.warehouse.settings.invoice?.paymentSupportEmail}`} />]}
            />
        </p>
    </div>;
}

export default ZelleInfo;