import SelectInput from "Components/Form/SelectInput";
import { type InboundStatus, InboundStatusList } from "api/types/contracts/inbound";
import { EnumSelectInput, SelectOption } from "helpers/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const InboundStatusSelect = (props: EnumSelectInput<InboundStatus>) => {
    const { t } = useTranslation();
    const inboundStatusOptions = useMemo<SelectOption<InboundStatus>[]>(() => InboundStatusList.map((item) => ({ 
        value: item, 
        label: t(`enums:InboundStatus.${item}`) 
    })), [t]);

    const { isMulti, value, onChange, ...rest } = props;

    return <>
        {isMulti && <SelectInput<InboundStatus> isMulti value={value} onChange={onChange} {...rest} options={inboundStatusOptions} placeholder={t("Inbound Status")} />}
        {!isMulti && <SelectInput {...rest} value={value} onChange={onChange} options={inboundStatusOptions} placeholder={t("Inbound Status")} />}
    </>;
};

export default InboundStatusSelect;