import { DangerAlert } from 'Components/Alerts';
import BusyOverlay from 'Components/Common/BusyOverlay';
import RequiredLabel from 'Components/Common/RequiredLabel';
import NumberInput from 'Components/Form/NumberInput';
import TextInput from 'Components/Form/TextInput';
import ValidationWrapper from 'Components/Form/Validated/ValidationWrapper';
import ValidatorButton from 'Components/Form/Validated/ValidatorButton';
import { useAppDispatch, useAppSelector } from 'Components/Hooks/StoreHooks';
import { BayContract, BayForm } from 'api/types/contracts/locations';
import { useFormik } from 'formik';
import { TypedShape } from 'helpers/types';
import { useTranslation } from 'react-i18next';
import { Col, Form, Label, Row } from 'reactstrap';
import { updateBay } from 'slices/location/thunk';
import * as Yup from 'yup';

type FormProps = {
    bay: BayContract,
    onSuccess: VoidFunction
}

const createBayForm = (bay: BayContract): BayForm => {
    return {
        name: bay.name,
        ...bay.properties
    }
};

const UpdateBayFormView = (props: FormProps) => {
    const aisle = createBayForm(props.bay);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { loading, error } = useAppSelector(
        (state) => ({
            error: state.Location.error,
            loading: state.Location.loading
        })
    );

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: aisle,
        validationSchema: Yup.object<BayForm, TypedShape<BayForm>>({
            name: Yup.string().required(t("Please enter bay name")),
            height: Yup.number().notRequired(),
            width: Yup.number().notRequired()
        }),
        onSubmit: async (values) => {
            const success = await dispatch(updateBay({
                bayId: props.bay.bayId,
                bay: values
            }));
            
            if (success) {
                props.onSuccess();
            }
        },
    });
    
    return <>
        <Form className="needs-validation form-steps" action="#" onSubmit={validation.handleSubmit}>
            <div>
                <div className="mb-4">
                    <div>
                        <h5 className="mb-1">{t("Bay Information")}</h5>
                    </div>
                </div>
                <Row>
                    <Col lg={4} className="g-3">
                        <RequiredLabel htmlFor="name">{t("Bay Name")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="name">
                            <TextInput placeholder="Enter Shelf Name" />
                        </ValidationWrapper>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="height">{t("Bay Height ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="height">
                            <NumberInput placeholder="Enter bay height" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="width">{t("Bay Height ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="width">
                            <NumberInput placeholder="Enter bay width" />
                        </ValidationWrapper>
                    </Col>
                </Row>
            </div>
            <div className="mt-4">{error && <DangerAlert>{error.message}</DangerAlert>}</div>
            <div className="d-flex justify-content-end gap-3">
                <BusyOverlay busy={loading.save} size="sm">
                    <ValidatorButton type="submit" validation={validation} className="btn btn-primary btn-label right nexttab">
                        <i className="ri-save-3-line label-icon align-middle fs-16 ms-2"></i>
                        {t("Save")}
                    </ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
    </>;
}

export default UpdateBayFormView;