import { APIClient, PagedList } from "../helpers/api_helper";
import { CreateBinsCommand, DeleteBinCommand, UpdateBinCommand } from "./types/commands";
import { BinContract } from "./types/contracts/bin";
import { GetBinByLabelQuery, GetBinQuery, ListBinsQuery } from "./types/queries";

const api = new APIClient();

export const postBin = async (data: CreateBinsCommand) => {
    await api.post(`/api/bin`, data);
}

export const putBin = async (data: UpdateBinCommand) => {
    await api.put(`/api/bin/${data.binId}`, { ...data.bin });
}

export const deleteBin = async (data: DeleteBinCommand) => {
    await api.delete(`/api/bin/${data.binId}`);
}

export const getBinList = async (data: ListBinsQuery) => {
    return await api.get<PagedList<BinContract>>(`/api/bin`, data);
}

export const getBin = async (data: GetBinQuery) => {
    return await api.get<BinContract>(`/api/bin/${data.id}`);
}

export const getBinByLabel = async (data: GetBinByLabelQuery): Promise<BinContract | undefined> => {
    return await api.get(`/api/bin/label`, { ...data });
}