import { AppDispatch, RootState } from "slices";
import { loading, apiError, setList, setProcessingEntry, setSelectedBox, setGateEntryInbound, setGateEntryReceiving, setGateEntryCustomer, setGateEntryLatest, setGateEntryWarehouse, setGateEntryShippingPurpose } from "./reducer";
import { GetReceivingEntryQuery, LatestReceivingEntriesQuery, ListReceivingEntriesQuery } from "api/types/queries";
import { AddReceivingBoxCommand, AddReceivingDocumentCommand, CreateReceivingEntryCommand, CreateReceivingProductLabelsCommand, FinalizeReceivingCommand, ResumeReceivingCommand, SaveReceivingProductStockCommand } from "api/types/commands";
import { ApiError } from "helpers/types";
import { deleteReceivingBoxEntry, deleteReceivingProductLabel, getLatestReceivingEntries, getReceivingEntriesList, getReceivingEntry, patchAssignProductLabel, patchFinalizeReceivingEntry, patchReceivingEntryBox, patchReceivingEntryDocument, patchResumeReceivingEntry, patchSaveStocks, postReceivingEntry, postReceivingProductLabels } from "api/receiving";
import { toast } from "react-toastify";
import i18n from 'i18n';
import { ReceivingBoxContract, ReceivingEntryContract } from "api/types/contracts/receivings";
import { InboundContract, ShippingPurpose } from "api/types/contracts/inbound";
import { CustomerContract } from "api/types/contracts/customers";
import { WarehouseContract } from "api/types/contracts/warehouses";
import { trimLabel, trimSearch } from "helpers/string";

export const loadReceivingEntry = (params: GetReceivingEntryQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["load", true]));

        const result = await getReceivingEntry(params);

        dispatch(setProcessingEntry(result));

        return result;
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["load", false]));
    }
}

export const loadReceivingForGateEntry = (params: GetReceivingEntryQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["load", true]));

        const result = await getReceivingEntry(params);

        dispatch(setGateEntryReceiving(result));

        return result;
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["load", false]));
    }
}

export const loadLatest = (params: LatestReceivingEntriesQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["latest", true]));

        var result = await getLatestReceivingEntries(params);
        
        dispatch(setGateEntryLatest(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["latest", false]));
    }
};

export const loadList = (params: ListReceivingEntriesQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["list", true]));

        const { search, ...rest } = params;

        var result = await getReceivingEntriesList({
            search: trimSearch(search),
            ...rest
        });

        dispatch(setList(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["list", false]));
    }
};

export const createReceivingEntry = (params: CreateReceivingEntryCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["new", true]));

        var newEntry = await postReceivingEntry(params);

        return newEntry;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return undefined;
    }
    finally {
        dispatch(loading(["new", false]));
    }
}

export const addReceivingEntryBox = (params: AddReceivingBoxCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        await patchReceivingEntryBox(params);

        toast.success(i18n.t("Box added", { count: params.boxQuantity}));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        
    }
}

export const removeReceivingEntryBox = (receivingEntryId: string, receivingBoxId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        await deleteReceivingBoxEntry({
            receivingEntryId,
            receivingBoxId
        });

        toast.success(i18n.t("Box removed"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        
    }
}

export const addReceivingEntryDocument = (params: AddReceivingDocumentCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        await patchReceivingEntryDocument(params);

        toast.success(i18n.t("Document added"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        
    }
}

export const createReceivingProductLabels = (params: CreateReceivingProductLabelsCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["newLabel", true]));

        const result = await postReceivingProductLabels(params);

        toast.success(i18n.t("Product label created", { count: result.length }));

        return result;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return [];
    }
    finally {
        dispatch(loading(["newLabel", false]));
    }
}

export const processReceivingEntry = (receivingEntry: ReceivingEntryContract) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setProcessingEntry(receivingEntry));
    dispatch(setSelectedBox(undefined));

    return Promise.resolve();
}

export const selectBox = (box: ReceivingBoxContract | undefined) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setSelectedBox(box));

    return Promise.resolve();
}

export const assignReceivingProduct = (productLabel: string, putawayBinCode: string, receivingBoxId: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["scan", true]));

        await patchAssignProductLabel({
            serialNumber: trimLabel(productLabel),
            putawayBinCode,
            receivingBoxId
        });

        toast.success(i18n.t("Product scanned"));

        return true;
    }
    catch (err) {
        const error = err as ApiError;
        dispatch(apiError(error));

        return false;
    }
    finally {
        dispatch(loading(["scan", false]));
    }
}

export const removeReceivingProduct = (productLabel: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["scan", true]));

        await deleteReceivingProductLabel({
            serialNumber: productLabel
        });

        toast.success(i18n.t("Product removed"));

        return true;
    }
    catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["scan", false]));
    }
}

export const saveStock = (params: SaveReceivingProductStockCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["saveStock", true]));

        await patchSaveStocks(params);

        toast.success(i18n.t("Stock saved"));

        return true;
    }
    catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["saveStock", false]));
    }
}

export const finalizeReceivingEntry = (params: FinalizeReceivingCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true]));

        await patchFinalizeReceivingEntry(params);

        return true;
    }
    catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const resumeReceivingEntry = (params: ResumeReceivingCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true]));

        await patchResumeReceivingEntry(params);

        return true;
    }
    catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const clearGateEntrySelection = () => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setGateEntryInbound(undefined));
    dispatch(setGateEntryReceiving(undefined));
    dispatch(setGateEntryCustomer(undefined));
    dispatch(setGateEntryWarehouse(undefined));
    dispatch(setGateEntryShippingPurpose(undefined));

    return Promise.resolve();
}

export const selectInboundForGateEntry = (inbound: InboundContract) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setGateEntryInbound(inbound));
    dispatch(setGateEntryReceiving(undefined));
    dispatch(setGateEntryCustomer(undefined));

    return Promise.resolve();
}

export const selectCustomerForGateEntry = (customer: CustomerContract) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setGateEntryInbound(undefined));
    dispatch(setGateEntryReceiving(undefined));
    dispatch(setGateEntryCustomer(customer));

    return Promise.resolve();
}

export const selectReceivingForGateEntry = (receiving: ReceivingEntryContract) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setGateEntryInbound(undefined));
    dispatch(setGateEntryReceiving(receiving));
    dispatch(setGateEntryCustomer(undefined));

    return Promise.resolve();
}

export const selectWarehouseForGateEntry = (warehouse: WarehouseContract | undefined) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setGateEntryWarehouse(warehouse));

    return Promise.resolve();
}

export const selectShippingPurposeForGateEntry = (shippingPurpose: ShippingPurpose | undefined) => async (dispatch: AppDispatch, getState: () => RootState) => {
    dispatch(setGateEntryShippingPurpose(shippingPurpose));

    return Promise.resolve();
}