import { changeHTMLAttribute } from './utils';
import {
    changeLayoutAction,
    changeLayoutModeAction,
    changeSidebarThemeAction,
    changeLayoutWidthAction,
    changeLayoutPositionAction,
    changeTopbarThemeAction,
    changeLeftsidebarSizeTypeAction,
    changeLeftsidebarViewTypeAction,
    changeSidebarImageTypeAction,
    changePreLoaderAction,
    changeSidebarVisibilityAction
} from './reducer';
import { LAYOUT_MODE_TYPES, LAYOUT_POSITION_TYPES, LAYOUT_SIDEBAR_TYPES, LAYOUT_TOPBAR_THEME_TYPES, LAYOUT_TYPES, LAYOUT_WIDTH_TYPES, LEFT_SIDEBAR_IMAGE_TYPES, LEFT_SIDEBAR_SIZE_TYPES, LEFT_SIDEBAR_VIEW_TYPES, PERLOADER_TYPES, SIDEBAR_VISIBILITY_TYPES } from 'helpers/constants/layout';
import { AppDispatch, RootState } from "slices";

/**
 * Changes the layout type
 * @layout
 */
export const changeLayout = (layout : LAYOUT_TYPES) => (dispatch : AppDispatch, getState: () => RootState) => {
    try {
        if (layout === "twocolumn") {
            document.documentElement.removeAttribute("data-layout-width");
        } else if (layout === "horizontal") {
            document.documentElement.removeAttribute("data-sidebar-size");
        } else if (layout === "semibox") {
            changeHTMLAttribute("data-layout-width", "fluid");
            changeHTMLAttribute("data-layout-style", "default");
        }
        changeHTMLAttribute("data-layout", layout);
        dispatch(changeLayoutAction(layout));
    } catch (error) { }
};

/**
 * Changes the layout mode
 * @layoutMode
 */
export const changeLayoutMode = (layoutMode : LAYOUT_MODE_TYPES) => (dispatch : AppDispatch, getState: () => RootState) => {
    try {
        changeHTMLAttribute("data-bs-theme", layoutMode);
        dispatch(changeLayoutModeAction(layoutMode));
    } catch (error) { }
};

/**
 * Changes the left sidebar theme
 * @theme
 */
export const changeSidebarTheme = (theme : LAYOUT_SIDEBAR_TYPES) => (dispatch : AppDispatch, getState: () => RootState) => {
    try {
        changeHTMLAttribute("data-sidebar", theme);
        dispatch(changeSidebarThemeAction(theme));
    } catch (error) {
        // console.log(error);
    }
};

/**
 * Changes the layout width
 * @layoutWidth
 */
export const changeLayoutWidth = (layoutWidth : LAYOUT_WIDTH_TYPES) => (dispatch : AppDispatch, getState: () => RootState) => {
    try {
        if (layoutWidth === 'lg') {
            changeHTMLAttribute("data-layout-width", "fluid");
        } else {
            changeHTMLAttribute("data-layout-width", "boxed");
        }
        dispatch(changeLayoutWidthAction(layoutWidth));
    } catch (error) {
        return error;
    }
};

/**
 * Changes the layout position
 * @layoutposition
 */
export const changeLayoutPosition = (layoutposition : LAYOUT_POSITION_TYPES) => (dispatch : AppDispatch, getState: () => RootState) => {
    try {
        changeHTMLAttribute("data-layout-position", layoutposition);
        dispatch(changeLayoutPositionAction(layoutposition));
    } catch (error) {
        // console.log(error);
    }
};

/**
 * Changes the topbar themes
 * @topbarTheme 
 */
export const changeTopbarTheme = (topbarTheme : LAYOUT_TOPBAR_THEME_TYPES) => (dispatch : AppDispatch, getState: () => RootState) => {
    try {
        changeHTMLAttribute("data-topbar", topbarTheme);
        dispatch(changeTopbarThemeAction(topbarTheme));

    } catch (error) {
        // console.log(error);
    }
};

/**
 * Changes the topbar themes
 * @leftsidebarImagetype
 */
export const changeSidebarImageType = (leftsidebarImagetype : LEFT_SIDEBAR_IMAGE_TYPES) => (dispatch : AppDispatch, getState: () => RootState) => {
    try {
        changeHTMLAttribute("data-sidebar-image", leftsidebarImagetype);
        dispatch(changeSidebarImageTypeAction(leftsidebarImagetype));
    } catch (error) {
        // console.log(error);
    }
};

/**
 * Changes the Preloader
 * @preloaderTypes
 */
export const changePreLoader = (preloaderTypes : PERLOADER_TYPES) => (dispatch : AppDispatch, getState: () => RootState) => {
    try {
        changeHTMLAttribute("data-preloader", preloaderTypes);
        dispatch(changePreLoaderAction(preloaderTypes));
    } catch (error) {
        // console.log(error);
    }
};

/**
 * Changes the topbar themes
 * @leftsidebarSizetype
 */
export const changeLeftsidebarSizeType = (leftsidebarSizetype : LEFT_SIDEBAR_SIZE_TYPES) => (dispatch : AppDispatch, getState: () => RootState) => {
    try {
        switch (leftsidebarSizetype) {
            case 'lg':
                changeHTMLAttribute("data-sidebar-size", "lg");
                break;
            case 'md':
                changeHTMLAttribute("data-sidebar-size", "md");
                break;
            case "sm":
                changeHTMLAttribute("data-sidebar-size", "sm");
                break;
            case "sm-hover":
                changeHTMLAttribute("data-sidebar-size", "sm-hover");
                break;
            default:
                changeHTMLAttribute("data-sidebar-size", "lg");
        }
        dispatch(changeLeftsidebarSizeTypeAction(leftsidebarSizetype));

    } catch (error) {
        // console.log(error);
    }
};

/**
 * Changes the topbar themes
 * @leftsidebarViewtype
 */
export const changeLeftsidebarViewType = (leftsidebarViewtype : LEFT_SIDEBAR_VIEW_TYPES) => (dispatch : AppDispatch, getState: () => RootState) => {
    try {
        changeHTMLAttribute("data-layout-style", leftsidebarViewtype);
        dispatch(changeLeftsidebarViewTypeAction(leftsidebarViewtype));
    } catch (error) {
        // console.log(error);
    }
};

/**
 * Changes the sidebar visibility
 * @sidebarVisibilitytype
 */
export const changeSidebarVisibility = (sidebarVisibilitytype : SIDEBAR_VISIBILITY_TYPES) => (dispatch : AppDispatch, getState: () => RootState) => {
    try {
        changeHTMLAttribute("data-sidebar-visibility", sidebarVisibilitytype);
        dispatch(changeSidebarVisibilityAction(sidebarVisibilitytype));
    } catch (error) { }
};