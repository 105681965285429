import List from "./_List";
import BusyOverlay from "Components/Common/BusyOverlay";
import { useAppSelector } from "Components/Hooks/StoreHooks";

const BinLocationsView = () => {
    const { selectedShelf, loading } = useAppSelector(
        (state) => {
            const location = state.Location;
            const selectedArea = location.areas?.find(a => a.areaId === location.selectedAreaId);
            const selectedAisle = selectedArea?.aisles?.find(a => a.aisleId === location.selectedAisleId);
            const selectedBay = selectedAisle?.bays?.find(b => b.bayId === location.selectedBayId);
            const selectedShelf = selectedBay?.shelves?.find(s => s.shelfId === location.selectedShelfId);

            return {
                view: location.view,
                loading: location.loading,
                selectedArea,
                selectedAisle,
                selectedShelf,
                selectedBay
            };
        }
    );

    return <>
        <BusyOverlay busy={loading.data} blur>
            {selectedShelf && <List binLocations={selectedShelf.binLocations} />}
        </BusyOverlay>
    </>;
}

export default BinLocationsView;