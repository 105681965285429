import SelectInput from "Components/Form/SelectInput";
import { type PrepServiceChannel, PrepServiceChannelList } from "api/types/contracts/shipping";
import { EnumSelectInput, SelectOption } from "helpers/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const PrepServiceChannelSelect = (props: EnumSelectInput<PrepServiceChannel>) => {
    const { t } = useTranslation();

    const options = useMemo(() => PrepServiceChannelList.map<SelectOption<PrepServiceChannel>>(p => ({
        value: p as PrepServiceChannel,
        label: t(`enums:PrepServiceChannel.${p}`)
    })), [t]);
    const { isMulti, value, onChange, ...rest } = props;

    return <>
        {isMulti && <SelectInput<PrepServiceChannel> isMulti value={value} onChange={onChange} {...rest} options={options} placeholder={t("Prep Service Channel")} />}
        {!isMulti && <SelectInput {...rest} value={value} onChange={onChange} options={options} placeholder={t("Prep Service Channel")} />}
    </>;
};

export default PrepServiceChannelSelect;