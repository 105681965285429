import SelectInput from "Components/Form/SelectInput"
import { useDebounce } from "Components/Hooks/HelperHooks";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { UnusableProductReasonContract } from "api/types/contracts/inventory"; 
import { ListUnusableProductReasonsQuery } from "api/types/queries";
import { EntitySelectInputProps, SelfPopulatedSelectInputRef } from "helpers/types";
import { ForwardedRef, RefAttributes, forwardRef, useEffect, useImperativeHandle, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { loadUnusableProductReasonList } from "slices/receivings/unusableProductReason/thunk"; 
import { useImmer } from "use-immer";

type UnusableProductReasonSelectProps = EntitySelectInputProps<UnusableProductReasonContract, "unusableProductReasonId"> & {
    
}

const UnusableProductReasonSelect = ({ isMulti, value, onChange, onSelect, name, isValid, ...rest }: UnusableProductReasonSelectProps, ref: ForwardedRef<SelfPopulatedSelectInputRef>) => { 
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [query, updateQuery] = useImmer<ListUnusableProductReasonsQuery>({
        page: 1,
        pageSize: 99,
        search: ""
    });

    const { unusableProductReasons, loading } = useAppSelector(
        (state) => ({
            unusableProductReasons: state.UnusableProductReason.list?.items || [], 
            loading: state.UnusableProductReason.loading.list
        })
    );

    const unusableProductReasonList = useMemo(() => unusableProductReasons.map(r => ({ value: r.unusableProductReasonId, label: r.name})), [unusableProductReasons]); 
    
    const loadUnusableProductReasonListDebounced = useDebounce(() => {
        dispatch(loadUnusableProductReasonList(query));
    }, 200, [query]);

    useImperativeHandle(ref, () => {
        return {
            reload: async () => {
                loadUnusableProductReasonListDebounced();
            }
        };
    }, [loadUnusableProductReasonListDebounced]);

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = unusableProductReasons.filter(r => val.includes(r.unusableProductReasonId)); 

        if (selected) {
            onSelect?.(selected);
            onChange?.(val);
            return;
        }

        onChange?.([]);
        onSelect?.([]);
    };

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            const selected = unusableProductReasons.find(r => r.unusableProductReasonId === val); 

            if (selected) {
                onSelect?.(selected);
                onChange?.(selected.unusableProductReasonId); 
                return;
            }
        }

        onChange?.(undefined);
        onSelect?.(undefined);
    };

    useEffect(() => {
        loadUnusableProductReasonListDebounced(); 
    }, [loadUnusableProductReasonListDebounced]);

    useEffect(() => {
        if (!value) {
            return;
        }

        if (unusableProductReasons.length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unusableProductReasons]);
    
    const selectProps = {
        ...rest,
        placeholder: t("Unusable Product Reason"), 
        busy: loading,
        options: unusableProductReasonList,
        showClear: true,
        isValid: isValid
    }

    return <>
        {isMulti && <SelectInput<string> isMulti value={value} onChange={onChangedMulti} {...selectProps} />}
        {!isMulti && <SelectInput value={value} onChange={onChangedSingle} {...selectProps} />}
    </>;
}

export default forwardRef(UnusableProductReasonSelect) as (
    props: UnusableProductReasonSelectProps & RefAttributes<SelfPopulatedSelectInputRef>
) => ReturnType<typeof UnusableProductReasonSelect>;
