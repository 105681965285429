import { UnitOfCurrency } from "api/types/contracts/common";
import { useAppSelector } from "./StoreHooks";

const useCommon = () => {
    const { exchangeRates } = useAppSelector(
        (state) => ({
            exchangeRates: state.Common.usdExchangeRates
        })
    );

    const calculateExchange = (amount: number, from: UnitOfCurrency, to: UnitOfCurrency) => {
        if (to === from) {
            return amount;
        }

        return amount / exchangeRates[from] * exchangeRates[to];
    }

    return { calculateExchange };
};

export { useCommon };
