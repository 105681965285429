//import images 
import { useTranslation } from "react-i18next";

import Grid from "./_Grid";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { useSelector } from "react-redux";
import { WarningAlert } from "Components/Alerts";
import BusyOverlay from "Components/Common/BusyOverlay";
import { useAppSelector } from "Components/Hooks/StoreHooks";

type AreasViewProps = {
    
}

const AreasView = (props: AreasViewProps) => {
    const { t } = useTranslation();

    const { areas, loading, error } = useAppSelector(
        (state) => ({
            error: state.Location.error,
            loading: state.Location.loading,
            areas: state.Location.areas
        })
    );

    return <>
        <BusyOverlay busy={loading.data} blur>
            {areas && areas.length > 0 ? 
                <Grid areas={areas} />
            :
                <WarningAlert>{t("There are no defined areas yet")}</WarningAlert>
            }
        </BusyOverlay>
    </>;
};

export default AreasView;
