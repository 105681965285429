import { Col, Row } from "reactstrap";
import { ApiFormsProps } from "./ApiFormsProps";
import { useFormik } from "formik";
import { useTranslation } from "react-i18next";
import RequiredLabel from "Components/Common/RequiredLabel";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import TextInput from "Components/Form/TextInput";
import { useDebounce } from "Components/Hooks/HelperHooks";
import * as Yup from "yup";
import SelectInput from "Components/Form/SelectInput";

const apcSenderOptions = [{
    label: "APC_NJ (Rutherford, NJ)",
    value: "APC_NJ"
}, {
    label: "APC_CA (Los Angeles, CA)",
    value: "APC_CA"
}]

const ApcApiForm = (props: ApiFormsProps) => {
    const { t } = useTranslation();

    let value = props.value ?? {};

    const validation = useFormik({
        initialValues: {
            userId: value["userId"] ?? "",
            accountNumber: value["accountNumber"] ?? "",
            passcode: value["passcode"] ?? "",
            rateKey: value["rateKey"] ?? "",
            sender: value["sender"] ?? ""
        },
        validationSchema: Yup.object({
            userId: Yup.string().required(t("User ID is required")),
            accountNumber: Yup.string().required(t("Account Number is required")),
            passcode: Yup.string().required(t("Passcode is required")),
            rateKey: Yup.string().required(t("Rate Key is required")),
            sender: Yup.string().required(t("Sender is required"))
        }),
        onSubmit: (values) => {
            props.onChange?.(values);
        }
    });

    const submitDebounced = useDebounce(() => validation.isValid && validation.submitForm(), 200);

    return <Row className="g-2">
        <Col xs={12}>
            <RequiredLabel>{t("User ID")}</RequiredLabel>
            <ValidationWrapper validation={validation} field="userId">
                <TextInput onChange={submitDebounced} />
            </ValidationWrapper>
        </Col>
        <Col xs={12}>
            <RequiredLabel>{t("Account Number")}</RequiredLabel>
            <ValidationWrapper validation={validation} field="accountNumber">
                <TextInput onChange={submitDebounced} />
            </ValidationWrapper>
        </Col>
        <Col xs={12}>
            <RequiredLabel>{t("Passcode")}</RequiredLabel>
            <ValidationWrapper validation={validation} field="passcode">
                <TextInput onChange={submitDebounced} />
            </ValidationWrapper>
        </Col>
        <Col xs={12}>
            <RequiredLabel>{t("Rate Key")}</RequiredLabel>
            <ValidationWrapper validation={validation} field="rateKey">
                <TextInput onChange={submitDebounced} />
            </ValidationWrapper>
        </Col>
        <Col xs={12}>
            <RequiredLabel>{t("Sender")}</RequiredLabel>
            <ValidationWrapper validation={validation} field="sender">
                <SelectInput options={apcSenderOptions} onChange={submitDebounced} />
            </ValidationWrapper>
        </Col>
    </Row>;
}

export default ApcApiForm;