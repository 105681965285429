import { Button, Modal, ModalBody } from "reactstrap";
import AreaFormView from "./_AreaFormView";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { useDispatch, useSelector } from "react-redux";
import { reloadLocations } from "slices/location/thunk";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";

const NewAreaButton = () => {
    const [newModal, setNewModal] = useState(false);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { warehouse, loading, error } = useAppSelector(
        (state) => ({
            error: state.Location.error,
            loading: state.Location.loading,
            warehouse: state.Location.warehouse
        })
    );

    const onAreaAdded = () => { 
        setNewModal(false);
        dispatch(reloadLocations({ warehouseId: warehouse!.warehouseId }));
    };

    return warehouse ? <>
        <Button color="info" onClick={() => setNewModal(true)}>
            <i className="ri-add-line align-bottom me-1"></i> {t("Add New Area")}
        </Button>
        <Modal isOpen={newModal} toggle={() => setNewModal(prev => !prev)} size="lg">
            <ModalBody>
                <ModalCloseButton onClick={() => setNewModal(false)} />
                <AreaFormView warehouseId={warehouse?.warehouseId} onSuccess={onAreaAdded} />
            </ModalBody>
        </Modal>
    </> : null;
};

export default NewAreaButton;