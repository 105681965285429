import { useTenant } from "./Hooks/TenantHooks";

const TenantCode = () => {
    const { tenant } = useTenant();

    return <>
        <div className="ms-1 header-item">
            <span className="fs-16 badge badge-gradient-success">{tenant?.code}</span>
        </div>
    </>;
}

export default TenantCode;