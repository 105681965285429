import { setList, loading, apiError } from "./reducer";
import type { ListProductSuppliersQuery } from "api/types/queries";
import type{ CreateProductSupplierCommand, DeleteProductSupplierCommand, UpdateProductSupplierCommand } from "api/types/commands";
import type { ApiError } from "helpers/types";
import { toast } from "react-toastify";
import i18n from 'i18n';
import { deleteProductSupplier, getProductSupplierList, postProductSupplier, putProductSupplier } from "api/productSupplier";
import type { AppDispatch, RootState } from "slices";

export const loadList = (params: ListProductSuppliersQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["list", true]));

        var result = await getProductSupplierList(params);
        
        dispatch(setList(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["list", false]));
    }
};

export const createProductSupplier = (params: CreateProductSupplierCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true]));

        const supplier = await postProductSupplier(params);
        toast.success(i18n.t("Product supplier created"));

        return supplier;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return undefined;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const updateProductSupplier = (params: UpdateProductSupplierCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true]));

        await putProductSupplier(params);
        toast.success(i18n.t("Product supplier updated"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const removeProductSupplier = (params: DeleteProductSupplierCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["delete", true]));

        await deleteProductSupplier(params);

        toast.success(i18n.t("Product supplier deleted", { count: 1 }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const removeMultiProductSuppliers = (params: DeleteProductSupplierCommand[]) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["delete", true]));

        for (const item of params) {
            await deleteProductSupplier(item);
        }

        toast.success(i18n.t("Product suppliers deleted", { count: params.length }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}