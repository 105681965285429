import { InfoAlert } from "Components/Alerts";
import BusyOverlay from "Components/Common/BusyOverlay";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import Loader from "Components/Common/Loader";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import ProductImageDisplay from "Components/Displays/ProductImageDisplay";
import Checkbox from "Components/Form/Checkbox";
import NumberInput from "Components/Form/NumberInput";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import ShipmentPackageView, { PackageItemColors } from "Components/Shared/ShipmentPackageView";
import { UnitOfLength, UnitOfWeight } from "api/types/contracts/common";
import { FbaShipmentBoxContract, FbaShipmentBoxItemContract, FbaShipmentPackageFormItem } from "api/types/contracts/shipping";
import { useFormik } from "formik";
import { desi } from "helpers/optimalBox";
import { TypedShape } from "helpers/types";
import { convertWeight } from "helpers/unitConversions";
import _, { round } from "lodash";
import { useEffect, useMemo, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, CardTitle, Modal, ModalBody, Table } from "reactstrap";
import { loadFbaShipment, updateFbaShipment, updateFbaShipmentPackages } from "slices/shipping/fbaShipping/thunk";
import { useImmer } from "use-immer";
import * as Yup from "yup";

type EditPackagesProps = {
    
};

type Dimensions = { 
    width?: number, 
    height?: number, 
    length?: number
}

const EditPackages = (props: EditPackagesProps) => {
    const dispatch = useAppDispatch();
    const { fbaShipment, loading } = useAppSelector(
        (state) => ({
            fbaShipment: state.FbaShipping.processingItem,
            loading: state.FbaShipping.loading
        })
    );

    const { t } = useTranslation();
    const [boxDimensions, setBoxDimensions] = useState<Dimensions[]>(_.chain(fbaShipment?.packages ?? []).map(p => ({ width: p.box.width, height: p.box.height, length: p.box.length })).uniqWith(_.isEqual).value());
    const [editPackagesModal, setEditPackagesModal] = useState(false);
    const [editPackagesModalData, updateEditPackagesModalData] = useImmer<FbaShipmentPackageFormItem[]>([]);
    const [estimatedBoxesModal, setEstimatedBoxesModal] = useState(false);
    const removeAllPackagesConfirmDialogRef = useRef<DialogRef>(null);
    const completePackagesConfirmDialogRef = useRef<DialogRef>(null);

    const toggleEditPackagesModal = () => {
        if (!editPackagesModal) {
            updateEditPackagesModalData(packagesValidation.values);
        }
        setEditPackagesModal(!editPackagesModal);
    }
    const toggleEstimatedBoxesModal = () => setEstimatedBoxesModal(prev => !prev);

    const confirmPackagesFormation = async () => {
        if (editPackagesModalData) {
            await packagesValidation.setValues(editPackagesModalData);
            await packagesValidation.submitForm();
        }
    }

    const productColorCodes = useMemo(() => _.chain(fbaShipment?.items ?? []).map((item, i) => [item.product.productId, PackageItemColors[i % PackageItemColors.length]]).uniqWith(_.isEqual).fromPairs().value(), [fbaShipment?.items]);

    const packagesValidation = useFormik({
        initialValues: fbaShipment?.packages.map<FbaShipmentPackageFormItem>(p => ({
            box: p.box
        })) ?? [],
        validationSchema: Yup.array<FbaShipmentPackageFormItem>(Yup.object<FbaShipmentPackageFormItem, TypedShape<FbaShipmentPackageFormItem>>({
            box: Yup.object<FbaShipmentBoxContract, TypedShape<FbaShipmentBoxContract>>({
                boxNumber: Yup.number().required(t("Box number is required")),
                width: Yup.number().required(t("Width is required")),
                height: Yup.number().required(t("Height is required")),
                length: Yup.number().required(t("Length is required")),
                weight: Yup.number().required(t("Weight is required")),
                desi: Yup.number().required(t("Desi is required")),
                lengthUnit: Yup.string<UnitOfLength>().required(t("Length unit is required")),
                weightUnit: Yup.string<UnitOfWeight>().required(t("Weight unit is required")),
                products: Yup.array<FbaShipmentBoxItemContract>(Yup.object<FbaShipmentBoxItemContract, TypedShape<FbaShipmentBoxItemContract>>({
                    productId: Yup.string().required(),
                    asin: Yup.string().required(),
                    count: Yup.number().required()
                })).required()
            }).required()
        })).required().length(1, t("At least one package is required")),
        onSubmit: async values => {
            await dispatch(updateFbaShipmentPackages({
                fbaShipmentId: fbaShipment!.fbaShipmentId,
                packages: values
            }));

            await dispatch(loadFbaShipment({ fbaShipmentId: fbaShipment!.fbaShipmentId }));

            setEditPackagesModal(false);
        }
    });

    const addPackage = () => {
        updateEditPackagesModalData(draft => {
            const newItem: FbaShipmentPackageFormItem = {
                box: {
                    boxNumber: draft.length + 1,
                    weight: 0,
                    width: 0,
                    height: 0,
                    length: 0,
                    desi: 0,
                    lengthUnit: fbaShipment!.warehouse.settings.unitOfLength,
                    weightUnit: fbaShipment!.warehouse.settings.unitOfWeight,
                    products: []
                }
            }

            return [...draft, newItem];
        });
    };

    const addBoxDimensions = () => {
        setBoxDimensions([...boxDimensions, { }]);
    };

    const setDimensionProperty = (index: number, property: keyof Dimensions, value: number | undefined) => {
        setBoxDimensions(prev => {
            const newDimensions = [...prev];
            newDimensions[index][property] = value;
            return newDimensions;
        });
    }

    useEffect(() => {
        if (boxDimensions.length === 0) {
            setBoxDimensions([{ }]);
        }
    }, [boxDimensions]);

    useEffect(() => {
        packagesValidation.setValues(fbaShipment?.packages.map(p => ({
            box: p.box
        })) ?? []);
    }, [fbaShipment])
    
    if (!fbaShipment) {
        return <Loader height="250px" />;
    }

    const updateStatusToBoxLabelPending = async () => {
        await packagesValidation.submitForm();
        if (packagesValidation.isValid) {
            await dispatch(updateFbaShipment({
                fbaShipmentId: fbaShipment.fbaShipmentId, 
                status: "boxLabelPending"
            }));
    
            await dispatch(loadFbaShipment({ fbaShipmentId: fbaShipment.fbaShipmentId }));
        }
    };

    return <>
        <Card>
            <CardHeader>
                <CardTitle tag="h5" className="mb-0">{t("Packages")}</CardTitle>
            </CardHeader>
            <CardBody>
                <div className="d-flex justify-content-between mb-3">
                    <div className="w-25 border rounded py-2 px-3 d-flex justify-content-between">
                        <span>{t("Estimated Packages")}</span>
                        <Link to="#" onClick={toggleEstimatedBoxesModal}>{t("{{count}} Package", { count: fbaShipment.estimatedBoxes.length })}</Link>
                    </div>
                    {fbaShipment.status === "processing" && <div>
                        <Button color="info" onClick={toggleEditPackagesModal}>{t(packagesValidation.values.length === 0 ? "Add Package Information" : "Edit Package Information")}</Button>
                    </div>}
                </div>
                {packagesValidation.values.length > 0 ? <>
                    <ShipmentPackageView 
                        colorCodes={productColorCodes} 
                        packedBoxes={packagesValidation.values} />
                </> : <>
                    <InfoAlert>{t("Add packages to view here")}</InfoAlert>
                </>}
            </CardBody>
        </Card>
        {fbaShipment.status === "processing" && <div className="d-flex justify-content-center gap-2 mb-2">
            <Button color="danger" onClick={() => removeAllPackagesConfirmDialogRef.current?.show()}>{t("Remove All Packages")}</Button>
            <BusyOverlay size="sm" busy={loading.save}>
                <ValidatorButton type="submit" validation={packagesValidation} color="success" onClick={() => completePackagesConfirmDialogRef.current?.show()}>{t("Complete Packaging")}</ValidatorButton>
            </BusyOverlay>
        </div>}
        <Modal isOpen={editPackagesModal} toggle={toggleEditPackagesModal} size="xl">
            <ModalBody>
                <ModalCloseButton onClick={toggleEditPackagesModal} />
                <Table className="align-middle" size="sm" borderless>
                    <thead>
                        <tr>
                            <th>{t("Image")}</th>
                            <th>{t("Name")}</th>
                            <th>{t("Units boxed")}</th>
                            {editPackagesModalData.map((p, i) => {
                                return <th key={i} className="text-center" style={{ width: "5%" }}>{t("Box {{number}}", { number: p.box.boxNumber })}</th>;
                            })}
                            <th>
                                <Button color="info" size="sm" onClick={addPackage}>{t("Add Box")}</Button>
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {fbaShipment.items.map((item, i) => <tr key={i}>
                            <td>
                                <ProductImageDisplay product={item.product} />
                            </td>
                            <td>
                                <div>
                                    <Link className="fw-semibold" to={`/product/edit/${item.product.productId}`} target="_blank">{item.product.name}</Link>
                                </div>
                                <div className="hstack gap-3">
                                    <Link to={`/product/edit/${item.product.productId}`} target="_blank">{item.product.asin}</Link>
                                    <span>SKU: {item.product.sku}</span>
                                </div>
                            </td>
                            <td>{t("{{boxedCount}} of {{totalCount}}", {
                                boxedCount: _.sumBy(editPackagesModalData.flatMap(b => b.box.products).filter(bp => bp.productId === item.product.productId), p => p.count),
                                totalCount: item.count
                            })}</td>
                            {editPackagesModalData.map((b, j) => {
                                const boxProduct = b.box.products.find(bp => bp.productId === item.product.productId);
                                return <td key={j}>
                                    <NumberInput size="sm" className="text-center" value={boxProduct?.count ?? 0} onChange={val => {
                                        updateEditPackagesModalData(draft => {
                                            if (val) {
                                                if (boxProduct) {
                                                    draft[j].box.products = draft[j].box.products.map(bp => {
                                                        if (bp.productId === item.product.productId) {
                                                            return { ...bp, count: val };
                                                        }
                                                        return bp;
                                                    });
                                                }
                                                else {
                                                    draft[j].box.products.push({
                                                        productId: item.product.productId,
                                                        asin: item.product.asin ?? "???",
                                                        count: val
                                                    });
                                                }
                                            }
                                            else {
                                                if (boxProduct) {
                                                    draft[j].box.products = draft[j].box.products.filter(bp => bp.productId !== item.product.productId);
                                                }
                                            }
                                            const weight = convertWeight(item.product.weight, item.product.options.unitOfWeight, fbaShipment.warehouse.settings.unitOfWeight);
                                            draft[j].box.weight = round(_.sumBy(draft[j].box.products, p => p.count * weight), 2);
                                            draft[j].box.desi = desi(draft[j].box);

                                            return draft;
                                        });
                                    }} />
                                </td>;
                            })}
                            <td></td>
                        </tr>)}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={3}>
                                <div className="d-flex justify-content-between">
                                    <span>{t("Total SKUs: {{count}}", { count: fbaShipment.items.length })}</span>
                                    <strong>{t("Total Units: {{count}}", { count: _.sumBy(fbaShipment.items, i => i.count) })}</strong>
                                </div>
                            </td>
                            {editPackagesModalData.map((p, i) => <th key={i}></th>)}
                            <th>
                                
                            </th>
                        </tr>
                        <tr>
                            <td colSpan={3} className="text-end">
                                {t("Box Weight ({{unit}})", { unit: fbaShipment.warehouse.settings.unitOfWeight })}
                            </td>
                            {editPackagesModalData.map((b, j) => <td key={j}>
                                <NumberInput size="sm" className="text-center" value={b.box.weight} onChange={val => {
                                    updateEditPackagesModalData(draft => {
                                        draft[j].box.weight = val ?? 0;
                                        draft[j].box.desi = desi(draft[j].box);

                                        return draft;
                                    });
                                }} />
                            </td>)}
                            <td></td>
                        </tr>
                        <tr>
                            <td colSpan={3} className="text-end">
                                {t("Box Dimensions ({{unit}})", { unit: fbaShipment.warehouse.settings.unitOfLength })}
                            </td>
                            {editPackagesModalData.map((p, i) => <td key={i}></td>)}
                            <td></td>
                        </tr>
                        {boxDimensions.map((dimension, i) => <tr key={i}>
                            <td colSpan={3}>
                                <div className="justify-content-end gap-2 hstack">
                                    {i === (boxDimensions.length - 1) && <>
                                        <Link to="#" className="flex-shrink-0" onClick={addBoxDimensions}>{t("Add another box dimension")}</Link>
                                    </>}
                                    <NumberInput size="sm" className="text-center" value={dimension.width} style={{ width: "3rem" }}
                                        onChange={val => setDimensionProperty(i, "width", val)} />
                                    <span>x</span>
                                    <NumberInput size="sm" className="text-center" value={dimension.height} style={{ width: "3rem" }}
                                        onChange={val => setDimensionProperty(i, "height", val)} />
                                    <span>x</span>
                                    <NumberInput size="sm" className="text-center" value={dimension.length} style={{ width: "3rem" }}
                                        onChange={val => setDimensionProperty(i, "length", val)} />
                                </div>
                            </td>
                            {editPackagesModalData.map((p, j) => <td key={j} className="text-center">
                                <Checkbox value={p.box.width === dimension.width && p.box.height === dimension.height && p.box.length === dimension.length} 
                                    inline onChange={checked => {
                                        updateEditPackagesModalData(draft => {
                                            
                                        if (checked) {
                                            draft[j].box.width = dimension.width ?? 0;
                                            draft[j].box.height = dimension.height ?? 0;
                                            draft[j].box.length = dimension.length ?? 0;
                                        }
                                        else {
                                            draft[j].box.width = 0;
                                            draft[j].box.height = 0;
                                            draft[j].box.length = 0;
                                        }

                                        draft[j].box.desi = desi(draft[j].box);

                                        return draft;
                                    });
                                }} />
                            </td>)}
                            <td></td>
                        </tr>)}
                    </tfoot>
                </Table>
                <div className="d-flex justify-content-end">
                    <BusyOverlay busy={loading.save} size="sm">
                        <ValidatorButton color="success" onClick={confirmPackagesFormation} validation={packagesValidation}>
                            {t("Confirm Packing Information")}
                        </ValidatorButton>
                    </BusyOverlay>
                </div>
            </ModalBody>
        </Modal>
        <Modal isOpen={estimatedBoxesModal} toggle={toggleEstimatedBoxesModal} size="xl">
            <ModalBody>
                <ModalCloseButton onClick={toggleEstimatedBoxesModal} />
                <ShipmentPackageView colorCodes={productColorCodes} packedBoxes={fbaShipment.estimatedBoxes} />
            </ModalBody>
        </Modal>
        <Dialog ref={removeAllPackagesConfirmDialogRef} color="danger" buttons={["yes", "no"]} busy={loading.save || loading.load} iconClass="ri-delete-bin-line"  
            message={`Do you want to continue?`} title={`Removing all packaging information`}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    packagesValidation.setValues([]);
                    dispatch(updateFbaShipmentPackages({
                        fbaShipmentId: fbaShipment.fbaShipmentId,
                        packages: []
                    })).then(() => hide());
                }
                else {
                    hide();
                }
            }} />
        <Dialog ref={completePackagesConfirmDialogRef} color="info" buttons={["yes", "no"]} busy={loading.save || loading.load} iconClass="ri-check-line"  
            message={`Do you want to continue?`} title={`Completing the packaging process`}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    updateStatusToBoxLabelPending().then(() => hide());
                }
                else {
                    hide();
                }
            }} />
    </>;
}
export default EditPackages;