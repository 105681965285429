import { useQuery } from "@tanstack/react-query";
import { getCardList } from "api/payment";
import type { CreditCardContract } from "api/types/contracts/payment";
import Loader from "Components/Common/Loader";
import { useTranslation } from "react-i18next";
import { Badge, Button, Card, CardBody, DropdownItem, DropdownMenu, DropdownToggle, Input, Label, UncontrolledButtonDropdown } from "reactstrap";

type CreditCardSelectProps = {
    value?: string,
    onSelect?: (card: CreditCardContract | undefined) => void
}

const CreditCardSelect = (props: CreditCardSelectProps) => {
    const { t } = useTranslation();
    const { data: cards, isFetching: cardLoading, refetch: reloadCards } = useQuery({
        queryKey: ["credit-cards"],
        queryFn: () => getCardList({})
    });

    return <>
        <div className="vstack">
            {cardLoading ? <Loader height="150px" /> : <>
                <div className="vstack px-3 gap-3">
                    {cards?.length === 0 && <div className="text-center text-muted">{t("No credit cards found")}</div>}
                    {cards!.map((card, i) => <div className="d-flex gap-3 align-items-center">
                        <div className="form-check card-radio flex-grow-1" key={i}>
                            <Input id={card.creditCardId} name="creditCards" 
                                type="radio" className="form-check-input" defaultChecked={card.isDefault} />
                            <Label className="form-check-label d-flex gap-2 align-items-center" htmlFor="shippingMethod02">
                                <span className="d-block">
                                    <img src={`/creditCardType/${card.type}.svg`} style={{ height: "40px" }} alt="visa" />
                                </span>
                                <span className="d-block">
                                    <span className="fs-14 mb-1 text-wrap d-block">
                                        <strong>{t(`enums:CreditCardType.${card.type}`)} - {card.maskedCardNumber}</strong>
                                        {card.isDefault && <Badge color="dark-subtle" className="text-dark ms-2">{t("Default")}</Badge>}
                                    </span>
                                    <span className="text-muted fw-normal text-wrap d-block">
                                        {t("Expires")} {card.expirationMonth}/{card.expirationYear}
                                    </span>
                                </span>
                            </Label>
                        </div>
                        <div>
                            <UncontrolledButtonDropdown>
                                <DropdownToggle tag="button" className="btn btn-sm btn-ghost-dark btn-icon">
                                    <i className="ri-more-fill fs-2"></i>
                                </DropdownToggle>
                                <DropdownMenu>
                                    <DropdownItem onClick={() => {}}>
                                        <i className="ri-check-fill align-middle me-2"></i> {t("Set as Default")}
                                    </DropdownItem>
                                    <DropdownItem onClick={() => {}}>
                                        <i className="ri-delete-bin-2-line align-middle me-2"></i> {t("Remove")}
                                    </DropdownItem>
                                </DropdownMenu>
                            </UncontrolledButtonDropdown>
                        </div>
                    </div>)}
                </div>
                <div className="mt-2">
                    <Button color="ghost-info" className="btn-label">
                        <i className="ri-add-fill label-icon fs-16"></i>
                        <span>{t("Link Credit/Debit Card")}</span>
                    </Button>
                </div>
            </>}
        </div>
    </>;
}

export default CreditCardSelect;