import BusyOverlay from "Components/Common/BusyOverlay";
import DateInput from "Components/Form/DateInput";
import NumberInput from "Components/Form/NumberInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { CreateReceivingProductLabelsCommand } from "api/types/commands";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Form, Label } from "reactstrap";
import { createReceivingProductLabels } from "slices/receivings/thunk";
import * as Yup from "yup";

type CreateProductLabelProps = {
    productId: string,
    onCreated?: (labels: string[]) => void,
}

type FormType = {
    count: number,
    expireDate?: Yup.Maybe<Date>,
    productId: string,
    receivingBoxId: string,
    receivingEntryId: string
};

const CreateProductLabel = (props: CreateProductLabelProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const [busy, setBusy] = useState(false);

    const { processedEntry, selectedBox } = useAppSelector(
        (state) => ({
            processedEntry: state.Receiving.processedEntry!,
            selectedBox: state.Receiving.selectedBox!
        })
    );

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            productId: props.productId,
            receivingBoxId: selectedBox.receivingBoxId,
            receivingEntryId: processedEntry.receivingEntryId,
            count: 1
        } as FormType,
        validationSchema: Yup.object<FormType, TypedShape<FormType>>({
            count: Yup.number().required(t("You must enter quantity")).min(1, t("Quantity must be greater than zero")),
            expireDate: Yup.date().notRequired(),
            productId: Yup.string().required(t("You must select a product")),
            receivingBoxId: Yup.string().required(t("You must select a box")),
            receivingEntryId: Yup.string().required(t("You must select an entry"))
        }),
        onSubmit: async (values) => {
            setBusy(true); 
            const labels = await dispatch(createReceivingProductLabels(values as CreateReceivingProductLabelsCommand));

            setBusy(false);
            props.onCreated?.(labels);
        }
    });

    return <>
        <Form onSubmit={validation.handleSubmit}>
            <div className="d-flex justify-content-between text-nowrap">
                <div className="hstack gap-3">
                    <ValidationWrapper validation={validation} field="count">
                        <NumberInput min={1} className="text-center" style={{ width: "5rem"}} />
                    </ValidationWrapper>
                    <div className="d-flex align-items-center gap-2">
                        <Label className="mb-0">{t("Expire Date")}</Label>
                        <ValidationWrapper validation={validation} field="expireDate">
                            <DateInput />
                        </ValidationWrapper>
                    </div>
                </div>
                <BusyOverlay size="sm" busy={busy}>
                    <ValidatorButton validation={validation} type="submit" color="primary">{t("Create Product Label")}</ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
    </>;
}

export default CreateProductLabel;