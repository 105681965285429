import { UpdateUserAvatarCommand, UpdateUserStatusCommand } from "api/types/commands";
import { getUserList, patchUpdateUserAvatar, patchUpdateUserStatus } from "api/user";
import { toast } from "react-toastify";
import i18n from "i18n";
import { AppDispatch, RootState } from "slices";
import { setList, setLoading } from "./reducer";
import type { ListUsersQuery } from "api/types/queries";

export const updateAvatar = (params: UpdateUserAvatarCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        var result = await patchUpdateUserAvatar(params);

        toast.success(i18n.t("User avatar updated"));
        
        return result;
    } catch (error) {
        toast.error(i18n.t("User avatar update failed"));

        throw error;
    }
};

export const updateStatus = (params: UpdateUserStatusCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        var result = await patchUpdateUserStatus(params);

        toast.success(i18n.t("User status updated"));
        
        return result;
    } catch (error) {
        toast.error(i18n.t("User status update failed"));

        throw error;
    }
};

export const loadList = (params: ListUsersQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(setLoading(["list", true]));

        const result = await getUserList(params);

        dispatch(setList(result));
    } catch (e) {
        
    }
    finally {
        dispatch(setLoading(["list", false]));
    }
}