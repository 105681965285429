//import Scss
import './assets/scss/main.scss';

//imoprt Route
import { authProtectedRoutes, publicRoutes } from "Routes";
import AuthProtected from 'Layouts/AuthProtected';
import NonAuthLayout from "Layouts/NonAuthLayout";
import MainLayout from "Layouts/MainLayout";
import { RouteObject, RouterProvider, createBrowserRouter } from 'react-router-dom';
import ErrorPage from 'pages/Errors/ErrorPage';
import { useTenant } from 'Components/Hooks/TenantHooks';
import { useEffect } from 'react';
import { startApp } from 'slices/thunks';
import { useAppDispatch } from 'Components/Hooks/StoreHooks';

const router = createBrowserRouter([ 
    ...authProtectedRoutes.map<RouteObject>(route => ({ 
        path: route.path, 
        loader: route.loader,
        element: route.element ? <AuthProtected>
            <MainLayout>
                {route.element}
            </MainLayout>
        </AuthProtected> : undefined,
        Component: route.pageFn ? () => <AuthProtected>
            <MainLayout>
                {route.pageFn!()}
            </MainLayout>
        </AuthProtected> : undefined,
        ErrorBoundary: ErrorPage
    })), 
    ...publicRoutes.map<RouteObject>(route => ({
        path: route.path,
        loader: route.loader,
        element: route.element ? <NonAuthLayout>
            {route.element}
        </NonAuthLayout> : undefined,
        Component: route.pageFn ? () => (
            <NonAuthLayout>
                {route.pageFn!()}
            </NonAuthLayout>
        ) : undefined,
        ErrorBoundary: ErrorPage
    }))
]);

const App = () => {
    const dispatch = useAppDispatch();

    useEffect(() => {
        dispatch(startApp());
    }, [dispatch]);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { tenant } = useTenant();

    return <RouterProvider router={router} />;
}

export default App;
