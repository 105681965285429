import { useAppSelector } from "./StoreHooks";

const useMetadata = () => {
    const { tenant } = useAppSelector(
        state => ({
            tenant: state.Tenant.tenant
        })
    );

    const setPageTitle = (title: string) => {
        document.title = tenant?.name ? `${title} - ${tenant.name}` : title;
    };

    return { setPageTitle };
};

export { useMetadata };
