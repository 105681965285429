import { type EnumSelectRadio } from "helpers/types";
import { useTranslation } from "react-i18next";
import Checkbox from "Components/Form/Checkbox";
import { type LocationLabelArrowDirection } from "api/types/contracts/printing";
import { Label } from "reactstrap";

const LocationLabelArrowDirectionSwitch = (props: EnumSelectRadio<LocationLabelArrowDirection>) => {
    const { t } = useTranslation("enums");
    const { value, onChange, ...rest } = props;

    const handleCheckboxChange = (checked?: boolean) => {
        onChange?.(checked ? "up" : "down");
    };

    return <>
        <Label className="d-inline-flex align-items-center fw-normal">
            <span className="me-2">{t("LocationLabelArrowDirection.down")}</span>
            <Checkbox switch value={value === "up"} onChange={handleCheckboxChange} {...rest} />
            <span>{t("LocationLabelArrowDirection.up")}</span>
        </Label>
    </>;
};

export default LocationLabelArrowDirectionSwitch;
