import { Breadcrumb, BreadcrumbItem, Card, CardBody, CardHeader, Col, Container, Row } from "reactstrap";

//import images 
import { useTranslation } from "react-i18next";

import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import { useSearchParams } from "react-router-dom";
import WarehouseSelect from "Components/EntitySelects/WarehouseSelect";
import CompanySelect from "Components/EntitySelects/CompanySelect";
import { CompanyContract } from "api/types/contracts/companies";
import { useEffect, useState } from "react";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { useDispatch, useSelector } from "react-redux";
import { loadWarehouse, resetData, selectAisle, selectArea, selectBay } from "slices/location/thunk";
import { useDebounce } from "Components/Hooks/HelperHooks";
import { InfoAlert } from "Components/Alerts";
import Loader from "Components/Common/Loader";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { AreasView, NewAreaButton } from "./Areas";
import { AislesView, NewAisleButton } from "./Aisles";
import { BaysView, NewBayButton } from "./Bays";
import { ShelvesView, NewShelfButton } from "./Shelves";
import { BinLocationsView, NewBinLocationButton } from "./BinLocations";
import Restricted from "Components/Common/Restricted";
import BusyOverlay from "Components/Common/BusyOverlay";
import { useAppSelector } from "Components/Hooks/StoreHooks";

type LocationsPageProps = {
    
}

const LocationsPage = (props: LocationsPageProps) => {
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();
    const [selectedCompany, setSelectedCompany] = useState<CompanyContract>();
    const [searchParams, setSearchParams] = useSearchParams();
    const warehouseId = searchParams.get("warehouse") || undefined;
    
    const { view, loading, warehouse, selectedArea, selectedAisle, selectedBay, selectedShelf } = useAppSelector(
        (state) => {
            const location = state.Location;
            const selectedArea = location.areas?.find(a => a.areaId === location.selectedAreaId);
            const selectedAisle = selectedArea?.aisles?.find(a => a.aisleId === location.selectedAisleId);
            const selectedBay = selectedAisle?.bays?.find(b => b.bayId === location.selectedBayId);
            const selectedShelf = selectedBay?.shelves?.find(s => s.shelfId === location.selectedShelfId);

            return {
                view: location.view,
                loading: location.loading,
                warehouse: location.warehouse,
                selectedArea,
                selectedAisle,
                selectedBay,
                selectedShelf
            };
        }
    );

    const loadDataDebounced = useDebounce(() => {
        if (warehouseId) {
            dispatch(loadWarehouse({ warehouseId }));
        }
        else {
            dispatch(resetData());
        }
    }, 200, [warehouseId, dispatch]);

    useEffect(() => {
        if (warehouse) {
            setSelectedCompany(warehouse.company);
        }
    }, [warehouse]);

    useEffect(() => {
        loadDataDebounced();
    }, [loadDataDebounced]);

    setPageTitle(`${t("Locations")} - ${t("Management")}`);

    return (
        <>
            <div className="page-content">
                <Container fluid>
                    <TitleBreadcrumb active={t("Locations")} parents={[t("Management")]} />
                    <Row>
                        <Col xs={12}>
                            <Card className="mb-2">
                                <CardHeader className="border-0">
                                    <Row className="align-items-center">
                                        <div className="col-sm">
                                            <h5 className="card-title mb-0 p-2">
                                                {!view && t("Locations")}
                                                {view === "area" && t("{{warehouse}} Areas", { warehouse: warehouse?.name })}
                                                {view === "aisle" && t("{{area}} - Aisles", { area: selectedArea?.name })}
                                                {view === "bay" && t("Aisle {{aisle}} - Bays", { aisle: selectedAisle?.name })}
                                                {view === "shelf" && t("Bay {{bay}} - Shelves", { bay: selectedBay?.name })}
                                                {view === "bin" && t("Shelf {{shelf}} - Bin Locations", { shelf: selectedShelf?.name })}
                                            </h5>
                                        </div>
                                        <div className="col-sm-auto">
                                            <div className="d-flex gap-1 flex-wrap">
                                                {view === "area" && <NewAreaButton />}
                                                {view === "aisle" && <><NewAisleButton /><NewBinLocationButton target="area" /></>}
                                                {view === "bay" && <NewBayButton />}
                                                {view === "shelf" && <NewShelfButton />}
                                                {view === "bin" && <NewBinLocationButton target="shelf" />}
                                            </div>
                                        </div>
                                    </Row>
                                </CardHeader>
                                <CardBody className="pt-0">
                                    <Row>
                                        <Restricted require="management.company" read>
                                            <Col sm={6} md={6} lg={3}>
                                                <BusyOverlay busy={loading.init} size="sm">
                                                    <CompanySelect value={selectedCompany?.companyId} onSelect={setSelectedCompany} />
                                                </BusyOverlay>
                                            </Col>
                                        </Restricted>
                                        <Col sm={6} md={6} lg={3}>
                                            <BusyOverlay busy={loading.init} size="sm">
                                                <WarehouseSelect value={warehouse?.warehouseId} companyId={selectedCompany?.companyId} 
                                                    onSelect={warehouse => warehouse && setSearchParams({ warehouse: warehouse.warehouseId })} />
                                            </BusyOverlay>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    {loading.init ? 
                        <Loader height="300px" />
                    :
                        <>
                            <Row>
                                <Col xs={12}>
                                    <div className="px-2 py-1 mb-2">
                                        <Breadcrumb listClassName='mb-0'>
                                            {warehouse && <BreadcrumbItem>
                                                <button className="btn btn-link p-0" onClick={() => loadDataDebounced()}>{warehouse.name}</button>
                                            </BreadcrumbItem>}
                                            {selectedArea && <BreadcrumbItem>
                                                <button className="btn btn-link p-0" onClick={() => dispatch(selectArea(selectedArea))}>{selectedArea.name}</button>
                                            </BreadcrumbItem>}
                                            {selectedAisle && <BreadcrumbItem>
                                                <button className="btn btn-link p-0" onClick={() => dispatch(selectAisle(selectedAisle))}>{t("Aisle {{name}}", { name: selectedAisle.name })}</button>
                                            </BreadcrumbItem>}
                                            {selectedBay && <BreadcrumbItem>
                                                <button className="btn btn-link p-0" onClick={() => dispatch(selectBay(selectedBay, selectedAisle))}>{t("Bay {{name}}", { name: selectedBay.name })}</button>
                                            </BreadcrumbItem>}
                                            {selectedShelf && <BreadcrumbItem>
                                                <button className="btn btn-link p-0">{t("Shelf {{name}}", { name: selectedShelf.name })}</button>
                                            </BreadcrumbItem>}
                                        </Breadcrumb>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col xs={12}>
                                    {view === "area" && <AreasView />}
                                    {view === "aisle" && <AislesView />}
                                    {view === "bay" && <BaysView />}
                                    {view === "shelf" && <ShelvesView />}
                                    {view === "bin" && <BinLocationsView />}
                                    {!view && <div style={{ height: "300px" }}>
                                        <InfoAlert>{t("Please make a warehouse selection to view warehouse areas")}</InfoAlert>
                                    </div>}
                                </Col>
                            </Row>
                        </>}
                </Container>
            </div>
        </>
    );
};

export default LocationsPage;
