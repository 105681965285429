import { Card, CardBody, CardHeader, CardTitle, FormGroup } from "reactstrap";
import type { EditSectionProps } from "./PrintDocumentEditPage";
import { useTranslation } from "react-i18next";
import Checkbox from "Components/Form/Checkbox";
import { useImmer } from "use-immer";
import { useEffect } from "react";
import type { BinLabelPrintPreferences } from "api/types/contracts/printing";

const BinLabelEditSection = (props: EditSectionProps) => {
    const [values, updateValues] = useImmer(props.value as Record<keyof BinLabelPrintPreferences, string | undefined>);

    const { t } = useTranslation();

    useEffect(() => {
        props.onChange(values);
    }, [values])

    return <>
        <Card>
            <CardHeader>
                <CardTitle tag="h6" className="mb-0">{t("Label Customization")}</CardTitle>
            </CardHeader>
            <CardBody>
                <FormGroup>
                    <Checkbox switch value={values.showBinType === "true"} onChange={val => {
                        if (val === (values.showBinType === "true")) {
                            return;
                        }
                        
                        updateValues(prev => {
                            prev.showBinType = val ? "true" : "false";
                            return prev;
                        })
                    }}>
                        <span>{t("Show Bin Type")}</span>
                        <br />
                        <span className="text-muted fw-light">
                            {t("paragraphs:BinLabelShowBinTypeDescription")}
                        </span>
                    </Checkbox>
                </FormGroup>
            </CardBody>
        </Card>
    </>;
}

export default BinLabelEditSection;