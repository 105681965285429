import SelectInput from "Components/Form/SelectInput"
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { CarrierImportTaxPricingRuleContract } from "api/types/contracts/carrier";
import { EntitySelectInputProps } from "helpers/types";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { loadImportTaxPricingRules } from "slices/carrierRules/thunk";

type CarrierImportTaxPricingRuleSelectProps = EntitySelectInputProps<CarrierImportTaxPricingRuleContract, "carrierImportTaxPricingRuleId"> & {

};

const CarrierImportTaxPricingRuleSelect = ({ isMulti, value, onChange, onSelect, name, isValid, ...rest }: CarrierImportTaxPricingRuleSelectProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { importTaxPricingRules, loading } = useAppSelector(
        state => ({
            importTaxPricingRules: state.CarrierRules.importTaxPricing?.items ?? [],
            loading: state.CarrierRules.loading.importTaxPricing.list
        })
    );

    const rulesList = useMemo(() => importTaxPricingRules.map(c => ({ value: c.carrierImportTaxPricingRuleId, label: c.name})), [importTaxPricingRules]);

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = importTaxPricingRules.filter(c => val.includes(c.carrierImportTaxPricingRuleId));

        if (selected) {
            onSelect?.(selected);
            onChange?.(val);
            return;
        }

        onChange?.([]);
        onSelect?.([]);
    };

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            const selected = importTaxPricingRules.find(c => c.carrierImportTaxPricingRuleId === val);

            if (selected) {
                onSelect?.(selected);
                onChange?.(selected.carrierImportTaxPricingRuleId);
                return;
            }
        }

        onChange?.(undefined);
        onSelect?.(undefined);
    };

    useEffect(() => {
        dispatch(loadImportTaxPricingRules({
            page: 1,
            pageSize: 9999
        }));
    }, []);

    useEffect(() => {
        if (!value) {
            return;
        }

        if (importTaxPricingRules.length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [importTaxPricingRules]);
    
    const selectProps = {
        ...rest,
        placeholder: t("Carrier Import Tax Pricing Rule"),
        busy: loading,
        options: rulesList,
        showClear: true,
        isValid: isValid
    }

    return <>
        {isMulti && <SelectInput<string> isMulti value={value} onChange={onChangedMulti} {...selectProps} />}
        {!isMulti && <SelectInput value={value} onChange={onChangedSingle} {...selectProps} />}
    </>;
}

export default CarrierImportTaxPricingRuleSelect;
