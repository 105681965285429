import { DangerAlert } from 'Components/Alerts';
import BusyOverlay from 'Components/Common/BusyOverlay';
import Restricted from 'Components/Common/Restricted';
import BinTypeSelect from 'Components/EnumSelects/BinTypeSelect';
import NumberInput from 'Components/Form/NumberInput';
import ValidationWrapper from 'Components/Form/Validated/ValidationWrapper';
import ValidatorButton from 'Components/Form/Validated/ValidatorButton';
import { useMetadata } from 'Components/Hooks/MetadataHooks';
import { useAppDispatch, useAppSelector } from 'Components/Hooks/StoreHooks';
import { BinContract, BinForm, BinType } from 'api/types/contracts/bin';
import { useFormik } from 'formik';
import { TypedShape } from 'helpers/types';
import { useTranslation } from 'react-i18next';
import { Col, Form, Input, Label, Row } from 'reactstrap';
import { updateBin } from 'slices/bins/thunk';
import * as Yup from 'yup';

type FormProps = {
    bin: BinContract,
    onSuccess: VoidFunction
}

const createBinForm = (bin: BinContract): BinForm => {
    return {
        type: bin.type,
        height: bin.height,
        width: bin.width,
        length: bin.length
    }
};

const UpdateBinForm = (props: FormProps) => {
    const bin = createBinForm(props.bin);
    const dispatch = useAppDispatch();
    const { setPageTitle } = useMetadata();
    const { t } = useTranslation();
    const { loading, error } = useAppSelector(
        (state) => ({
            error: state.Location.error,
            loading: state.Location.loading
        })
    );

    setPageTitle(`${t("Update Bin")} - ${t("Management")}`);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: bin,
        validationSchema: Yup.object<BinForm, TypedShape<BinForm>>({
            type: Yup.string<BinType>().required(t("Please enter bin type")),
            height: Yup.number().notRequired(),
            width: Yup.number().notRequired(),
            length: Yup.number().notRequired()
        }),
        onSubmit: async (values) => {
            const success = await dispatch(updateBin({
                binId: props.bin.binId,
                bin: values
            }));
            
            if (success) {
                props.onSuccess();
            }
        },
    });
    
    return <>
        <Form className="needs-validation" action="#" onSubmit={validation.handleSubmit}>
            <div>
                <div className="mb-4">
                    <div>
                        <h5 className="mb-1">{t("Edit Bin")}</h5>
                    </div>
                </div>
                <Row>
                    <Restricted require="management.company" read>
                        <Col xs={4} className='g-3'>
                            <Label>{t("Company")}</Label>
                            <Input type="text" readOnly className="bg-light" defaultValue={props.bin.warehouse.company.name} />
                        </Col>
                    </Restricted>
                    <Col xs={4} className='g-3'>
                        <Label>{t("Warehouse")}</Label>
                        <Input type="text" readOnly className="bg-light" defaultValue={props.bin.warehouse.name} />
                    </Col>
                    <Col xs={4} className='g-3'>
                        <Label htmlFor="binType">{t("Bin Type")}</Label>
                        <ValidationWrapper validation={validation} field="type">
                            <BinTypeSelect />
                        </ValidationWrapper>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="width">{t("Width ({{unit}})", { unit: "inch" })} ({t("Optional")})</Label>
                        <ValidationWrapper validation={validation} field="width">
                            <NumberInput placeholder="Enter Bin width" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="height">{t("Height ({{unit}})", { unit: "inch" })} ({t("Optional")})</Label>
                        <ValidationWrapper validation={validation} field="height">
                            <NumberInput placeholder="Enter Bin height" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="length">{t("Depth ({{unit}})", { unit: "inch" })} ({t("Optional")})</Label>
                        <ValidationWrapper validation={validation} field="length">
                            <NumberInput placeholder="Enter Bin length" />
                        </ValidationWrapper>
                    </Col>
                </Row>
            </div>
            <div className="mt-4">{error && <DangerAlert>{error.message}</DangerAlert>}</div>
            <div className="d-flex justify-content-end gap-3">
                <BusyOverlay busy={loading.save} size="sm">
                    <ValidatorButton type="submit" validation={validation} className="btn btn-primary btn-label right nexttab">
                        <i className="ri-save-3-line label-icon align-middle fs-16 ms-2"></i>
                        {t("Save")}
                    </ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
    </>;
}

export default UpdateBinForm;