import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { LoginInfo } from "api/types/contracts/users";
import { getLoggedInUser } from "helpers/localStorage";
import { ApiError } from "helpers/types";

export type LoginState = {
  user?: LoginInfo,
  error?: ApiError,
  loading: boolean,
  isUserLogout: boolean,
  errorMsg: boolean
}

export const initialState: LoginState = {
  user: getLoggedInUser(),
  error: undefined, // for error message
  loading: false,
  isUserLogout: false,
  errorMsg: false, // for error
};

const loginSlice  = createSlice({
  name: "login",
  initialState,
  reducers: {
    loading(state) {
      state.loading = true;
    },
    apiError(state, action: PayloadAction<ApiError>) {
      state.error = action.payload;
      state.loading = false;
      state.isUserLogout = false;
      state.errorMsg = true;
    },
    loginSuccess(state, action: PayloadAction<LoginInfo>) {
      state.user = action.payload;
      state.error = undefined;
      state.loading = false;
      state.errorMsg = false;
    },
    logoutUserSuccess(state) {
      state.isUserLogout = true
    },
    reset_login_flag(state) {
      state.error = undefined;
      state.loading = false;
      state.errorMsg = false;
    }
  },
});

export const {
  apiError,
  loginSuccess,
  logoutUserSuccess,
  reset_login_flag
  ,loading
} = loginSlice.actions

export default loginSlice.reducer;