import { Container } from "reactstrap";
import BinPutaway from "./_BinPutaway";
import ItemPutaway from "./_ItemPutaway";
import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import { useTranslation } from "react-i18next";

type PutawayPageProps = {
    forBin?: boolean,
    forItem?: boolean,
};

const PutawayPage = (props: PutawayPageProps) => {
    const { t } = useTranslation();

    return <>
        <div className="page-content">
            <Container fluid>
                <TitleBreadcrumb active={t("Put Away")} parents={[t("Putaway Bin")]} />
                {props.forBin && <BinPutaway />}
                {props.forItem && <ItemPutaway />}
            </Container>
        </div>
    </>;
};

export default PutawayPage;