import type { PrintingSetupContract } from "api/types/contracts/printing";
import { GetPrinterPreferencesQuery } from "api/types/queries";
import Loader from "Components/Common/Loader";
import Restricted from "Components/Common/Restricted";
import TitleBreadcrumb from "Components/Common/TitleBreadcrumb"
import ClientPrinterSelect from "Components/EntitySelects/ClientPrinterSelect";
import UserSelect from "Components/EntitySelects/UserSelect";
import { useDebounce } from "Components/Hooks/HelperHooks";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { createAppSelector, useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Card, CardBody, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Label, Row, Table, UncontrolledButtonDropdown } from "reactstrap"
import { loadPrintingPreferences, savePrinterPreference } from "slices/printers/thunk";
import { useImmer } from "use-immer";

const templateTypeMap: (keyof PrintingSetupContract)[] = [
    "shippingLabel",
    "packingSlip",
    "invoice",
    "binLabel",
    "pickList",
    "fnskuLabel",
    "locationLabel",
    "binLocationLabel"
];

const PrintingSetupPage = () => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { setPageTitle } = useMetadata();
    const [query, updateQuery] = useImmer<GetPrinterPreferencesQuery>({
        userId: undefined
    });
    
    setPageTitle(`${t("Printer Setup")} - ${t("Management")}`);

    const { loading, printPreferences } = useAppSelector(
        createAppSelector([state => state.Printers],
            (printers) => ({
                loading: printers.loading,
                printPreferences: printers.printerPreferences 
            })
        )
    );

    const loadPrintingPreferencesDebounced = useDebounce(() => {
        dispatch(loadPrintingPreferences(query));
    }, 500, [query]);

    useEffect(() => {
        loadPrintingPreferencesDebounced();
    }, [loadPrintingPreferencesDebounced]);

    const savePreferredPrinter = async (printPreferenceId: string, printerId: string | undefined) => {
        await dispatch(savePrinterPreference({
            printPreferenceId: printPreferenceId,
            preferredClientPrinterId: printerId
        }));

        await dispatch(loadPrintingPreferences(query, true));
    }

    return <>
        <div className="page-content">
            <Container fluid>
                <TitleBreadcrumb active={t("Printer Setup")} parents={[t("Management")]} />
                <Card body>
                    <h5 className="mb-3">
                        <i className="ri-printer-fill me-2"></i>
                        {t("Printing Setup")}
                    </h5>
                    <p>{t("paragraphs:PrintingSetupDescription")}</p>
                    <Restricted require="management.printer" write>
                        <Row>
                            <Col xs={12} md={6}>
                                <FormGroup row className="align-items-center" noMargin>
                                    <Label className="col-6 mb-0">{t("Select operator")}</Label>
                                    <Col xs={6}>
                                        <UserSelect value={query.userId} onChange={val => updateQuery(q => {
                                            q.userId = val;
                                        })} />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Restricted>
                </Card>
                <Card>
                    <CardBody>
                        <div className="table-card table-responsive">
                            <Table className="align-middle mb-0" borderless>
                                <thead>
                                    <tr>
                                        <th style={{ width: "25%" }}>{t("Document Type")}</th>
                                        <th style={{ width: "30%" }}>{t("Print To")}</th>
                                        <th>{t("Document Format")}</th>
                                        <th style={{ width: "10%" }}>{t("Options")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {loading.preferenceList ? <tr>
                                        <td colSpan={4}>
                                            <Loader height="30px" />
                                        </td>
                                    </tr> : <>
                                        {templateTypeMap.map((templateType, i) => <React.Fragment key={i}>
                                            {printPreferences && <tr>
                                                <td>{t(`enums:PrintTemplateType.${templateType}`)}</td>
                                                <td>
                                                    <ClientPrinterSelect disabled={loading.preferenceSave} size="sm" value={printPreferences[templateType].preferredClientPrinter?.clientPrinterId ?? ""} onChange={val => {
                                                        if (printPreferences[templateType].preferredClientPrinter?.clientPrinterId === val) {
                                                            return;
                                                        }
                                                        
                                                        savePreferredPrinter(printPreferences[templateType].printPreferenceId, val);
                                                    }} />
                                                </td>
                                                <td>
                                                    {printPreferences[templateType].printSize.name}
                                                </td>
                                                <td>
                                                    <UncontrolledButtonDropdown>
                                                        <DropdownToggle color="ghost-primary" size="sm" className="btn-icon">
                                                            <i className="ri-settings-5-fill fs-16"></i>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem tag={Link} to={`/printing-setup/${printPreferences[templateType]?.printPreferenceId}`}>
                                                                {t("Edit Document")}
                                                            </DropdownItem>
                                                        </DropdownMenu>
                                                    </UncontrolledButtonDropdown>
                                                </td>
                                            </tr>}
                                        </React.Fragment>)}
                                    </>}
                                </tbody>
                            </Table>
                        </div>
                    </CardBody>
                </Card>
            </Container>
        </div>
    </>;
}

export default PrintingSetupPage;