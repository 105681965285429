import { useTranslation } from "react-i18next";
import { Button, PopoverBody, Table, UncontrolledPopover } from "reactstrap";
import Radiobox from "Components/Form/Radiobox";
import { CarrierAccountServiceContract, PackageInfoContract, ShipmentRateContract } from "api/types/contracts/carrier";
import CountryDisplay from "Components/Displays/CountryDisplay";
import _ from "lodash";
import Currency from "Components/Displays/UnitDisplay/Currency";
import Weight from "Components/Displays/UnitDisplay/Weight";
import { useEffect, useMemo } from "react";
import { getCarrierAccountServiceCost, loadCarrierAccountServices } from "slices/carrier/thunk";
import BusyOverlay from "Components/Common/BusyOverlay";
import { useDebounce } from "Components/Hooks/HelperHooks";
import { ShipmentChargesContract } from "api/types/contracts/shipping";
import { WarningAlert } from "Components/Alerts";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";

type CarrierSelectTableProps = {
    boxes: PackageInfoContract[],
    originWarehouseId: string,
    shipmentTargetId: string,
    initialRates?: Record<string, ShipmentChargesContract>,
    disableAutoRates?: boolean,
    disabled?: boolean,
    static?: boolean,
    value?: string,
    onSelect?: (selected: {
        carrierAccountService: CarrierAccountServiceContract,
        rates: ShipmentRateContract | undefined,
    }) => void
}

const CarrierSelectTable = (props: CarrierSelectTableProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { list } = useAppSelector(
        (state) => ({
            list: state.Carrier.carrierAccountServices
        })
    );

    const onSelect = (item: typeof list[number]) => {
        if (props.disabled) {
            return;
        }

        props.onSelect?.({
            carrierAccountService: item.carrierAccountService,
            rates: {
                ...item,
                success: !!item.success
            }
        });
    }

    const totalWeight = useMemo(() => _.sumBy(props.boxes, b => b.weight), [props.boxes]);
    const listIdentifiers = list.map(l => l.carrierAccountService.carrierAccountServiceId).join(",");

    const refreshRate = (item: typeof list[number]) => {
        if (!item.rateLoading) {
            dispatch(getCarrierAccountServiceCost({
                originWarehouseId: props.originWarehouseId,
                carrierAccountServiceId: item.carrierAccountService.carrierAccountServiceId,
                shipmentTargetId: props.shipmentTargetId,
                packageTypeCode: "",
                shipmentCode: "",
                packages: props.boxes
            })).then(result => {
                if (result && item.carrierAccountService.carrierAccountServiceId === props.value) {
                    onSelect({
                        carrierAccountService: item.carrierAccountService,
                        rateLoading: false,
                        ...result
                    });
                }
            });
        }
    }

    const debouncedRefreshShipmentCost = useDebounce(() => {
        if (!props.disableAutoRates && props.boxes.length > 0) {
            list.forEach(item => {
                refreshRate(item);
            });
        }
    }, 1000);

    useEffect(() => {
        dispatch(loadCarrierAccountServices({
            shipmentTargetId: props.shipmentTargetId
        }));
    }, [props.shipmentTargetId]);

    useEffect(() => {
        if (totalWeight) {
            debouncedRefreshShipmentCost();
        }
    }, [listIdentifiers, totalWeight]);

    useEffect(() => {
        if (!props.disableAutoRates) {
            debouncedRefreshShipmentCost();
        }
    }, [props.disableAutoRates]);

    return <>
        {list.length === 0 ? <>
            <WarningAlert kind="default" className="m-2">{t("No carrier services available")}</WarningAlert>
        </> : <>
            <Table className="mb-0 align-middle">
                <thead>
                    <tr>
                        <th>&nbsp;</th>
                        <th>{t("Logo")}</th>
                        <th>{t("Carrier Name")}</th>
                        <th>{t("Destination Country")}</th>
                        <th>{t("Box Count")}</th>
                        <th>{t("Total Weight")}</th>
                        <th>{t("Import Tax")}</th>
                        <th>{t("Shipping Cost")}</th>
                        <th>{t("Total Cost")}</th>
                        {!props.static && <th></th>}
                    </tr>
                </thead>
                <tbody>
                    {list.map((item, i) => {
                        const initialRate = props.initialRates?.[item.carrierAccountService.carrierAccountServiceId];

                        return <tr key={i}>
                            <td>
                                <Radiobox 
                                    name="carrier"
                                    disabled={props.disabled || !item.success}
                                    value={props.value === item.carrierAccountService.carrierAccountServiceId} 
                                    onChange={() => onSelect(item)} />
                            </td>
                            <td className="text-center">
                                <img src={item.carrierAccountService.carrierService.logoImage ?? item.carrierAccountService.carrierService.carrier.logoImage} alt={item.carrierAccountService.carrierService.name} style={{ maxWidth: "100px", maxHeight: "35px" }} />
                            </td>
                            <td>
                                <h6 className="mb-0">{item.carrierAccountService.carrierService.carrier.name}</h6>
                                <span className="text-muted small">{item.carrierAccountService.carrierService.name}</span>
                            </td>
                            <td>
                                <CountryDisplay country={item.carrierAccountService.country} />
                            </td>
                            <td>{props.boxes.length}</td>
                            <td><Weight value={_.sumBy(props.boxes, b => b.weight)} unit={props.boxes.length > 0 ? props.boxes[0].weightUnit : undefined} /></td>
                            <td className="text-center">
                                {props.static || initialRate ? <>
                                    <Currency value={initialRate?.importTaxCost} currency={item.currencyCode} />
                                </> : <>
                                    <BusyOverlay busy={item.rateLoading} opaque size="sm" backgroundColor="white" inline spinnerColor="tenant-primary">
                                        <Currency value={item.importTaxCost} currency={item.currencyCode} />
                                    </BusyOverlay>
                                </>}
                            </td>
                            <td className="text-center">
                                {props.static || initialRate ? <>
                                    <Currency value={initialRate?.shippingCost} currency={item.currencyCode} />
                                </> : <>
                                    <BusyOverlay busy={item.rateLoading} opaque size="sm" backgroundColor="white" inline spinnerColor="tenant-primary">
                                        <Currency value={item.shippingCost} currency={item.currencyCode} />
                                    </BusyOverlay>
                                </>}
                            </td>
                            <td className="text-center">
                                {props.static || initialRate ? <>
                                    <Currency value={initialRate?.totalCost} currency={item.currencyCode} />
                                </> : <>
                                    <BusyOverlay busy={item.rateLoading} opaque size="sm" backgroundColor="white" inline spinnerColor="tenant-primary">
                                        {item.success === undefined ? <>
                                            <span>-</span>
                                        </> : item.success ? <>
                                            <Currency value={item.totalCost} currency={item.currencyCode} />
                                        </> : <>
                                            <UncontrolledPopover target={`carrier-${i}-info`} trigger="legacy">
                                                <PopoverBody>
                                                    <div>
                                                        <span>{item.message}</span>
                                                    </div>
                                                </PopoverBody>
                                            </UncontrolledPopover>
                                            <i className="ri-alert-fill text-warning cursor-pointer fs-4" id={`carrier-${i}-info`}></i>
                                        </>}
                                    </BusyOverlay>
                                </>}
                            </td>
                            {!props.static && <td>
                                {props.disableAutoRates && 
                                    <Button color="ghost-success" disabled={item.rateLoading} className="btn-icon" size="sm" onClick={() => refreshRate(item)}>
                                        <i className="ri-refresh-line fs-4"></i>
                                    </Button>}
                            </td>}
                        </tr>;
                    })}
                </tbody>
            </Table>
        </>}
    </>;
}

export default CarrierSelectTable;