import Select, { CSSObjectWithLabel, GroupBase, OptionsOrGroups, PropsValue, StylesConfig } from "react-select";
import CreatableSelect from 'react-select/creatable';
import { FocusEventHandler, KeyboardEventHandler, useEffect, useId, useState } from "react";
import { SelectOption } from "helpers/types";
import Checkbox from "./Checkbox";
import _ from "lodash";
import classNames from "classnames";
import { useTranslation } from "react-i18next";
import Radiobox from "./Radiobox";

type SingleSelectProps<T> = {
    display?: "select" | "radio" | "inline-radio",
    isMulti?: false,
    value?: T,
    onChange?: (val: T | undefined) => void,
    optionDisabled?: (opt: SelectOption<T> | GroupBase<SelectOption<T>>) => boolean
}

type MultiSelectProps<T> = {
    display?: "select" | "checkbox" | "inline-checkbox" | "tags"
    isMulti: true,
    value?: T[],
    onChange?: (val: T[]) => void,
    optionDisabled?: (opt: SelectOption<T> | GroupBase<SelectOption<T>>) => boolean
}

export type GeneralSelectProps = {
    name?: string,
    className?: string,
    placeholder?: string,
    controlClassNames?: string,
    label?: string,
    disabled?: boolean,
    size?: "sm" | "lg",
    showClear?: boolean,
    busy?: boolean,
    isValid?: boolean,
    onSearch?: (val?: string) => void,
    onBlur?: FocusEventHandler<HTMLInputElement>
}

// 'ref' extends keyof P ? Omit<P, 'ref'> : P
// IsMulti extends boolean = 'isMulti' extends keyof S ? true : false
export type SelectInputProps<T, S = SingleSelectProps<T> | MultiSelectProps<T>> = S & GeneralSelectProps & {
    options: OptionsOrGroups<SelectOption<T>, GroupBase<SelectOption<T>>>,
    styles?: StylesConfig<SelectOption<T> | GroupBase<SelectOption<T>>, boolean, GroupBase<SelectOption<T>>>
}

const SelectInput = <T,>(props: SelectInputProps<T>) => {
    const display = props.display;
    return <>
        {(!display || display === "select") && <DropdownInput {...props} />}
        {((display === "checkbox" || display === "inline-checkbox") && props.isMulti) && <CheckboxGroupInput {...props} />}
        {(display === "radio" || display === "inline-radio") && <RadioboxGroupInput {...props} />}
        {(display === "tags" && props.isMulti) && <TagsInput {...props} />}
    </>;
};

const CheckboxGroupInput = <T,>(props: SelectInputProps<T, MultiSelectProps<T>>) => {
    const nonGroupOptions = _.compact(props.options.map(opt => "value" in opt ? opt : undefined));
    const optionGroups = _.compact(props.options.map(opt => "options" in opt ? opt : undefined));
    const [selected, setSelected] = useState(nonGroupOptions.filter(opt => props.value?.includes(opt.value) === true).map(opt => opt.value));
    const inline = props.display === "inline-checkbox";
    
    const onChange = (opt: SelectOption<T>) => {
        setSelected(prev => {
            if (prev.includes(opt.value)) {
                return prev.filter(v => v !== opt.value);
            }
            else {
                return [...prev, opt.value];
            }
        });
    }

    useEffect(() => {
        props.onChange?.(selected);
    }, [selected]);

    return <>
        <ul className={classNames("w-100 h-100 mb-0 list-unstyled", props.className, { "list-group": optionGroups.length > 0, "list-inline": optionGroups.length === 0 && inline })}>
            {optionGroups.length > 0 ? 
                optionGroups.map((opt, i) => <li key={i} className={classNames("list-group-item")}>
                    <h5>{opt.label}</h5>
                    <ul className={classNames({ "list-inline": inline })}>
                        {opt.options.map((o, j) => <li key={j} className={classNames({ "list-inline-item": inline })}>
                            {<Checkbox disabled={props.disabled} value={selected.includes(o.value)} onChange={() => onChange(o)}>{o.label}</Checkbox>}
                        </li>)}
                    </ul>
                </li>)
            :
                nonGroupOptions.map((opt, i) => <li key={i} className={classNames({ "list-inline-item": inline })}>
                    {<Checkbox disabled={props.disabled} value={selected.includes(opt.value)} onChange={() => onChange(opt)}>{opt.label}</Checkbox>}
                </li>)
            }
        </ul>
    </>;
}

const RadioboxGroupInput = <T,>(props: SelectInputProps<T, SingleSelectProps<T>>) => {
    const nonGroupOptions = _.compact(props.options.map(opt => "value" in opt ? opt : undefined));
    const optionGroups = _.compact(props.options.map(opt => "options" in opt ? opt : undefined));
    const [selected, setSelected] = useState(nonGroupOptions.find(opt => props.value === opt.value));
    const id = useId();
    const inline = props.display === "inline-radio";
    
    const onChange = (opt: SelectOption<T>) => {
        setSelected(opt);
    }

    useEffect(() => {
        props.onChange?.(selected?.value);
    }, [selected]);

    return <>
        <ul className={classNames("w-100 h-100 mb-0 list-unstyled", props.className, { "list-group": optionGroups.length > 0, "list-inline": optionGroups.length === 0 && inline })}>
            {optionGroups.length > 0 ? 
                optionGroups.map((opt, i) => <li key={i} className={classNames("list-group-item")}>
                    <h5>{opt.label}</h5>
                    <ul className={classNames({ "list-inline": inline })}>
                        {opt.options.map((o, j) => <li key={j} className={classNames({ "list-inline-item": inline })}>
                            {<Radiobox name={`${id}_${i}_${j}`} disabled={props.disabled} value={o.value === selected?.value} onChange={() => onChange(o)}>{o.label}</Radiobox>}
                        </li>)}
                    </ul>
                </li>)
            :
                nonGroupOptions.map((opt, i) => <li key={i} className={classNames({ "list-inline-item": inline })}>
                    {<Radiobox name={`${id}_${i}`} disabled={props.disabled} value={opt.value === selected?.value} onChange={() => onChange(opt)}>{opt.label}</Radiobox>}
                </li>)
            }
        </ul>
    </>;
}

const DropdownInput = <T,>(props: SelectInputProps<T>) => {
    let selected: PropsValue<SelectOption<T>> | undefined = undefined;
    let customStyles: StylesConfig<SelectOption<T> | GroupBase<SelectOption<T>>, boolean, GroupBase<SelectOption<T>>>;
    const { t } = useTranslation();

    const onChange = props.isMulti ? (opt: any) => props.onChange?.(opt?.filter((o: any) => !!o).map((o: any) => o.value)) : (opt: any) => props.onChange?.(opt?.value === "" ? undefined : opt?.value);
    const defaultOptionsDisabledCallback = ((opt: (SelectOption<T> | GroupBase<SelectOption<T>>)) => "disabled" in opt ? opt.disabled ?? false : false);

    const nonGroupOptions = _.compact(props.options.map(opt => "value" in opt ? opt : undefined));
    if (props.isMulti) {
        selected = nonGroupOptions.filter(opt => opt ? props.value?.includes(opt.value) : false);
        let controlStyles: CSSObjectWithLabel = {};
        let inputStyles: CSSObjectWithLabel = {};
        let indicatorStyles: CSSObjectWithLabel = {};

        if (props.size === "sm") {
            controlStyles = {
                minHeight: "28px",
                fontSize: "0.72rem",
                lineHeight: "17px"
            };

            inputStyles = {
                paddingTop: "1px",
                paddingBottom: "1px"
            };

            indicatorStyles = {
                padding: "2px"
            }
        }
        customStyles = {
            ...(props.styles || {}),
            control: (base, props) => ({ ...base, backgroundColor: props.isDisabled ? "var(--gray-200) !important" : "var(--secondary-bg)", color: "var(--body-color)", borderColor: props.isDisabled ? "var(--border-color) !important" : "var(--border-color)", ...controlStyles }),
            input: (base, props) => ({ ...base, color: "var(--body-color)", ...inputStyles  }),
            singleValue: (base, props) => ({ ...base, color: "var(--body-color)" }),
            dropdownIndicator: (base, props) => ({ ...base, ...indicatorStyles }),
            clearIndicator: (base, props) => ({ ...base, ...indicatorStyles }),
            multiValue: (base, props) => ({ ...base, backgroundColor: "var(--primary)", color: "var(--white)" }),
            multiValueLabel: (base, props) => ({ ...base, color: "var(--white)" }),
            indicatorSeparator: (base, props) => ({ ...base, borderColor: "var(--border-color)" }),
            menu: (base, props) => ({ ...base, backgroundColor: "var(--secondary-bg)", color: "var(--body-color)", borderColor: "var(--border-color)", zIndex: 9999 }),
            option: (base, props) => ({ 
                ...base, 
                backgroundColor: props.isDisabled ? "var(--gray-300) !important" : props.isSelected ? "var(--primary)" : "var(--secondary-bg)", 
                color: props.isSelected ? "var(--white)" : "var(--body-color)" 
            }),
            menuPortal: (base, props) => ({ ...base, zIndex: 9999 })
        };
    }
    else {
        selected = nonGroupOptions.find(opt => opt ? opt.value === props.value : false);
        let controlStyles: CSSObjectWithLabel = {};
        let inputStyles: CSSObjectWithLabel = {};
        let indicatorStyles: CSSObjectWithLabel = {};

        if (props.size === "sm") {
            controlStyles = {
                minHeight: "28px",
                fontSize: "0.72rem",
                lineHeight: "17px"
            };

            inputStyles = {
                paddingTop: "1px",
                paddingBottom: "1px"
            };

            indicatorStyles = {
                padding: "2px"
            }
        }

        customStyles = {
            ...(props.styles || {}),
            control: (base, props) => ({ ...base, backgroundColor: props.isDisabled ? "var(--boxed-body-bg) !important" : "var(--secondary-bg)", color: "var(--body-color)", borderColor: "var(--border-color)", ...controlStyles }),
            input: (base, props) => ({ ...base, color: "var(--body-color)", ...inputStyles  }),
            singleValue: (base, props) => ({ ...base, color: "var(--body-color)" }),
            dropdownIndicator: (base, props) => ({ ...base, ...indicatorStyles }),
            clearIndicator: (base, props) => ({ ...base, ...indicatorStyles }),
            menu: (base, props) => ({ ...base, backgroundColor: "var(--secondary-bg)", color: "var(--body-color)", borderColor: "var(--border-color)", zIndex: 9999 }),
            option: (base, props) => ({ 
                ...base, 
                backgroundColor: props.isSelected ? "var(--primary)" : "var(--secondary-bg)", 
                color: props.isDisabled ? "var(--light-text-emphasis)" : props.isSelected ? "var(--light)" : "var(--body-color)" 
            }),
            menuPortal: (base, props) => ({ ...base, zIndex: 9999 })
        };
    }
    
    return <Select
        name={props.name}
        className={classNames("mb-0", props.className, {
            "is-invalid": props.isValid === false,
            "is-valid": props.isValid === true
        })}
        isDisabled={props.disabled}
        isLoading={props.busy}
        filterOption={(options, input) => !props.busy && new RegExp(input, "i").test(options.label)}
        loadingMessage={({ inputValue }) => <span>{t("Loading")}...</span>}
        placeholder={props.placeholder}
        isMulti={props.isMulti}
        isOptionDisabled={props.optionDisabled ?? defaultOptionsDisabledCallback}
        value={selected}
        options={props.options}
        onBlur={props.onBlur}
        onChange={onChange}
        onInputChange={val => props.onSearch?.(val)}
        isClearable={props.showClear}
        menuPlacement="auto"
        menuPortalTarget={document.body}
        classNames={{
            control: p => classNames(p.className, {
                "border-danger": props.isValid === false,
                "border-success": props.isValid === true
            })
        }}
        styles={customStyles} />
}

const tagsInputComponents = {
    DropdownIndicator: null,
};

const TagsInput = <T,>(props: SelectInputProps<T, MultiSelectProps<T>>) => {
    let customStyles: StylesConfig<SelectOption<T> | GroupBase<SelectOption<T>>, boolean, GroupBase<SelectOption<T>>>;

    customStyles = {
        ...(props.styles || {}),
        menuPortal: (base, props) => ({ ...base, zIndex: 9999 })
    };

    const [inputValue, setInputValue] = useState('');
    const [value, setValue] = useState<readonly SelectOption<T>[]>([]);

    const handleKeyDown: KeyboardEventHandler = (event) => {
        if (!inputValue) {
            return;
        }

        switch (event.key) {
            case 'Enter':
            case 'Tab':
                setValue(prev => [...prev, { label: inputValue, value: inputValue as T }]);
                setInputValue('');
                event.preventDefault();
                break;
        }
    };
    
    return <CreatableSelect
        name={props.name}
        className={classNames("mb-0", props.className, {
            "is-invalid": props.isValid === false,
            "is-valid": props.isValid === true
        })}
        isDisabled={props.disabled}
        isLoading={props.busy}
        components={tagsInputComponents}
        placeholder={props.placeholder}
        isClearable={props.showClear}
        isMulti
        value={value}
        onChange={(newValue) => setValue(newValue as any)}
        onInputChange={setInputValue}
        onKeyDown={handleKeyDown}
        inputValue={inputValue}
        menuIsOpen={false}
        classNames={{
            control: p => classNames(p.className, {
                "border-danger": props.isValid === false,
                "border-success": props.isValid === true
            })
        }}
        styles={customStyles} />
}

export default SelectInput;