import { InfoAlert } from "Components/Alerts"
import DateDisplay from "Components/Displays/DateDisplay"
import { useAppSelector } from "Components/Hooks/StoreHooks"
import { ReceivingEntryContract } from "api/types/contracts/receivings"
import classNames from "classnames"
import _ from "lodash"
import React, { useEffect, useMemo, useState } from "react"
import { useTranslation } from "react-i18next"
import { Accordion, AccordionItem, Collapse, Table } from "reactstrap"

type BoxesProps = {
    receivings: ReceivingEntryContract[]
}

const Boxes = ({ receivings }: BoxesProps) => {
    const { t } = useTranslation();
    const allBoxes = useMemo(() => receivings.flatMap(receiving => receiving.boxes.map(b => ({
        ...b,
        receivingEntryId: receiving.receivingEntryId,
        receivingNumber: receiving.receivingNumber
    }))), [receivings]);
    const [openBoxes, setOpenBoxes] = useState<string[]>([]);

    useEffect(() => {
        setOpenBoxes(allBoxes.map(b => b.receivingBoxId));
    }, [allBoxes]);

    // const { loading, inbound, error } = useAppSelector(
    //     (state) => ({
    //         error: state.Inbound.error,
    //         inbound: state.Inbound.processingItem!,
    //         loading: state.Inbound.loading
    //     })
    // );

    const toggleActive = (boxId: string) => {
        setOpenBoxes(prev => prev.includes(boxId) ? prev.filter(id => id !== boxId) : [...prev, boxId]);
    }

    return allBoxes.length > 0 ? <>
        <Accordion open={openBoxes} flush toggle={a => {}}>
            {allBoxes.map((receivingBox, index) => {
                const isOpen = openBoxes.includes(receivingBox.receivingBoxId);

                return <React.Fragment key={index}>
                    <AccordionItem id={receivingBox.receivingBoxId} className="mb-2">
                        <h2 className="accordion-header">
                            <button className={classNames("accordion-button border border-success", {
                                "collapsed rounded-3": !isOpen,
                                "bg-success-subtle rounded-top-3 border-bottom-0": isOpen
                            })} type="button" onClick={() => toggleActive(receivingBox.receivingBoxId)}>
                                <span className="d-inline-flex gap-2">
                                    <span>
                                        <strong>{t("Box ID")}: </strong>
                                        <span>#{receivingBox.box.boxNumber}</span>
                                    </span>
                                    <span>
                                        <strong>{t("Receiving ID")}: </strong>
                                        <span>#{receivingBox.receivingNumber}</span>
                                    </span>
                                    <span>
                                        <strong>{t("Total Received")}: </strong>
                                        <span>{_.sumBy(receivingBox.box.contents, c => c.sellableQuantity + c.unusableQuantity)}</span>
                                    </span>
                                    <span>
                                        <strong>{t("Total Sellable")}: </strong>
                                        <span>{_.sumBy(receivingBox.box.contents, c => c.sellableQuantity)}</span>
                                    </span>
                                    <span>
                                        <strong>{t("Total Damaged")}: </strong>
                                        <span>{_.sumBy(receivingBox.box.contents, c => c.unusableQuantity)}</span>
                                    </span>
                                    <span>
                                        <strong>{t("Carrier")}: </strong>
                                        <span>{receivingBox.carrier?.name ?? "-"}</span>
                                    </span>
                                    <span>
                                        <strong>{t("Track ID")}: </strong>
                                        <span>{receivingBox.trackingNumber}</span>
                                    </span>
                                    <span>
                                        <strong>{t("Created At")}: </strong>
                                        <span><DateDisplay date={receivingBox.createdAt} /></span>
                                    </span>
                                </span>
                            </button>
                        </h2>
                        <Collapse isOpen={isOpen}>
                            <div className="accordion-body border border-success rounded-bottom-3">
                                {receivingBox.box.contents?.length === 0 ? <>
                                    <InfoAlert className="mb-0">{t("No products have been added to this box.")}</InfoAlert>
                                </>
                                : <div className="table-responsive table-card">
                                    <Table striped className="table-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th>{t("SKU")}</th>
                                                <th>{t("ASIN")}</th>
                                                <th>{t("Total Received")}</th>
                                                <th>{t("Total Sellable")}</th>
                                                <th>{t("Total Unusable")}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {receivingBox.box.contents.map((content, index) => <tr key={index}>
                                                <td>{content.product.sku}</td>
                                                <td>{content.product.asin}</td>
                                                <td>{content.unusableQuantity + content.sellableQuantity}</td>
                                                <td>{content.sellableQuantity}</td>
                                                <td>{content.unusableQuantity}</td>
                                            </tr>)}
                                        </tbody>
                                    </Table>
                                </div>}
                            </div>
                        </Collapse>
                    </AccordionItem>
                </React.Fragment>;
            })}
        </Accordion>
    </>
    : <>
        <InfoAlert className="mb-0">{t("No boxes have been created for this inbound.")}</InfoAlert>
    </>;
}

export default Boxes;