import { DangerAlert } from "Components/Alerts";
import BusyOverlay from "Components/Common/BusyOverlay";
import RequiredLabel from "Components/Common/RequiredLabel";
import NumberInput from "Components/Form/NumberInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { CreateBayCommand } from "api/types/commands";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import { useTranslation } from "react-i18next";
import { Col, Form, Label, Row } from "reactstrap";
import { createBay } from "slices/location/thunk";
import * as Yup from "yup";

type FormProps = {
    aisleId: string,
    onSuccess: VoidFunction
}

type FormType = Omit<CreateBayCommand, "aisleId">;

const CreateBayFormView = (props: FormProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { loading, error } = useAppSelector(
        (state) => ({
            error: state.Location.error,
            loading: state.Location.loading
        })
    );

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {} as FormType,
        validationSchema: Yup.object<FormType, TypedShape<FormType>>({
            bayCount: Yup.number().required("You must enter bay count").min(1, t("Shelf bay must be greater than zero")),
            shelfCount: Yup.number().notRequired(),
            bayHeight: Yup.number().notRequired(),
            bayWidth: Yup.number().notRequired(),
            shelfHeight: Yup.number().notRequired(),
            shelfWidth: Yup.number().notRequired(),
            shelfDepth: Yup.number().notRequired(),
        }),
        onSubmit: async (values) => {
            const success = await dispatch(createBay({ 
                aisleId: props.aisleId,
                ...values
            }));
            
            if (success) {
                props.onSuccess();
            }
        },
    });

    return <>
        <Form className="needs-validation form-steps" action="#" onSubmit={validation.handleSubmit}>
            <div>
                <div className="mb-4">
                    <div>
                        <h5 className="mb-1">{t("Add Bays")}</h5>
                        <p className="text-muted">
                            
                        </p>
                    </div>
                </div>
                <Row>
                    <Col lg={4} className="g-3">
                        <RequiredLabel htmlFor="bayCount">{t("Bay Count")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="bayCount">
                            <NumberInput placeholder="Enter Bay Count" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="shelfCount">{t("Shelf Count")}</Label>
                        <ValidationWrapper validation={validation} field="shelfCount">
                            <NumberInput placeholder="Enter shelf count on each bay" />
                        </ValidationWrapper>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="bayWidth">{t("Bay Width ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="bayWidth">
                            <NumberInput placeholder="Enter bay width" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="bayHeight">{t("Bay Height ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="bayHeight">
                            <NumberInput placeholder="Enter bay height" />
                        </ValidationWrapper>
                    </Col>
                </Row>
                {validation.values.shelfCount !== 0 && <Row>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="shelfWidth">{t("Shelf Width ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="shelfWidth">
                            <NumberInput placeholder="Enter shelf width" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="shelfHeight">{t("Shelf Height ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="shelfHeight">
                            <NumberInput placeholder="Enter shelf height" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="shelfDepth">{t("Shelf Depth ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="shelfDepth">
                            <NumberInput placeholder="Enter shelf width" />
                        </ValidationWrapper>
                    </Col>
                </Row>}
            </div>
            <div className="mt-4">{error && <DangerAlert>{error.message}</DangerAlert>}</div>
            <div className="d-flex justify-content-end gap-3">
                <BusyOverlay busy={loading.save} size="sm">
                    <ValidatorButton type="submit" validation={validation} className="btn btn-primary btn-label right nexttab">
                        <i className="ri-save-3-line label-icon align-middle fs-16 ms-2"></i>
                        {t("Save")}
                    </ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
    </>;
};

export default CreateBayFormView;
