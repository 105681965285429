import { loading, setImportTaxPricingRules, setInsuranceRules, setOversizeRules, setOverweightRules } from "./reducer";
import { deleteCarrierImportTaxPricingRule, deleteCarrierInsuranceRule, deleteCarrierOversizeRule, deleteCarrierOverweightRule, getCarrierImportTaxPricingRuleList, getCarrierInsuranceRuleList, getCarrierOversizeRuleList, getCarrierOverweightRuleList, postCarrierImportTaxPricingRule, postCarrierInsuranceRule, postCarrierOversizeRule, postCarrierOverweightRule, putCarrierImportTaxPricingRule, putCarrierInsuranceRule, putCarrierOversizeRule, putCarrierOverweightRule } from "api/carrierRules";
import { ListCarrierImportTaxPricingRulesQuery, ListCarrierInsuranceRulesQuery, ListCarrierOversizeRulesQuery, ListCarrierOverweightRulesQuery } from "api/types/queries";
import { FaultResponse } from "helpers/api_helper";
import { CreateCarrierImportTaxPricingRuleCommand, CreateCarrierInsuranceRuleCommand, CreateCarrierOversizeRuleCommand, CreateCarrierOverweightRuleCommand, DeleteCarrierImportTaxPricingRuleCommand, DeleteCarrierInsuranceRuleCommand, DeleteCarrierOversizeRuleCommand, DeleteCarrierOverweightRuleCommand, UpdateCarrierImportTaxPricingRuleCommand, UpdateCarrierInsuranceRuleCommand, UpdateCarrierOversizeRuleCommand, UpdateCarrierOverweightRuleCommand } from "api/types/commands";
import { toast } from "react-toastify";
import i18n from "i18n";
import { AppDispatch, RootState } from "slices";

export const loadOversizeRules = (params: ListCarrierOversizeRulesQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["oversize", "list", true]));

        var result = await getCarrierOversizeRuleList(params);
        
        dispatch(setOversizeRules(result));
    } catch (error) {
        const fault = error as FaultResponse;
    }
    finally {
        dispatch(loading(["oversize", "list", false]));
    }
};

export const loadInsuranceRules = (params: ListCarrierInsuranceRulesQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["insurance", "list", true]));

        var result = await getCarrierInsuranceRuleList(params);
        
        dispatch(setInsuranceRules(result));
    } catch (error) {
        const fault = error as FaultResponse;
    }
    finally {
        dispatch(loading(["insurance", "list", false]));
    }
}

export const loadOverweightRules = (params: ListCarrierOverweightRulesQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["overweight", "list", true]));

        var result = await getCarrierOverweightRuleList(params);
        
        dispatch(setOverweightRules(result));
    } catch (error) {
        const fault = error as FaultResponse;
    }
    finally {
        dispatch(loading(["overweight", "list", false]));
    }
}

export const loadImportTaxPricingRules = (params: ListCarrierImportTaxPricingRulesQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["importTaxPricing", "list", true]));

        var result = await getCarrierImportTaxPricingRuleList(params);
        
        dispatch(setImportTaxPricingRules(result));
    } catch (error) {
        const fault = error as FaultResponse;
    }
    finally {
        dispatch(loading(["importTaxPricing", "list", false]));
    }
}

export const createInsuranceRule = (params: CreateCarrierInsuranceRuleCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["insurance", "save", true]));

        var result = await postCarrierInsuranceRule(params);

        toast.success(i18n.t("Insurance rule created successfully"));

        return result;
    } catch (error) {
        const fault = error as FaultResponse;
    }
    finally {
        dispatch(loading(["insurance", "save", false]));
    }
}

export const createImportTaxPricingRule = (params: CreateCarrierImportTaxPricingRuleCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["importTaxPricing", "save", true]));

        var result = await postCarrierImportTaxPricingRule(params);

        toast.success(i18n.t("Import tax pricing rule created successfully"));

        return result;
    } catch (error) {
        const fault = error as FaultResponse;
    }
    finally {
        dispatch(loading(["importTaxPricing", "save", false]));
    }
}

export const createOverweightRule = (params: CreateCarrierOverweightRuleCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["overweight", "save", true]));

        var result = await postCarrierOverweightRule(params);

        toast.success(i18n.t("Overweight rule created successfully"));

        return result;
    } catch (error) {
        const fault = error as FaultResponse;
    }
    finally {
        dispatch(loading(["overweight", "save", false]));
    }
}

export const createOversizeRule = (params: CreateCarrierOversizeRuleCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["oversize", "save", true]));

        var result = await postCarrierOversizeRule(params);

        toast.success(i18n.t("Oversize rule created successfully"));

        return result;
    } catch (error) {
        const fault = error as FaultResponse;
    }
    finally {
        dispatch(loading(["oversize", "save", false]));
    }
}

export const updateInsuranceRule = (params: UpdateCarrierInsuranceRuleCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["insurance", "save", true]));

        await putCarrierInsuranceRule(params);

        toast.success(i18n.t("Insurance rule updated successfully"));

        return true;
    } catch (error) {
        const fault = error as FaultResponse;
        
        return false;
    }
    finally {
        dispatch(loading(["insurance", "save", false]));
    }
}

export const updateImportTaxPricingRule = (params: UpdateCarrierImportTaxPricingRuleCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["importTaxPricing", "save", true]));

        await putCarrierImportTaxPricingRule(params);

        toast.success(i18n.t("Import tax pricing rule updated successfully"));

        return true;
    } catch (error) {
        const fault = error as FaultResponse;
        
        return false;
    }
    finally {
        dispatch(loading(["importTaxPricing", "save", false]));
    }
}

export const updateOverweightRule = (params: UpdateCarrierOverweightRuleCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["overweight", "save", true]));

        await putCarrierOverweightRule(params);

        toast.success(i18n.t("Overweight rule updated successfully"));

        return true;
    } catch (error) {
        const fault = error as FaultResponse;
        
        return false;
    }
    finally {
        dispatch(loading(["overweight", "save", false]));
    }
}

export const updateOversizeRule = (params: UpdateCarrierOversizeRuleCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["oversize", "save", true]));

        await putCarrierOversizeRule(params);

        toast.success(i18n.t("Oversize rule updated successfully"));

        return true;
    } catch (error) {
        const fault = error as FaultResponse;
        
        return false;
    }
    finally {
        dispatch(loading(["oversize", "save", false]));
    }
}

export const removeInsuranceRule = (params: DeleteCarrierInsuranceRuleCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["insurance", "delete", true]));

        await deleteCarrierInsuranceRule(params);
        return true;
    } catch (error) {
        const fault = error as FaultResponse;
        
        return false;
    }
    finally {
        dispatch(loading(["insurance", "delete", false]));
    }
}

export const removeImportTaxPricingRule = (params: DeleteCarrierImportTaxPricingRuleCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["importTaxPricing", "delete", true]));

        await deleteCarrierImportTaxPricingRule(params);
        return true;
    } catch (error) {
        const fault = error as FaultResponse;
        
        return false;
    }
    finally {
        dispatch(loading(["importTaxPricing", "delete", false]));
    }
}

export const removeOverweightRule = (params: DeleteCarrierOverweightRuleCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["overweight", "delete", true]));

        await deleteCarrierOverweightRule(params);
        return true;
    } catch (error) {
        const fault = error as FaultResponse;
        
        return false;
    }
    finally {
        dispatch(loading(["overweight", "delete", false]));
    }
}

export const removeOversizeRule = (params: DeleteCarrierOversizeRuleCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["oversize", "delete", true]));

        await deleteCarrierOversizeRule(params);

        return true;
    } catch (error) {
        const fault = error as FaultResponse;
        
        return false;
    }
    finally {
        dispatch(loading(["oversize", "delete", false]));
    }
}