
/**
 * Delays the execution of code for a specified number of milliseconds.
 *
 * @param milliseconds - The number of milliseconds to delay.
 * @returns A promise that resolves after the specified delay.
 */
const delay = (milliseconds: number) => new Promise(resolve => {
    setTimeout(resolve, milliseconds);
});

export default delay;