import BusyOverlay from "Components/Common/BusyOverlay";
import RequiredLabel from "Components/Common/RequiredLabel";
import CountryDisplay from "Components/Displays/CountryDisplay";
import CustomerDisplay from "Components/Displays/CustomerDisplay";
import FbaShipmentStatusBadge from "Components/Displays/FbaShipmentStatusBadge";
import Currency from "Components/Displays/UnitDisplay/Currency";
import PrepServiceSelect from "Components/EntitySelects/PrepServiceSelect";
import NumberInput from "Components/Form/NumberInput";
import CurrencyInput from "Components/Form/UnitInputs/CurrencyInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { FbaShipmentContract, PrepServiceContract } from "api/types/contracts/shipping";
import { useFormik } from "formik";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Form, FormGroup, Row, Table } from "reactstrap";
import * as Yup from "yup";
import { createFbaShipmentInvoice } from "slices/shipping/fbaShipping/thunk";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { TypedShape } from "helpers/types";
import { UnitOfCurrency } from "api/types/contracts/common";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import type { InvoiceForm, InvoiceItemForm } from "api/types/contracts/payment";

type EditFormProps = {
    fbaShipment: FbaShipmentContract
};

const EditForm = ({ fbaShipment }: EditFormProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const [params, setParams] = useSearchParams();
    const [selectedPrepService, setSelectedPrepService] = useState<PrepServiceContract>();
    const currency = fbaShipment.warehouse.settings.unitOfCurrency ?? "usd";
    const isFirst = params.get("first") === "true";
    
    const validation = useFormik({
        initialValues: {
            //invoiceCode: "",
            importCost: 0,
            shippingCost: 0,
            unitOfCurrency: currency,
            items: []
        } as InvoiceForm,
        validationSchema: Yup.object<InvoiceForm, TypedShape<InvoiceForm>>({
            importCost: Yup.number().required(t("Import cost is required")),
            shippingCost: Yup.number().required(t("Shipping cost is required")),
            //invoiceCode: Yup.string().required(t("Invoice code is required")),
            unitOfCurrency: Yup.string<UnitOfCurrency>().required(t("Currency is required")),
            items: Yup.array<InvoiceItemForm>(Yup.object<InvoiceItemForm, TypedShape<InvoiceItemForm>>({
                count: Yup.number().required(t("Count is required")),
                discount: Yup.number().notRequired(),
                prepServiceId: Yup.string().required(t("Prep service is required")),
                serviceName: Yup.string().required(t("Service name is required")),
                total: Yup.number().required(t("Total is required")),
                unitPrice: Yup.number().required(t("Unit price is required"))
            }).required()).required()
        }),
        onSubmit: async values => {
            const invoice = await dispatch(createFbaShipmentInvoice({
                fbaShipmentId: fbaShipment.fbaShipmentId,
                invoice: values
            }));

            if (invoice) {
                navigate(`/invoice/${invoice.invoiceId}`);
            }
        }
    });

    const { prepServices, prepServicesLoading, createInvoiceLoading } = useAppSelector(
        (state) => ({
            prepServices: state.PrepService.list?.items || [], 
            prepServicesLoading: state.PrepService.loading.list,
            createInvoiceLoading: state.FbaShipping.loading.invoiceAdd
        })
    );

    const addPrepService = () => {
        if (selectedPrepService) {
            validation.setFieldValue("items", [...validation.values.items, {
                count: 1,
                discount: 0,
                prepServiceId: selectedPrepService.prepServiceId,
                serviceName: selectedPrepService.name,
                total: selectedPrepService.price,
                unitPrice: selectedPrepService.price
            }]);

            setSelectedPrepService(undefined);
        }
    }

    useEffect(() => {        
        if (isFirst) {
            const cost = fbaShipment.actualCost ?? fbaShipment.estimatedCost;

            validation.setFieldValue("shippingCost", cost.shipping);
            validation.setFieldValue("importCost", cost.import);
            validation.setFieldValue("items", cost.items.map(i => ({
                count: i.count,
                discount: i.discount,
                prepServiceId: i.prepServiceId,
                serviceName: i.serviceName,
                total: i.unitPrice * i.count,
                unitPrice: i.unitPrice,
            })));
        }
    }, [isFirst]);

    return <>
        <Card body>
            <dl className="row align-items-center mb-0 g-2">
                <dt className="col-2">{t("FBA Shipment ID")}</dt>
                <dd className="col-2 mb-0"><Link to={`fba-shipment/${fbaShipment.fbaShipmentId}`}>{fbaShipment.shipmentCode}</Link></dd>
                <dt className="col-2">{t("Status")}</dt>
                <dd className="col-2 mb-0"><FbaShipmentStatusBadge value={fbaShipment.status} /></dd>
                <dt className="col-2">{t("Country")}</dt>
                <dd className="col-2 mb-0"><CountryDisplay country={fbaShipment.shipmentTarget.address.country} /></dd>

                <dt className="col-2">{t("Customer")}</dt>
                <dd className="col-2 mb-0"><CustomerDisplay customer={fbaShipment.customer} /></dd>
                <dt className="col-2">{t("W/T Products")}</dt>
                <dd className="col-2 mb-0">{0}/{_.sumBy(fbaShipment.items, "count")}</dd>
                <dt className="col-2">{t("Excepted Total Price")}</dt>
                <dd className="col-2 mb-0"><Currency value={fbaShipment.actualCost?.total ?? fbaShipment.estimatedCost.total} currency={currency} /></dd>

                <dt className="col-2">{t("Invoice ID")}</dt>
                <dd className="col-2 mb-0">{t("N/A")}</dd>
                <dt className="col-2">{t("Carrier")} / {t("Carrier Service")}</dt>
                <dd className="col-2 mb-0">{fbaShipment.carrierAccountService.carrierService.carrier.name} / {fbaShipment.carrierAccountService.carrierService.name}</dd>
                <dt className="col-2">{t("Boxes")}</dt>
                <dd className="col-2 mb-0">{fbaShipment.packages ? fbaShipment.packages.length : fbaShipment.estimatedBoxes.length}</dd>
            </dl>
        </Card>
        <Form className="needs-validation" action="#" onSubmit={validation.handleSubmit}>
            <Card>
                <CardHeader>
                    <CardTitle tag="h5">{t("Shipping Cost")}</CardTitle>
                </CardHeader>
                <CardBody>
                    <FormGroup row className="align-items-center">
                        <RequiredLabel className="col-3">{t("Shipping Cost")}</RequiredLabel>
                        <div className="col-2">
                            <ValidationWrapper validation={validation} field="shippingCost">
                                <CurrencyInput currency={currency} />
                            </ValidationWrapper>
                        </div>
                    </FormGroup>
                    <FormGroup row className="align-items-center">
                        <RequiredLabel className="col-3">{t("Import Duty & Customs Tax")}</RequiredLabel>
                        <div className="col-2">
                            <ValidationWrapper validation={validation} field="importCost">
                                <CurrencyInput currency={currency} />
                            </ValidationWrapper>
                        </div>
                    </FormGroup>
                    <Row>
                        <Col className="text-end">
                            {t("Total")}
                        </Col>
                        <Col xs={1}>
                            <Currency value={validation.values.importCost + validation.values.shippingCost} currency={fbaShipment.warehouse.settings.unitOfCurrency} />
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Card>
                <CardHeader>
                    <div className="d-flex justify-content-between align-items-center">
                        <CardTitle tag="h5">{t("Items")}</CardTitle>
                        <div className="hstack">
                            <PrepServiceSelect channel="fba" warehouseId={fbaShipment.warehouse.warehouseId} 
                                value={selectedPrepService?.prepServiceId} onSelect={setSelectedPrepService} />
                            <Button type="button" color="info" className="ms-2" onClick={addPrepService}>{t("Add")}</Button>
                        </div>
                    </div>
                </CardHeader>
                <CardBody>
                    <div className="table-responsive table-card">
                        <Table className="mb-0 align-middle" borderless size="sm">
                            <thead className="table-light text-muted">
                                <tr>
                                    <th>{t("Prep Service")}</th>
                                    <th style={{ width: "10%" }}>{t("Unit Price")}</th>
                                    <th style={{ width: "10%" }}>{t("Count")}</th>
                                    <th style={{ width: "10%" }}>{t("Sub Total")}</th>
                                    <th style={{ width: "10%" }}>{t("Discount")}</th>
                                    <th style={{ width: "10%" }}>{t("Total")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {validation.values.items.map((item, i) => {
                                    const prepService = prepServices.find(p => p.prepServiceId === item.prepServiceId);

                                    return <tr key={i}>
                                        <td>{item.serviceName}</td>
                                        <td><Currency currency={currency} value={item.unitPrice} /></td>
                                        <td>
                                            <ValidationWrapper validation={validation} field={`items.${i}.count`}>
                                                <NumberInput size="sm" onChange={val => {
                                                    validation.setFieldValue(`items.${i}.total`, ((val ?? 0) * item.unitPrice) - (item.discount ?? 0));
                                                }} />
                                            </ValidationWrapper>
                                        </td>
                                        <td>
                                            <CurrencyInput size="sm" currency={currency} disabled value={item.unitPrice * item.count} />
                                        </td>
                                        <td>
                                            <ValidationWrapper validation={validation} field={`items.${i}.discount`}>
                                                <CurrencyInput size="sm" currency={currency} disabled={prepService?.pricingType === "custom"} onChange={val => {
                                                    validation.setFieldValue(`items.${i}.total`, ((item.count ?? 0) * item.unitPrice) - (val ?? 0));
                                                }} />
                                            </ValidationWrapper>
                                        </td>
                                        <td>
                                            <ValidationWrapper validation={validation} field={`items.${i}.total`}>
                                                <CurrencyInput size="sm" currency={currency} disabled={prepService?.pricingType !== "custom"} onChange={val => {
                                                    if (prepService?.pricingType === "custom") {
                                                        validation.setFieldValue(`items.${i}.unitPrice`, val);
                                                    }
                                                }} />
                                            </ValidationWrapper>
                                        </td>
                                    </tr>;
                                })}
                            </tbody>
                            <tfoot>
                                <tr>
                                    <th colSpan={5} className="text-end">{t("Total")}</th>
                                    <th><Currency currency={currency} value={_.sumBy(validation.values.items, "total")} /></th>
                                </tr>
                            </tfoot>
                        </Table>
                    </div>
                </CardBody>
            </Card>
            <Card body>
                <div className="mx-auto w-50 vstack gap-3">
                    <div className="d-flex justify-content-between p-2">
                        <span>{t("Shipping & Tax")}</span>
                        <span className="fw-bold"><Currency currency={currency} value={validation.values.shippingCost + validation.values.importCost} /></span>
                    </div>
                    <div className="d-flex justify-content-between p-2 border-bottom border-dark">
                        <span>{t("FBA Preps")}</span>
                        <span className="fw-bold"><Currency currency={currency} value={_.sumBy(validation.values.items, "total")} /></span>
                    </div>
                    <div className="d-flex justify-content-end gap-2 p-2">
                        <span className="fs-5">{t("Total")}</span>
                        <span className="fw-bold fs-5"><Currency currency={currency} value={validation.values.shippingCost + validation.values.importCost + _.sumBy(validation.values.items, "total")} /></span>
                    </div>
                </div>
            </Card>
            <div className="d-flex justify-content-center mb-3">
                <BusyOverlay busy={createInvoiceLoading} size="sm">
                    <ValidatorButton validation={validation} color="primary">
                        {t("Create Invoice")}
                    </ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
    </>;
}

export default EditForm;