import { InfoAlert } from "Components/Alerts";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import { RootState } from "slices";

type ProblemsProps = {
    
}

const Problems = (props: ProblemsProps) => {
    const { t } = useTranslation();

    // const { loading, inbound, error } = useAppSelector(
    //     (state) => ({
    //         error: state.Inbound.error,
    //         inbound: state.Inbound.processingItem!,
    //         loading: state.Inbound.loading
    //     })
    // ); 

    return <>
        <InfoAlert className="mb-0">{t("No problems found")}</InfoAlert>
    </>;
}

export default Problems;