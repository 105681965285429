import { WarningAlert } from "Components/Alerts";
import { useTranslation } from "react-i18next";
import Grid from "./_Grid";
import BusyOverlay from "Components/Common/BusyOverlay";
import List from "../BinLocations/_List";
import { useAppSelector } from "Components/Hooks/StoreHooks";

type AislesViewProps = {
    
}

const AislesView = (props: AislesViewProps) => {
    const { t } = useTranslation();

    const { selectedArea, loading } = useAppSelector(
        (state) => ({
            loading: state.Location.loading,
            selectedArea: state.Location.areas?.find(a => a.areaId === state.Location.selectedAreaId)
        })
    );

    return <>
        <BusyOverlay busy={loading.data} blur>
            {selectedArea && (selectedArea.aisles?.length !== 0 || selectedArea.binLocations?.length !== 0) ? <>
                {selectedArea.aisles && <Grid aisles={selectedArea.aisles} />}
                {selectedArea.binLocations && <>
                    <h5 className="my-3">{t("Bin Locations")}</h5>
                    <List binLocations={selectedArea.binLocations} />
                </>}
            </>

            :
                <WarningAlert>{t("There are no defined aisles/bin locations yet")}</WarningAlert>
            }
        </BusyOverlay>
    </>;
}

export default AislesView;