import { setList, loading, apiError } from "./reducer";
import { GetProductQuery, ListProductsQuery } from "api/types/queries";
import { CreateProductCommand, DeleteProductCommand, UpdateProductCommand } from "api/types/commands";
import { ApiError } from "helpers/types";
import { toast } from "react-toastify";
import i18n from 'i18n';
import { deleteProduct, getProduct, getProductList, postProduct, putProduct, searchProductByAsin } from "api/product";
import { AppDispatch, RootState } from "slices";

export const loadProduct = (params: GetProductQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        var result = await getProduct(params);
        
        return result;
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
};

export const searchWithAsin = (asin: string) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["search", true]));

        var result = await searchProductByAsin({ asin });
        
        return result;
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["search", false]));
    }
}

export const loadList = (params: ListProductsQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["list", true]));

        var result = await getProductList(params);
        
        dispatch(setList(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["list", false]));
    }
};

export const createProduct = (params: CreateProductCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true]));

        await postProduct(params);
        toast.success(i18n.t("Product created"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const updateProduct = (params: UpdateProductCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true]));

        await putProduct(params);
        toast.success(i18n.t("Product updated"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const removeProduct = (params: DeleteProductCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["delete", true]));

        await deleteProduct(params);

        toast.success(i18n.t("Product deleted", { count: 1 }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const removeMultiProducts = (params: DeleteProductCommand[]) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["delete", true]));

        for (const item of params) {
            await deleteProduct(item);
        }

        toast.success(i18n.t("Product deleted", { count: params.length }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}