import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { CarrierAccountContract, CarrierAccountServiceContract, CarrierContract, ShipmentRateContract } from "api/types/contracts/carrier";
import { CarrierPackageTypeContract } from "api/types/contracts/shipping";
import { ApiError, LoadingStates } from "helpers/types";

type CarrierLoadingStates = LoadingStates<"list" | "save" | "delete" | "listAccounts" | "listPackageTypes" | "createShipment">;

export type CarrierState = {
    carriers: CarrierContract[],
    carrierAccounts: CarrierAccountContract[],
    carrierAccountServices: (Omit<ShipmentRateContract, "success"> & {
        carrierAccountService: CarrierAccountServiceContract,
        rateLoading: boolean,
        success: boolean | undefined
    })[],
    packageTypes: CarrierPackageTypeContract[],
    loading: CarrierLoadingStates,
    error?: ApiError
}

export const initialState: CarrierState = {
    carriers: [],
    carrierAccounts: [],
    carrierAccountServices: [],
    packageTypes: [],
    loading: {
        list: false,
        save: false,
        delete: false,
        listAccounts: false,
        listPackageTypes: false,
        createShipment: false
    }
};

const CarrierSlice = createSlice({
    name: "CarrierSlice",
    initialState,
    reducers: {
        setCarriers(state, action: PayloadAction<CarrierContract[]>) {
            state.carriers = action.payload;
        },
        setCarrierAccounts(state, action: PayloadAction<CarrierAccountContract[]>) {
            state.carrierAccounts = action.payload;
        },
        setCarrierAccountServices(state, action: PayloadAction<CarrierAccountServiceContract[]>) {
            state.carrierAccountServices = action.payload.map(service => ({
                carrierAccountService: service,
                ratio: 1,
                currencyCode: undefined,
                rateLoading: false,
                shippingCost: 0,
                importTaxCost: 0,
                insuranceCost: 0,
                oversizeCost: 0,
                overweightCost: 0,
                totalCost: 0,
                success: undefined,
                packageRates: [],
                details: {},
                message: undefined,
            }));
        },
        setCarrierAccountServiceLoading(state, action: PayloadAction<[string, boolean]>) {
            const [serviceId, loading] = action.payload;
            const index = state.carrierAccountServices.findIndex(c => c.carrierAccountService.carrierAccountServiceId === serviceId);
            
            if (index !== -1) {
                state.carrierAccountServices[index].rateLoading = loading;
            }
        },
        setCarrierAccountServiceCharge(state, action: PayloadAction<[string, ShipmentRateContract]>) {
            const [serviceId, rates] = action.payload;
            const index = state.carrierAccountServices.findIndex(c => c.carrierAccountService.carrierAccountServiceId === serviceId);
            
            if (index !== -1) {
                state.carrierAccountServices[index].shippingCost = rates.shippingCost;
                state.carrierAccountServices[index].importTaxCost = rates.importTaxCost;
                state.carrierAccountServices[index].insuranceCost = rates.insuranceCost;
                state.carrierAccountServices[index].oversizeCost = rates.oversizeCost;
                state.carrierAccountServices[index].overweightCost = rates.overweightCost;
                state.carrierAccountServices[index].totalCost = rates.totalCost;
                state.carrierAccountServices[index].packageRates = rates.packageRates;
                state.carrierAccountServices[index].message = rates.message;
                state.carrierAccountServices[index].details = rates.details;
                state.carrierAccountServices[index].success = rates.success;
                state.carrierAccountServices[index].currencyCode = rates.currencyCode;
                state.carrierAccountServices[index].ratio = rates.ratio;
            }
        },
        setPackageTypes(state, action: PayloadAction<CarrierPackageTypeContract[]>) {
            state.packageTypes = action.payload;
        },
        loading(state, action: PayloadAction<[keyof CarrierLoadingStates, boolean]>) {
            const [operation, loadingState] = action.payload;
            state.loading[operation] = loadingState;
        },
        apiError(state, action: PayloadAction<ApiError>) {
            state.error = action.payload;
        }
    },
});

export const {
    setCarriers,
    setCarrierAccounts,
    setCarrierAccountServices,
    setCarrierAccountServiceLoading,
    setCarrierAccountServiceCharge,
    setPackageTypes,
    loading,
    apiError
} = CarrierSlice.actions;

export default CarrierSlice.reducer;
