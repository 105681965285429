import { APIClient, PagedList } from "../helpers/api_helper";
import type { ListUnusableProductReasonsQuery } from "api/types/queries";
import type { CreateUnusableProductReasonCommand, DeleteUnusableProductReasonCommand, UpdateUnusableProductReasonCommand } from "api/types/commands";
import type { UnusableProductReasonContract } from "./types/contracts/inventory";

const api = new APIClient();

export const getUnusableProductReasonList = async (data: ListUnusableProductReasonsQuery) => {
    const result = await api.get<PagedList<UnusableProductReasonContract>>(`/api/unusableproductreason`, data);
    return result;
}

export const postUnusableProductReason = async (data: CreateUnusableProductReasonCommand): Promise<void> => {
    await api.post(`/api/unusableproductreason`, data);
}

export const putUnusableProductReason = async (data: UpdateUnusableProductReasonCommand): Promise<void> => {
    await api.put(`/api/unusableproductreason/${data.unusableProductReasonId}`, { ...data.unusableProductReason });
}

export const deleteUnusableProductReason = async (data: DeleteUnusableProductReasonCommand): Promise<void> => {
    await api.delete(`/api/unusableproductreason/${data.unusableProductReasonId}`);
}
