import { DangerAlert } from "Components/Alerts";
import BusyOverlay from "Components/Common/BusyOverlay";
import RequiredLabel from "Components/Common/RequiredLabel";
import NumberInput from "Components/Form/NumberInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { CreateBinLocationCommand } from "api/types/commands";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import { useTranslation } from "react-i18next";
import { Col, Form, Label, Row } from "reactstrap";
import { createBinLocation } from "slices/location/thunk";
import * as Yup from "yup";

type FormProps = {
    areaId?: string,
    shelfId?: string,
    onSuccess: VoidFunction
}

type FormType = Omit<CreateBinLocationCommand, "shelfId" | "areaId">;

const CreateBinLocationFormView = (props: FormProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { loading, error } = useAppSelector(
        (state) => ({
            error: state.Location.error,
            loading: state.Location.loading
        })
    );

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {} as FormType,
        validationSchema: Yup.object<FormType, TypedShape<FormType>>({
            count: Yup.number().required("You must enter bin location count").min(1, t("Bin Location count must be greater than zero")),
            width: Yup.number().notRequired(),
            height: Yup.number().notRequired(),
            depth: Yup.number().notRequired(),
        }),
        onSubmit: async (values) => {
            const success = await dispatch(createBinLocation({ 
                areaId: props.areaId,
                shelfId: props.shelfId,
                ...values
            }));

            if (success) {
                props.onSuccess();
            }
        },
    });

    return <>
        <Form className="needs-validation form-steps" action="#" onSubmit={validation.handleSubmit}>
            <div>
                <div className="mb-4">
                    <div>
                        <h5 className="mb-1">{t("Add Bin Locations")}</h5>
                        <p className="text-muted">
                            
                        </p>
                    </div>
                </div>
                <Row>
                    <Col lg={4} className="g-3">
                        <RequiredLabel htmlFor="count">{t("Count")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="count">
                            <NumberInput placeholder="Enter bin location count" />
                        </ValidationWrapper>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="width">{t("Width ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="width">
                            <NumberInput placeholder="Enter width" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="height">{t("Height ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="height">
                            <NumberInput placeholder="Enter height" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="depth">{t("Depth ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="depth">
                            <NumberInput placeholder="Enter length" />
                        </ValidationWrapper>
                    </Col>
                </Row>
            </div>
            <div className="mt-4">{error && <DangerAlert>{error.message}</DangerAlert>}</div>
            <div className="d-flex justify-content-end gap-3">
                <BusyOverlay busy={loading.save} size="sm">
                    <ValidatorButton type="submit" validation={validation} className="btn btn-primary btn-label right nexttab">
                        <i className="ri-save-3-line label-icon align-middle fs-16 ms-2"></i>
                        {t("Save")}
                    </ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
    </>;
};

export default CreateBinLocationFormView;
