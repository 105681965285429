import SelectInput from "Components/Form/SelectInput"
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { ProductColorContract } from "api/types/contracts/products";
import { ListProductColorsQuery } from "api/types/queries";
import { EntitySelectInputProps, SelectOption, SelfPopulatedSelectInputRef } from "helpers/types";
import { ForwardedRef, RefAttributes, forwardRef, useCallback, useEffect, useImperativeHandle, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { GroupBase } from "react-select";
import { loadList } from "slices/product/color/thunk";
import { useImmer } from "use-immer";

type ProductColorSelectProps = EntitySelectInputProps<ProductColorContract, "colorId"> & {
    userId?: string
}

const ProductColorSelect = ({ isMulti, value, onChange, onSelect, name, isValid, userId, ...rest }: ProductColorSelectProps, ref: ForwardedRef<SelfPopulatedSelectInputRef>) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [query, updateQuery] = useImmer<ListProductColorsQuery>({
        page: 1,
        pageSize: 99,
        search: "",
        userId
    });
    const { colors, loading } = useAppSelector(
        (state) => ({
            colors: state.ProductColor.list?.items || [],
            loading: state.ProductColor.loading.list
        })
    );

    const productColorList = useMemo(() => colors.map(b => ({
        label: b.name,
        value: b.colorId
    })), [colors]);

    const findColor = useCallback((val?: string) => colors.find(c => c.colorId === val), [colors]);

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = colors.filter(c => val.includes(c.colorId));

        if (selected) {
            onSelect?.(selected);
            onChange?.(val);
            return;
        }

        onChange?.([]);
        onSelect?.([]);
    };

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            const selected = colors.find(c => c.colorId === val);

            if (selected) {
                onSelect?.(selected);
                onChange?.(selected.colorId);
                return;
            }
        }

        onChange?.(undefined);
        onSelect?.(undefined);
    };

    useEffect(() => {
        dispatch(loadList(query));
    }, [dispatch, query]);

    useImperativeHandle(ref, () => {
        return {
            reload: () => dispatch(loadList(query))
        };
    }, [dispatch, query]);

    const onSearch = useCallback((val?: string) => {
        updateQuery(q => {
            q.search = val;
        });
    }, [updateQuery]);

    const dot = (data: SelectOption<string> | GroupBase<SelectOption<string>>) => {
        if ("options" in data) 
            return {};

        const color = findColor(data.value)?.hexCode || "#000000";
        return {
            alignItems: 'center',
            display: 'flex',
            ':before': {
                backgroundColor: color,
                borderRadius: 16,
                content: '" "',
                display: 'block',
                marginRight: 8,
                height: 16,
                width: 16,
            },
        };
    }

    useEffect(() => {
        if (!value) {
            return;
        }

        if (colors.length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [colors]);

    const selectProps = {
        ...rest,
        placeholder: t("Product Color"),
        busy: loading,
        options: productColorList,
        onSearch: onSearch,
        showClear: true,
        isValid: isValid
    }

    return <>
        {isMulti && <SelectInput<string> isMulti value={value} onChange={onChangedMulti} {...selectProps} styles={{
            multiValue: (styles, { data }) => ({...styles, ...dot(data) }),
            option: (styles, { data }) => ({...styles, ...dot(data) }),
        }} />}
        {!isMulti && <SelectInput value={value} onChange={onChangedSingle} {...selectProps} styles={{
            singleValue: (styles, { data }) => ({...styles, ...dot(data) }),
            option: (styles, { data }) => ({...styles, ...dot(data) }),
        }} />}
    </>;
}

export default forwardRef(ProductColorSelect) as (
    props: ProductColorSelectProps & RefAttributes<SelfPopulatedSelectInputRef>
) => ReturnType<typeof ProductColorSelect>;