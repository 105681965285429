import Dialog, { DialogRef } from "Components/Common/Dialog";
import { BayContract, ShelfContract } from "api/types/contracts/locations"
import { useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Modal, ModalBody, Row } from "reactstrap";
import { generateShelfLabel, reloadLocations, removeShelf, selectShelf } from "slices/location/thunk";
import BinLocationItem from "../BinLocations/_BinLocationItem";
import UpdateShelfFormView from "./_UpdateShelfFormView";
import classNames from "classnames";
import NewBinLocationButton from "../BinLocations/_NewBinLocationButton";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import BusyOverlay from "Components/Common/BusyOverlay";

type ShelfItemProps = {
    shelf: ShelfContract,
    bay: BayContract,
    mini?: boolean,
}

const ShelfItem = (props: ShelfItemProps) => {
    const [editModal, setEditModal] = useState(false);
    const dialogRef = useRef<DialogRef>(null);
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const { warehouse, loading }  = useAppSelector(
        (state) => ({
            warehouse: state.Location.warehouse,
            loading: state.Location.loading
        })
    );

    const reload = () => dispatch(reloadLocations({ warehouseId: warehouse!.warehouseId }));
    const select = () => dispatch(selectShelf(props.shelf, props.bay));

    const onUpdated = () => { 
        setEditModal(false);
        reload();
    };

    const deleteShelf = async () => {
        await dispatch(removeShelf({ shelfId: props.shelf.shelfId }));
        await reload();
        dialogRef.current?.hide();
    };

    const printShelfLabel = async () => {
        await dispatch(generateShelfLabel({ shelfId: props.shelf.shelfId }));
    }

    return <>
        <div className="shelf-item bg-white rounded shadow-sm">
            <div className={classNames("justify-content-start align-items-center d-flex gap-2 mb-2", {
                "ps-2": props.mini,
                "p-2": !props.mini
            })}>
                <h6 className="mb-0">{t("Shelf #{{shelfName}}", { shelfName: props.shelf.name })}</h6>
                <Button onClick={select} type="button" className={classNames("btn-sm btn-ghost-dark btn-icon", {
                    "me-auto": props.mini
                })}>
                    <i className="ri-arrow-right-down-line fs-16"></i>
                </Button>
                <div className="shelf-actions auto-hide-actions">
                    <Button color="ghost-primary" size="sm" className="btn-icon" onClick={() => setEditModal(true)} type="button">
                        <i className=" ri-edit-2-line fs-16"></i>
                    </Button>
                    {!props.mini && <BusyOverlay busy={loading.printLabel} size="sm" inline backgroundColor="white" spinnerColor="tenant-primary" opaque>
                        <Button color="ghost-info" size="sm" className="btn-icon" onClick={() => printShelfLabel()} type="button">
                            <i className="ri-printer-line fs-16"></i>
                        </Button>
                    </BusyOverlay>}
                    <Button color="ghost-danger" size="sm" className="btn-icon" onClick={() => dialogRef.current?.show()} type="button">
                        <i className="ri-delete-bin-line fs-16"></i>
                    </Button>
                </div>
            </div>
            <div className="d-flex justify-content-between align-items-center">
                <div className={classNames("shelf-bins flex-grow-1", {
                    "p-1": props.mini,
                    "p-2 pt-0": !props.mini
                })}>
                    {
                    props.mini ?
                    props.shelf.binLocations && props.shelf.binLocations.length > 0 ? <>
                            <Row className="g-1">
                                {props.shelf.binLocations.slice(0, 8).map(binLocation => <>
                                    <Col xs={3} key={binLocation.binLocationId}>
                                        <BinLocationItem mini binLocation={binLocation} />
                                    </Col>
                                </>)}
                                {props.shelf.binLocations.length > 8 && <>
                                    <Col xs={12}>
                                        <Button block color="light" onClick={select}>{t("More")}</Button>
                                    </Col>
                                </>}
                            </Row>
                        </>
                        :
                        <div className="w-100 text-center">
                            <i className="ri-alert-line ri-3x text-muted"></i>
                            <p>{t("Empty Shelf")}</p>
                        </div>
                    :
                    props.shelf.binLocations && props.shelf.binLocations.length > 0 ? <>
                            <div className="gap-2 hstack">
                                {props.shelf.binLocations.slice(0, 10).map(binLocation => <div style={{ width: "8%" }}><BinLocationItem binLocation={binLocation} key={binLocation.binLocationId} /></div>)}
                                {props.shelf.binLocations.length > 10 && <>
                                    <div>
                                        <Button onClick={select} className="btn-ghost-dark">{t("{{count}} more bin locations", { count: props.shelf.binLocations.length - 10 })}</Button>
                                    </div>
                                </>}
                            </div>
                        </>
                        :
                        <div className="w-100 d-flex justify-content-start align-items-center gap-2 p-2">
                            <i className="ri-error-warning-line ri-2x"></i>
                            <span>{t("There are no defined bin locations yet")}</span>
                        </div>
                    }
                </div>
                {!props.mini && 
                <div>
                    <NewBinLocationButton target="shelf" iconButton shelfId={props.shelf.shelfId} />
                </div>}
            </div>
        </div>
        <Modal isOpen={editModal} toggle={() => setEditModal(prev => !prev)} size="lg" unmountOnClose>
            <ModalBody>
                <ModalCloseButton onClick={() => setEditModal(false)} />
                <UpdateShelfFormView shelf={props.shelf} onSuccess={onUpdated} />
            </ModalBody>
        </Modal>
        <Dialog ref={dialogRef} color="warning" buttons={["yes", "no"]} busy={loading.delete} iconClass="ri-delete-bin-line"  
            message={`Do you want to continue?`} title={t("Deleting \"Shelf #{{shelfName}}\"", { shelfName: props.shelf.name })}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    deleteShelf();
                }
                else {
                    hide();
                }
            }} />
    </>;
}

export default ShelfItem;