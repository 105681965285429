import { APIClient, type PagedList } from "../helpers/api_helper";
import { UpdateUserAvatarCommand, UpdateUserStatusCommand, VerifyEmailCommand } from "./types/commands";
import type { UserContract } from "./types/contracts/users";
import type { ListUsersQuery } from "./types/queries";

const api = new APIClient();

export const patchVerifyEmail = async (data: VerifyEmailCommand) => {
    await api.patch(`/api/user/verify-email`, data);
}

export const patchUpdateUserAvatar = async (data: UpdateUserAvatarCommand) => {
    await api.patch(`/api/user/${data.userId}/avatar`, { avatar: data.avatar });
}

export const patchUpdateUserStatus = async (data: UpdateUserStatusCommand) => {
    await api.patch(`/api/user/${data.userId}/status`, { userStatus: data.userStatus });
}

export const getUserList = async (data: ListUsersQuery) => {
    return await api.get(`/api/user`, data) as PagedList<UserContract>;
}