import classNames from "classnames";
import BusyOverlay from "Components/Common/BusyOverlay";
import Loader from "Components/Common/Loader";
import Restricted from "Components/Common/Restricted";
import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import Checkbox from "Components/Form/Checkbox";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import { createAppSelector, useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { withCdn } from "helpers/urlHelper";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardHeader, CardSubtitle, CardTitle, Col, Container, FormGroup, Row, Table, UncontrolledTooltip } from "reactstrap";
import { loadPrinterList, printTestPage, updatePrinter } from "slices/printers/thunk";

type PrinterListPageProps = {
    
}

const PrinterListPage = (props: PrinterListPageProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { setPageTitle } = useMetadata();
    const [ownPrinterDisabledFilter, setOwnPrinterDisabledFilter] = useState(true);
    const [otherPrinterDisabledFilter, setOtherPrinterDisabledFilter] = useState(true);

    setPageTitle(`${t("Printers")} - ${t("Management")}`);

    const { loading, ownPrinterGroups, otherPrinterGroups } = useAppSelector(
        createAppSelector([state => state.Printers, state => state.Login],
            (printers, login) => {
                const printerGroups = _.chain(printers.printers)
                    .groupBy(p => p.desktopClientInstance.desktopClientInstanceId)
                    .map((printers, desktopClientInstanceId) => ({
                        desktopClientInstance: printers[0].desktopClientInstance,
                        printers
                    }))
                    .value();

                return {
                    loading: printers.loading,
                    ownPrinterGroups: printerGroups.filter(g => g.desktopClientInstance.owner?.userId === login.user?.user.userId),
                    otherPrinterGroups: printerGroups.filter(g => g.desktopClientInstance.owner?.userId !== login.user?.user.userId)
                };
            }
        )
    );

    useEffect(() => {
        dispatch(loadPrinterList({}));
    }, []);

    const disablePrinter = async (printerId: string, disabled: boolean) => {
        await dispatch(updatePrinter({
            clientPrinterId: printerId,
            isDisabled: disabled
        }));
    }

    const sharePrinter = async (printerId: string, shared: boolean) => {
        await dispatch(updatePrinter({
            clientPrinterId: printerId,
            isShared: shared
        }));
    }

    const printTest = async (printerId: string) => {
        await dispatch(printTestPage({
            clientPrinterId: printerId
        }));
    }

    return <>
        <div className="page-content">
            <Container fluid>
                <TitleBreadcrumb active={t("Printers")} parents={[t("Management")]} />
                <h5 className="mb-3">
                    <i className="ri-printer-fill me-2"></i>
                    {t("Printing Management")}
                </h5>
                {loading.list ? <>
                    <Loader height="500px" />
                </> : <>
                    <Card className="mb-3 gap-3">
                        <CardHeader className="border-bottom-0 pb-0">
                            <CardTitle tag="h6">{t("Your Printers")}</CardTitle>
                            <CardSubtitle>{t("paragraphs:YourPrintersDescription")}</CardSubtitle>
                            <FormGroup noMargin className="mt-3">
                                <Checkbox switch value={ownPrinterDisabledFilter} onChange={val => setOwnPrinterDisabledFilter(val ?? false)}>
                                    {t("Show disabled printers")}
                                </Checkbox>
                            </FormGroup>
                        </CardHeader>
                        {ownPrinterGroups.length === 0 ? <>
                            <div className="text-center mb-3">
                                {t("No workstation connected")}
                            </div>
                        </> : <>
                            {ownPrinterGroups.map(({ desktopClientInstance, printers }, i) => {
                                const filteredPrinters = ownPrinterDisabledFilter ? printers : printers.filter(p => !p.isDisabled);

                                return <>
                                    <CardBody>
                                        {<div className="table-responsive table-card">
                                            <Table size="sm" borderless className="align-middle mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <i className=" ri-computer-line me-1"></i>
                                                            <span>{desktopClientInstance.deviceName}</span>
                                                        </th>
                                                        <th className="text-center">
                                                            <span>{t("Disabled")}</span>
                                                            <i className="ri-information-line ms-1" id="disabledOwnPrintersTooltip"></i>
                                                            <UncontrolledTooltip target="disabledOwnPrintersTooltip">
                                                                {t("paragraphs:DisabledPrintersInfoTooltip")}
                                                            </UncontrolledTooltip>
                                                        </th>
                                                        <th className="text-center">
                                                            <span>{t("Shared")}</span>
                                                            <i className="ri-information-line ms-1" id="sharedOwnPrintersTooltip"></i>
                                                            <UncontrolledTooltip target="sharedOwnPrintersTooltip">
                                                                {t("paragraphs:SharedPrintersInfoTooltip")}
                                                            </UncontrolledTooltip>
                                                        </th>
                                                        <th className="text-center">
                                                            <span>{t("Connected")}</span>
                                                            <i className="ri-information-line ms-1" id="connectedOwnPrintersTooltip"></i>
                                                            <UncontrolledTooltip target="connectedOwnPrintersTooltip">
                                                                {t("paragraphs:ConnectedPrintersInfoTooltip")}
                                                            </UncontrolledTooltip>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {filteredPrinters.length === 0 ? <>
                                                        <tr>
                                                            <td colSpan={4} className="text-center">
                                                                {t("No enabled printers found")}
                                                            </td>
                                                        </tr>
                                                    </> : filteredPrinters.map((printer, i) => <tr>
                                                        <td>
                                                            <i className="ri-printer-fill me-1"></i>
                                                            <span>{printer.displayName}</span>
                                                        </td>
                                                        <td>
                                                            <BusyOverlay busy={loading.savePrinter} size="sm" backgroundColor="white" opaque spinnerColor="tenant-primary" overlayClassName="w-100">
                                                                <div className="d-flex justify-content-center">
                                                                    <Checkbox color="warning" switch value={printer.isDisabled} onChange={val => {
                                                                        disablePrinter(printer.clientPrinterId, val ?? false);
                                                                    }} />
                                                                </div>
                                                            </BusyOverlay>
                                                        </td>
                                                        <td>
                                                            <BusyOverlay busy={loading.savePrinter} size="sm" backgroundColor="white" opaque spinnerColor="tenant-primary" overlayClassName="w-100">
                                                                <div className="d-flex justify-content-center">
                                                                    <Checkbox color="info" switch value={printer.isShared} onChange={val => {
                                                                        sharePrinter(printer.clientPrinterId, val ?? false);
                                                                    }} />
                                                                </div>
                                                            </BusyOverlay>
                                                        </td>
                                                        <td className="text-center">
                                                            <i className={classNames("ri-checkbox-blank-circle-fill", {
                                                                "text-success": printer.isConnected,
                                                                "text-danger": !printer.isConnected
                                                            })}></i>
                                                        </td>
                                                    </tr>)}
                                                </tbody>
                                            </Table>
                                        </div>}
                                    </CardBody>
                                </>;
                            })}
                        </>}
                    </Card>
                    <Card className="gap-3">
                        <CardHeader className="border-bottom-0 pb-0">
                            <CardTitle tag="h6">{t("Other User's Printers")}</CardTitle>
                            <CardSubtitle>{t("paragraphs:OtherUsersPrintersDescription")}</CardSubtitle>
                            <FormGroup noMargin className="mt-3">
                                <Checkbox switch value={otherPrinterDisabledFilter} onChange={val => setOtherPrinterDisabledFilter(val ?? false)}>
                                    {t("Show disabled printers")}
                                </Checkbox>
                            </FormGroup>
                        </CardHeader>
                        {otherPrinterGroups.length === 0 ? <>
                            <div className="text-center mb-3">
                                {t("No shared printers found")}
                            </div>
                        </> : <>
                            {otherPrinterGroups.map(({ desktopClientInstance, printers }, i) => {
                                const filteredPrinters = ownPrinterDisabledFilter ? printers : printers.filter(p => !p.isDisabled);

                                return <>
                                    <CardBody>
                                        {<div className="table-responsive table-card">
                                            <Table size="sm" borderless className="align-middle mb-0">
                                                <thead>
                                                    <tr>
                                                        <th>
                                                            <i className=" ri-computer-line me-1"></i>
                                                            <span>{desktopClientInstance.deviceName}</span>
                                                        </th>
                                                        <th className="text-center">
                                                            <span>{t("Disabled")}</span>
                                                            <i className="ri-information-line ms-1" id="disabledOtherPrintersTooltip"></i>
                                                            <UncontrolledTooltip target="disabledOtherPrintersTooltip">
                                                                {t("paragraphs:DisabledPrintersInfoTooltip")}
                                                            </UncontrolledTooltip>
                                                        </th>
                                                        <th className="text-center">
                                                            <span>{t("Shared")}</span>
                                                            <i className="ri-information-line ms-1" id="sharedOtherPrintersTooltip"></i>
                                                            <UncontrolledTooltip target="sharedOtherPrintersTooltip">
                                                                {t("paragraphs:SharedPrintersInfoTooltip")}
                                                            </UncontrolledTooltip>
                                                        </th>
                                                        <th className="text-center">
                                                            <span>{t("Connected")}</span>
                                                            <i className="ri-information-line ms-1" id="connectedOtherPrintersTooltip"></i>
                                                            <UncontrolledTooltip target="connectedOtherPrintersTooltip">
                                                                {t("paragraphs:ConnectedPrintersInfoTooltip")}
                                                            </UncontrolledTooltip>
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                {filteredPrinters.length === 0 ? <>
                                                    <tr>
                                                        <td colSpan={4} className="text-center">
                                                            {t("No enabled printers found")}
                                                        </td>
                                                    </tr>
                                                </> : filteredPrinters.map((printer, i) => <tr>
                                                        <td>
                                                            <i className="ri-printer-fill me-1"></i>
                                                            <span>{printer.displayName}</span>
                                                        </td>
                                                        <td>
                                                            <BusyOverlay busy={loading.savePrinter} size="sm" backgroundColor="white" opaque spinnerColor="tenant-primary" overlayClassName="w-100">
                                                                <div className="d-flex justify-content-center">
                                                                    <Restricted require="management.printer" write fallback={() => printer.isDisabled ? <i className="ri-close-circle-fill text-warning"></i> : <></>}>
                                                                        <Checkbox color="warning" switch value={printer.isDisabled} />
                                                                    </Restricted>
                                                                </div>
                                                            </BusyOverlay>
                                                        </td>
                                                        <td>
                                                            <BusyOverlay busy={loading.savePrinter} size="sm" backgroundColor="white" opaque spinnerColor="tenant-primary" overlayClassName="w-100">
                                                                <div className="d-flex justify-content-center">
                                                                    <Restricted require="management.printer" write fallback={() => printer.isShared ? <i className="ri-checkbox-circle-fill text-info"></i> : <></>}>
                                                                        <Checkbox color="info" switch value={printer.isShared} />
                                                                    </Restricted>
                                                                </div>
                                                            </BusyOverlay>
                                                        </td>
                                                        <td className="text-center">
                                                            <i className={classNames("ri-checkbox-blank-circle-fill", {
                                                                "text-success": printer.isConnected,
                                                                "text-danger": !printer.isConnected
                                                            })}></i>
                                                        </td>
                                                    </tr>)}
                                                </tbody>
                                            </Table>
                                        </div>}
                                    </CardBody>
                                </>;
                            })}
                        </>}
                    </Card>
                </>}
                <Row>
                    <Col xs="auto" className="text-center mx-auto my-4">
                        <p>{t("paragraphs:TylocClientDownloadDescription")}</p>
                        <Button color="primary" className="btn-label btn-left" tag="a" href={withCdn("connect/tyloc-connect-1.0.0%20Setup.exe")}>
                            <i className="ri-download-2-line label-icon align-middle fs-16 me-2"></i>
                            {t("Install Tyloc Connect")}
                        </Button>
                    </Col>
                </Row>
            </Container>
        </div>
    </>;
}

export default PrinterListPage;