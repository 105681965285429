import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import Restricted from "Components/Common/Restricted";
import { Button, Container } from "reactstrap";
import TopInfo from "./_TopInfo";
import { Await, useLoaderData } from "react-router-dom";
import { Suspense } from "react";
import { useTranslation } from "react-i18next";
import Loader from "Components/Common/Loader";
import ShipmentProgress from "./_ShipmentProgress";
import { FbaShipmentContract } from "api/types/contracts/shipping";
import ProductCardGrid from "./_ProductCardGrid";
import EditPackages from "./_EditPackages";
import ViewPackages from "./_ViewPackages";
import { useProfile } from "Components/Hooks/ProfileHooks";
import ProductList from "./_ProductList";
import DocumentUpload from "./_DocumentUpload";
import DocumentList from "./_DocumentList";
import InvoiceList from "./_Invoices";
import { setProcessingItem } from "slices/shipping/fbaShipping/reducer";
import CostBreakdown from "./_CostBreakdown";
import { loadFbaShipment, updateFbaShipment } from "slices/shipping/fbaShipping/thunk";
import BusyOverlay from "Components/Common/BusyOverlay";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import CarrierInfo from "./_CarrierInfo";
import ShipmentList from "./_Shipments";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";

type LoaderData = {
    fbaShipment: Promise<FbaShipmentContract>
} | undefined;

const ProcessFbaShipmentPage = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();
    const loader = useLoaderData() as LoaderData;
    const { userProfile } = useProfile();

    const { stateFbaShipment, loading } = useAppSelector(
        (state) => ({
            stateFbaShipment: state.FbaShipping.processingItem,
            loading: state.FbaShipping.loading
        })
    );

    setPageTitle(`${t("FBA Shipment Detail")} - ${t("FBA Shipment")}`);

    return <>
        <div className="page-content">
            <Container fluid>
                <Restricted require="shipping.fbashipping" throw>
                    <Suspense fallback={<Loader height="500px" />}>
                        <Await resolve={loader?.fbaShipment}>
                            {(resolvedFbaShipment: FbaShipmentContract) => {
                                if (!stateFbaShipment || stateFbaShipment.fbaShipmentId !== resolvedFbaShipment.fbaShipmentId) {
                                    dispatch(setProcessingItem(resolvedFbaShipment));
                                }

                                const fbaShipment = stateFbaShipment ?? resolvedFbaShipment;

                                return <>
                                    <TitleBreadcrumb active={`View #${fbaShipment.shipmentCode}`} parents={[t("FBA Prep & Shipping"), { title: t("FBA Shipments"), to: "/fba-shipments"}]} />
                                    <TopInfo />
                                    <ShipmentProgress />

                                    {fbaShipment.owner.userId === userProfile?.user.userId ? <>
                                        <ProductList />
                                        <ViewPackages />
                                        {fbaShipment.status === "boxLabelPending" ? <DocumentUpload /> : <DocumentList />}
                                    </> : <>
                                        <ProductCardGrid />
                                        {["ready", "processing", "boxLabelPending"].includes(fbaShipment.status) ? <EditPackages /> : <ViewPackages />}
                                        <DocumentList />
                                    </>}
                                    <CarrierInfo />
                                    {["boxLabelPending", "paymentWaiting", "paymentCompleted", "shipped"].includes(fbaShipment.status) && <>
                                        <ShipmentList />
                                    </>}
                                    {["productPending", "fnskuPending", "ready", "processing", "boxLabelPending"].includes(fbaShipment.status) && <>
                                        <CostBreakdown />
                                    </>}
                                    {["boxLabelPending", "paymentWaiting", "paymentCompleted", "shipped"].includes(fbaShipment.status) && <InvoiceList />}
                                    <>
                                        {fbaShipment.status === "paymentCompleted" && <div className="d-flex justify-content-center my-4">
                                            <Restricted require="shipping.fbashipping" write>
                                                <BusyOverlay busy={loading.save} size="sm">
                                                    <Button color="primary" className="w-25" onClick={() => {
                                                        dispatch(updateFbaShipment({
                                                            fbaShipmentId: fbaShipment.fbaShipmentId,
                                                            status: "shipped"
                                                        })).then(() => {
                                                            dispatch(loadFbaShipment({ fbaShipmentId: fbaShipment.fbaShipmentId }));
                                                        });
                                                    }}>
                                                        {t("Send To Amazon")}
                                                    </Button>
                                                </BusyOverlay>
                                            </Restricted>
                                        </div>}
                                    </>
                                </>;
                            }}
                        </Await>
                    </Suspense>
                </Restricted>
            </Container>
        </div>
    </>;
}

export default ProcessFbaShipmentPage;