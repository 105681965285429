import { setList, loading, apiError } from "./reducer";
import { ListProductSizesQuery } from "api/types/queries";
import { CreateProductSizeCommand, DeleteProductSizeCommand, UpdateProductSizeCommand } from "api/types/commands";
import { ApiError } from "helpers/types";
import { toast } from "react-toastify";
import i18n from 'i18n';
import { deleteProductSize, getProductSizeList, postProductSize, putProductSize } from "api/productSize";
import type { AppDispatch, RootState } from "slices";

export const loadList = (params: ListProductSizesQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["list", true]));

        var result = await getProductSizeList(params);
        
        dispatch(setList(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["list", false]));
    }
};

export const createProductSize = (params: CreateProductSizeCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true]));

        const size = await postProductSize(params);
        toast.success(i18n.t("Product size created"));

        return size;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return undefined;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const updateProductSize = (params: UpdateProductSizeCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true]));

        await putProductSize(params);
        toast.success(i18n.t("Product size updated"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const removeProductSize = (params: DeleteProductSizeCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["delete", true]));

        await deleteProductSize(params);

        toast.success(i18n.t("Product size deleted", { count: 1 }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const removeMultiProductSizes = (params: DeleteProductSizeCommand[]) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["delete", true]));

        for (const item of params) {
            await deleteProductSize(item);
        }

        toast.success(i18n.t("Product sizes deleted", { count: params.length }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}