import RequiredLabel from "Components/Common/RequiredLabel";
import WarehouseSelect from "Components/EntitySelects/WarehouseSelect";
import TextInput from "Components/Form/TextInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import { CarrierAccountContract, CarrierAccountForm, CarrierContract, CarrierServiceItemForm } from "api/types/contracts/carrier";
import { useFormik } from "formik";
import { Carriers } from "helpers/constants/identities";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Button, Col, Form, Row } from "reactstrap";
import * as Yup from "yup";
import { TypedShape } from "helpers/types";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import BusyOverlay from "Components/Common/BusyOverlay";
import { useState } from "react";
import { WarningAlert } from "Components/Alerts";
import Checkbox from "Components/Form/Checkbox";
import CountrySelect from "Components/EntitySelects/CountrySelect";
import { createCarrierAccount, updateCarrierAccount } from "slices/carrier/thunk";

import FedexApiForm from "./ApiForms/_Fedex";
import UpsApiForm from "./ApiForms/_Ups";
import UspsApiForm from "./ApiForms/_Usps";
import DhlApiForm from "./ApiForms/_Dhl";
import PurolatorApiForm from "./ApiForms/_Puralotor";
import ApcApiForm from "./ApiForms/_Apc";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";

type EditCarrierAccountFormProps = {
    carrier: CarrierContract,
    carrierAccount?: CarrierAccountContract,
    onCancel?: VoidFunction,
    onSuccess?: (params: { selectedWarehouseId: string }) => void;
};

const createCarrierAccountForm = (carrierAccount: CarrierAccountContract | undefined): Partial<CarrierAccountForm> => {
    return carrierAccount ? {
        warehouseId: carrierAccount.warehouse.warehouseId,
        accountName: carrierAccount.accountName,
        apiParameters: carrierAccount.apiParameters,
        services: _.chain(carrierAccount.carrierAccountServices).groupBy(x => x.carrierServiceId).map((carrierAccountServices, carrierServiceId) => {
            const carrierAccountService = carrierAccountServices[0];

            return {
                carrierServiceId: carrierAccountService.carrierServiceId,
                countries: carrierAccountServices.map(x => x.country.code)
            };
        }).value()
    } : {
        warehouseId: undefined,
        accountName: "",
        apiParameters: {},
        services: []
    };
};

const EditCarrierAccountForm = (props: EditCarrierAccountFormProps) => {
    const { t } = useTranslation();
    const carrierAccount = createCarrierAccountForm(props.carrierAccount);
    const [step, setStep] = useState<"connect" | "services">("connect");
    const dispatch = useAppDispatch();

    const { loading, error } = useAppSelector(
        (state) => ({
            error: state.Carrier.error,
            loading: state.Carrier.loading
        })
    );

    const validation = useFormik({
        initialValues: carrierAccount,
        validationSchema: Yup.object<CarrierAccountForm, TypedShape<CarrierAccountForm>>({
            accountName: Yup.string().required(t("You must enter an account name")),
            warehouseId: Yup.string().required(t("You must select a warehouse")),
            services: Yup.array<CarrierServiceItemForm>(Yup.object({
                carrierServiceId: Yup.string().required(t("You must select a service")),
                countries: Yup.array<string[]>().min(1, t("You must select at least one country"))
            })).required(),
            apiParameters: Yup.object().required(),
        }),
        onSubmit: async (values) => {
            const carrierAccount = values as CarrierAccountForm;
            let success: boolean;

            if (props.carrierAccount?.carrierAccountId) {
                success = await dispatch(updateCarrierAccount({ 
                    carrierAccountId: props.carrierAccount.carrierAccountId, 
                    carrierAccount 
                }));
            }
            else {
                success = await dispatch(createCarrierAccount({ 
                    carrierId: props.carrier.carrierId, 
                    carrierAccount 
                }));
            }

            if (success) {
                props.onSuccess?.({ selectedWarehouseId: carrierAccount.warehouseId });
            }
        }
    });

    return <>
        <div className="d-flex align-items-center gap-2 mb-2">
            <img src={props.carrier.logoImage} alt={props.carrier.name} style={{ maxWidth: "100px", maxHeight: "3rem" }} />
            <h5 className="mb-0">
                {step === "connect" && t("Connect {{carrier}}", { carrier: props.carrier.name })}
                {step === "services" && `${props.carrier.name} - ${validation.values.accountName}`}
            </h5>
        </div>
        {step === "connect" && <p className="small">{t("paragraphs:ConnectCarrierAccountSubTitle", { carrier: props.carrier.name })}</p>}
        <Form className="needs-validation" action="#" onSubmit={validation.handleSubmit}>
            {step === "connect" && <>
                <Row>
                    <Col>
                        <WarningAlert kind="top-border">
                            {t("paragraphs:ConnectCarrierAccountWarning", { carrier: props.carrier.name })}
                        </WarningAlert>
                    </Col>
                </Row>
                <Row className="g-2 mb-2">
                    <Col xs={12}>
                        <RequiredLabel>{t("Select Warehouse")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="warehouseId">
                            <WarehouseSelect />
                        </ValidationWrapper>
                    </Col>
                    <Col xs={12}>
                        <RequiredLabel>{t("Account Name")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="accountName">
                            <TextInput />
                        </ValidationWrapper>
                    </Col>
                </Row>
                <div className="label-separator my-2 label-seperator-2">
                    <span className="label"><i className="ri-file-text-line me-1"></i> {t("API Parameters")}</span>
                </div>
                {props.carrier.carrierId === Carriers.FedEx && <>
                    <ValidationWrapper validation={validation} field="apiParameters">
                        <FedexApiForm />
                    </ValidationWrapper>
                </>}
                {props.carrier.carrierId === Carriers.UPS && <>
                    <ValidationWrapper validation={validation} field="apiParameters">
                        <UpsApiForm />
                    </ValidationWrapper>
                </>}
                {props.carrier.carrierId === Carriers.USPS && <>
                    <ValidationWrapper validation={validation} field="apiParameters">
                        <UspsApiForm />
                    </ValidationWrapper>
                </>}
                
                {props.carrier.carrierId === Carriers.DHL && <>
                    <ValidationWrapper validation={validation} field="apiParameters">
                        <DhlApiForm />
                    </ValidationWrapper>
                </>}
                {props.carrier.carrierId === Carriers.APC && <>
                    <ValidationWrapper validation={validation} field="apiParameters">
                        <ApcApiForm />
                    </ValidationWrapper>
                </>}
                {props.carrier.carrierId === Carriers.Purolator && <>
                    <ValidationWrapper validation={validation} field="apiParameters">
                        <PurolatorApiForm />
                    </ValidationWrapper>
                </>}
                {props.carrier.carrierId === Carriers.Amazon && <>
                    <div className="text-center text-muted">{t("No Options")}</div>
                </>}
                <Row>
                    <Col>
                        <div className="hstack gap-2 mt-2 justify-content-end">
                            {props.onCancel && <Button type="button" color="light" onClick={props.onCancel}>
                                {t("Back")}
                            </Button>}
                            <BusyOverlay size="sm" busy={loading.save}>
                                <ValidatorButton type="button" color="primary" validation={validation} fields={["accountName", "warehouseId", "apiParameters"]} onClick={() => setStep("services")}>
                                    {t("Connect")}
                                </ValidatorButton>
                            </BusyOverlay>
                        </div>
                    </Col>
                </Row>
            </>}
            {step === "services" && <>
                <Row>
                    <Col className="mb-2">
                        <h4>{t("Select Carrier Services")}</h4>
                        <p>{t("paragraphs:SelectCarrierServicesSubTitle")}</p>
                    </Col>
                </Row>
                <Row className="g-1">
                    {props.carrier.services.length === 0 ? <>
                        <Col className="text-muted text-center">
                            {t("No services available")}
                        </Col>
                    </>: props.carrier.services.map((service, i) => {
                        const serviceIndex = validation.values.services!.findIndex(x => x.carrierServiceId === service.carrierServiceId);

                        return <Col xs={12} key={i}>
                            <div className="vstack">
                                <div>
                                    <Checkbox value={serviceIndex !== -1} onChange={val => {
                                        if (serviceIndex === -1) {
                                            validation.setFieldValue("services", [...validation.values.services!, { 
                                                carrierServiceId: service.carrierServiceId, 
                                                countries: [] 
                                            }]);
                                        } else {
                                            validation.setFieldValue("services", validation.values.services!.toSpliced(serviceIndex));
                                        }
                                    }}>{service.name}</Checkbox>
                                </div>
                                {serviceIndex !== -1 && <div className="ms-3 mb-2">
                                    <CountrySelect isMulti value={validation.values.services![serviceIndex].countries} onChange={val => {
                                        validation.setFieldValue(`services.${serviceIndex}.countries`, val);
                                    }} />
                                </div>}
                            </div>
                        </Col>;
                    })}
                </Row>
                <Row>
                    <Col>
                        <div className="hstack gap-2 mt-2 justify-content-end">
                            <Button type="button" color="light" onClick={() => setStep("connect")}>
                                {t("Back")}
                            </Button>
                            <BusyOverlay size="sm" busy={loading.save}>
                                <ValidatorButton type="submit" color="primary" validation={validation} >
                                    {t("Save")}
                                </ValidatorButton>
                            </BusyOverlay>
                        </div>
                    </Col>
                </Row>
            </>}
        </Form>
    </>;
}

export default EditCarrierAccountForm;