import { useQuery } from "@tanstack/react-query";
import { getInvoiceList } from "api/invoice";
import type { PaymentMethod } from "api/types/contracts/payment";
import type { WarehouseContract } from "api/types/contracts/warehouses";
import { getWallet } from "api/wallet";
import { WarningAlert } from "Components/Alerts";
import BusyOverlay from "Components/Common/BusyOverlay";
import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import CurrencyCountUp from "Components/Displays/CurrencyCountUp";
import WarehouseSelect from "Components/EntitySelects/WarehouseSelect";
import PaymentMethodRadio from "Components/EnumSelects/PaymentMethodRadio";
import { useProfile } from "Components/Hooks/ProfileHooks";
import _ from "lodash";
import { useState } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { Button, Card, CardBody, CardHeader, Col, Container, Label, Row, UncontrolledTooltip } from "reactstrap";
import AddCreditForm from "./_AddCreditForm";
import TransactionHistory from "./_TransactionHistory";

type WalletPageProps = {
    
};

const WalletPage = (props: WalletPageProps) => {
    const { t } = useTranslation();
    const { customer, userProfile } = useProfile();
    const [warehouse, setWarehouse] = useState<WarehouseContract | undefined>(userProfile!.user.warehouse);
    const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>("stripe");

    const { data: wallet, isFetching: walletLoading } = useQuery({
        queryKey: ["my-wallet", warehouse?.warehouseId],
        queryFn: () => getWallet({ warehouseId: warehouse!.warehouseId }),
        enabled: !!warehouse
    });

    const { data: requiredCredit, isFetching: requiredCreditLoading } = useQuery({
        queryKey: ["required-credit", warehouse?.warehouseId],
        queryFn: () => getInvoiceList({ warehouseId: warehouse!.warehouseId, status: "pending", page: 1, pageSize: 9999 }),
        select: data => _.sumBy(data.items, i => i.total),
        enabled: !!warehouse
    });

    return <>
        <div className="page-content">
            <Container fluid>
                <TitleBreadcrumb active={t("My Wallet")} parents={[t("Billing")]} />
                <Card>
                    <CardHeader className="border-0">
                        <Row className="align-items-center gy-3">
                            <div className="col-sm">
                                <h4 className="card-title mb-0">{t("My Wallet")}</h4>
                            </div>
                            <div className="col-sm-auto">
                                <div className="d-flex gap-3 flex-wrap align-items-center">
                                    <Label className="ms-auto">{t("Select Warehouse")}</Label>
                                    <div style={{ minWidth: "300px" }}>
                                        <WarehouseSelect value={warehouse?.warehouseId} showClear onSelect={setWarehouse} className="w-100" />
                                    </div>
                                </div>
                            </div>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <p>
                            <Trans i18nKey={"paragraphs:WalletSubTitle"} 
                                values={{ billingEmail: warehouse?.settings.invoice?.paymentSupportEmail }}
                                components={[<a href={`mailto:${warehouse?.settings.invoice?.paymentSupportEmail ?? " "}`}></a>]}
                            />
                        </p>
                        {!warehouse ? <Row>
                            <Col xs="auto" className="mx-auto fs-16 text-muted">
                                <p>{t("To view your balance please select warehouse first")}</p>
                            </Col>
                        </Row>
                        : <>
                            <Row>
                                <Col xs={6}>
                                    <Card className="shadow-lg">
                                        <CardBody>
                                            <div className="d-flex align-items-end justify-content-between">
                                                <div>
                                                    <div className="overflow-hidden">
                                                        <p className="text-uppercase fw-medium text-truncate mb-4 fs-18">{t("Available Credit")}</p>
                                                    </div>
                                                    <h4 className="fs-2 fw-semibold ff-secondary mb-4 text-success">
                                                        <BusyOverlay busy={walletLoading} inline spinnerColor="success" backgroundColor="white" opaque>
                                                            <CurrencyCountUp value={wallet?.balance ?? 0} currency={wallet?.unitOfCurrency} />
                                                        </BusyOverlay>
                                                    </h4>
                                                    <span className="text-muted">{t("paragraphs:AvailableCreditsDescription")}</span>
                                                </div>
                                                <div className="avatar-md flex-shrink-0">
                                                    <span className="avatar-title rounded fs-1 bg-success-subtle">
                                                        <i className="text-success bx bx-dollar-circle"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                                <Col xs={6}>
                                    <Card className="shadow-lg">
                                        <CardBody>
                                            <div className="d-flex align-items-end justify-content-between">
                                                <div>
                                                    <div className="overflow-hidden">
                                                        <p className="text-uppercase fw-medium text-truncate mb-4 fs-18 d-inline-flex align-items-center gap-2">
                                                            <span>{t("Estimated Required Credit")}</span>
                                                            <i className="ri-information-fill fs-18 text-info cursor-pointer" id="estimated-required-credit-info" />
                                                            <UncontrolledTooltip target="estimated-required-credit-info" trigger="legacy">
                                                                {t("paragraphs:EstimatedRequiredCreditsTooltip")}
                                                            </UncontrolledTooltip>
                                                        </p>
                                                    </div>
                                                    <h4 className="fs-2 fw-semibold ff-secondary mb-4 text-danger">
                                                        <BusyOverlay busy={requiredCreditLoading} inline spinnerColor="danger" backgroundColor="white" opaque>
                                                            <CurrencyCountUp value={requiredCredit ?? 0} currency={wallet?.unitOfCurrency} />
                                                        </BusyOverlay>
                                                    </h4>
                                                    <span className="text-muted">
                                                        <Trans i18nKey="paragraphs:EstimatedRequiredCreditsDescription" 
                                                            components={[<Link to="/invoices" className="fw-semibold" />]} />
                                                    </span>
                                                </div>
                                                <div className="avatar-md flex-shrink-0">
                                                    <span className="avatar-title rounded fs-1 bg-danger-subtle">
                                                        <i className="text-danger bx bx-credit-card"></i>
                                                    </span>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </>}
                    </CardBody>
                </Card>
                {warehouse && <Card>
                    <CardHeader className="border-0">
                        <h4 className="card-title">{t("Load Credit")}</h4>
                        <p className="mb-0 text-muted">{t("paragraphs:LoadCreditsSubTitle")}</p>
                    </CardHeader>
                    <CardBody>
                        {!customer?.invoiceAddress && <WarningAlert kind="top-border">
                            <div className="d-flex justify-content-between align-items-center">
                                <span>{t("paragraphs:NoBillingAddressWarning")}</span>
                                <Button type="button" color="warning">
                                    {t("Update Billing Address")}
                                </Button>
                            </div>
                        </WarningAlert>}
                        <div className="my-4">
                            <PaymentMethodRadio value={paymentMethod} onChange={val => setPaymentMethod(val ?? "stripe")} />
                        </div>
                        <AddCreditForm warehouse={warehouse} paymentMethod={paymentMethod} />
                    </CardBody>
                </Card>}
                {warehouse && <Card>
                    <CardHeader className="border-0">
                        <h4 className="card-title">{t("Transaction History")}</h4>
                        <p className="text-muted card-subtitle">{t("paragraphs:TransactionHistoryDescription")}</p>
                    </CardHeader>
                    <CardBody>
                        <TransactionHistory warehouse={warehouse} />
                    </CardBody>
                </Card>}
            </Container>
        </div>
    </>;
}

export default WalletPage;