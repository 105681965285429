import { useEffect } from "react";
import { Navigate } from "react-router-dom";
import { logoutUser } from "../../slices/thunks";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";

const Logout = () => {
    const dispatch = useAppDispatch();

    const isUserLogout = useAppSelector(
        (state) => state.Login.isUserLogout
    );

    useEffect(() => {
        dispatch(logoutUser());
    }, [dispatch]);

    if (isUserLogout) {
        return <Navigate to="/login" />;
    }

    return <></>;
};


export default Logout;