import { DangerAlert } from "Components/Alerts";
import BusyOverlay from "Components/Common/BusyOverlay";
import RequiredLabel from "Components/Common/RequiredLabel";
import NumberInput from "Components/Form/NumberInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { CreateShelfCommand } from "api/types/commands";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import { useTranslation } from "react-i18next";
import { Col, Form, Label, Row } from "reactstrap";
import { createShelf } from "slices/location/thunk";
import * as Yup from "yup";

type FormProps = {
    bayId: string,
    onSuccess: VoidFunction
}

type FormType = Omit<CreateShelfCommand, "bayId">;

const CreateShelfFormView = (props: FormProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { loading, error } = useAppSelector(
        (state) => ({
            error: state.Location.error,
            loading: state.Location.loading
        })
    );

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {} as FormType,
        validationSchema: Yup.object<FormType, TypedShape<FormType>>({
            shelfCount: Yup.number().required("You must enter shelf count").min(1, t("Shelf count must be greater than zero")),
            binLocationCount: Yup.number().notRequired(),
            shelfHeight: Yup.number().notRequired(),
            shelfWidth: Yup.number().notRequired(),
            shelfDepth: Yup.number().notRequired(),
            binLocationWidth: Yup.number().notRequired(),
            binLocationHeight: Yup.number().notRequired(),
            binLocationDepth: Yup.number().notRequired(),
        }),
        onSubmit: async (values) => {
            const success = await dispatch(createShelf({ 
                bayId: props.bayId,
                ...values
            }));
            
            if (success) {
                props.onSuccess();
            }
        },
    });

    return <>
        <Form className="needs-validation form-steps" action="#" onSubmit={validation.handleSubmit}>
            <div>
                <div className="mb-4">
                    <div>
                        <h5 className="mb-1">{t("Add Shelves")}</h5>
                        <p className="text-muted">
                            
                        </p>
                    </div>
                </div>
                <Row>
                    <Col lg={4} className="g-3">
                        <RequiredLabel htmlFor="shelfCount">{t("Shelf Count")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="shelfCount">
                            <NumberInput placeholder="Enter shelf count on each bay" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="binLocationCount">{t("Bin Location Count")}</Label>
                        <ValidationWrapper validation={validation} field="binLocationCount">
                            <NumberInput placeholder="Enter Bin Count" />
                        </ValidationWrapper>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="shelfWidth">{t("Shelf Width ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="shelfWidth">
                            <NumberInput placeholder="Enter shelf width" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="shelfHeight">{t("Shelf Height ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="shelfHeight">
                            <NumberInput placeholder="Enter shelf height" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="shelfDepth">{t("Shelf Depth ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="shelfDepth">
                            <NumberInput placeholder="Enter shelf width" />
                        </ValidationWrapper>
                    </Col>
                </Row>
                {validation.values.binLocationCount !== 0 && <Row>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="binLocationWidth">{t("Bin Location Width ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="binLocationWidth">
                            <NumberInput placeholder="Enter Bin width" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="binLocationHeight">{t("Bin Location Height ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="binLocationHeight">
                            <NumberInput placeholder="Enter Bin Location height" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="binLocationDepth">{t("Bin Location Depth ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="binLocationDepth">
                            <NumberInput placeholder="Enter Bin width" />
                        </ValidationWrapper>
                    </Col>
                </Row>}
            </div>
            <div className="mt-4">{error && <DangerAlert>{error.message}</DangerAlert>}</div>
            <div className="d-flex justify-content-end gap-3">
                <BusyOverlay busy={loading.save} size="sm">
                    <ValidatorButton type="submit" validation={validation} className="btn btn-primary btn-label right nexttab">
                        <i className="ri-save-3-line label-icon align-middle fs-16 ms-2"></i>
                        {t("Save")}
                    </ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
    </>;
};

export default CreateShelfFormView;
