import BusyOverlay from "Components/Common/BusyOverlay";
import { useTranslation } from "react-i18next";
import Grid from "./_Grid";
import { useAppSelector } from "Components/Hooks/StoreHooks";

const BaysView = () => {
    const { t } = useTranslation();

    const { selectedAisle, loading } = useAppSelector(
        (state) => ({
            loading: state.Location.loading,
            selectedAisle: state.Location.areas?.find(a => a.areaId === state.Location.selectedAreaId)?.aisles?.find(a => a.aisleId === state.Location.selectedAisleId)
        })
    );

    return <>
        <BusyOverlay busy={loading.data} blur>
            {selectedAisle && <Grid aisle={selectedAisle} />}
        </BusyOverlay>
    </>;
}

export default BaysView;