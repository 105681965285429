import SelectInput from "Components/Form/SelectInput";
import { type CompanyStatus, CompanyStatusList } from "api/types/contracts/companies";
import { EnumSelectInput, SelectOption } from "helpers/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const CompanyStatusSelect = (props: EnumSelectInput<CompanyStatus>) => {
    const { t } = useTranslation();
    const options = useMemo<SelectOption<CompanyStatus>[]>(() => CompanyStatusList.map((item) => ({ 
        value: item, 
        label: t(`enums:PrintOrientation.${item}`) 
    })), [t]);

    const { isMulti, value, onChange, ...rest } = props;

    return <>
        {isMulti && <SelectInput<CompanyStatus> isMulti value={value} onChange={onChange} {...rest} options={options} placeholder={t("Company Status")} />}
        {!isMulti && <SelectInput {...rest} value={value} onChange={onChange} options={options} placeholder={t("Company Status")} />}
    </>;
};

export default CompanyStatusSelect;