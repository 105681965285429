import { setList, loading, apiError, setSelectList } from "./reducer";
import { GetWarehouseQuery, ListWarehousesQuery, ListWarehousesQueryForSelect } from "api/types/queries";
import { CreateWarehouseCommand, DeleteWarehouseCommand, SetWarehouseStatusCommand, UpdateWarehouseCommand } from "api/types/commands";
import { ApiError } from "helpers/types";
import { deleteWarehouse, getWarehouse, getWarehouseList, getWarehouseListForSelect, patchWarehouseStatus, postWarehouse, putWarehouse } from "api/warehouse";
import { toast } from "react-toastify";
import i18n from 'i18n';
import { AppDispatch, RootState } from "slices";

export const loadWarehouse = (params: GetWarehouseQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        var result = await getWarehouse(params);
        
        return result;
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
};

export const loadList = (params: ListWarehousesQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["list", true]));

        var result = await getWarehouseList(params);
        
        dispatch(setList(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["list", false]));
    }
};

export const loadSelectList = (params: ListWarehousesQueryForSelect) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["selectList", true]));

        var result = await getWarehouseListForSelect(params);
        
        dispatch(setSelectList(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["selectList", false]));
    }
};

export const createWarehouse = (params: CreateWarehouseCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true]));

        await postWarehouse(params);
        toast.success(i18n.t("Warehouse created"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const updateWarehose = (params: UpdateWarehouseCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true]));

        await putWarehouse(params);
        toast.success(i18n.t("Warehouse updated"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const removeWarehouse = (params: DeleteWarehouseCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["delete", true]));

        await deleteWarehouse(params);

        toast.success(i18n.t("Warehouse deleted", { count: 1 }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const removeMultiWarehouses = (params: DeleteWarehouseCommand[]) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["delete", true]));

        for (const item of params) {
            await deleteWarehouse(item);
        }

        toast.success(i18n.t("Warehouse deleted", { count: params.length }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const setWarehouseStatus = (params: SetWarehouseStatusCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["status", true]));

        await patchWarehouseStatus(params);
        toast.success(i18n.t("Warehouse status updated"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["status", false]));
    }
}