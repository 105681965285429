import { Button, Modal, ModalBody } from "reactstrap";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { reloadLocations } from "slices/location/thunk";
import CreateBayFormView from "./_CreateBayFormView";
import classNames from "classnames";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";

type NewBayButtonProps = {
    aisleButton?: boolean,
    aisleId?: string,
}

const NewBayButton = ({ aisleButton, aisleId }: NewBayButtonProps) => {
    const [newModal, setNewModal] = useState(false);
    const { t } = useTranslation();
    //const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { warehouse, selectedAisle } = useAppSelector(
        (state) => {
            const selectedArea = state.Location.areas?.find(a => a.areaId === state.Location.selectedAreaId);
            const selectedAisle = selectedArea?.aisles?.find(a => a.aisleId === state.Location.selectedAisleId);

            return {
                ...state.Location,
                selectedArea,
                selectedAisle
            };
        }
    );

    const onBayAdded = () => { 
        setNewModal(false);
        dispatch(reloadLocations({ warehouseId: warehouse!.warehouseId }));
    };

    return warehouse ? <>
        <Button color="info" className={classNames({
            "btn-ghost-info btn-sm": aisleButton,
        })} onClick={() => setNewModal(true)}>
            {aisleButton ? t("Add Bay") : <><i className="ri-add-line align-bottom me-1"></i> {t("Add New Bay")}</>}
        </Button>
        <Modal isOpen={newModal} toggle={() => setNewModal(prev => !prev)} size="lg">
            <ModalBody>
                <ModalCloseButton onClick={() => setNewModal(false)} />
                <CreateBayFormView aisleId={aisleId || selectedAisle!.aisleId} onSuccess={onBayAdded} />
            </ModalBody>
        </Modal>
    </> : null;
};

export default NewBayButton;