import { setList, loading, apiError } from "./reducer";
import { ApiError } from "helpers/types";
import { deleteBin, getBin, getBinList, postBin, putBin } from "api/bin";
import { toast } from "react-toastify";
import i18n from 'i18n';
import { GetBinQuery, ListBinsQuery } from "api/types/queries";
import { CreateBinsCommand, DeleteBinCommand, UpdateBinCommand, type GenerateBinLabelCommand } from "api/types/commands";
import type { AppDispatch, RootState } from "slices";
import { postGenerateBinLabel } from "api/printing";
import { multiDownload, withCdn } from "helpers/urlHelper";

export const loadBin = (params: GetBinQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        var result = await getBin(params);

        return result;
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
};

export const loadList = (params: ListBinsQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["list", true]));

        var result = await getBinList(params);
        
        dispatch(setList(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["list", false]));
    }
};

export const createBin = (params: CreateBinsCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true]));

        await postBin(params);
        toast.success(i18n.t("Bin created"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const updateBin = (params: UpdateBinCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true]));

        await putBin(params);
        toast.success(i18n.t("Bin updated"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const removeBin = (params: DeleteBinCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["delete", true]));

        await deleteBin(params);

        toast.success(i18n.t("Bin deleted", { count: 1 }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const removeMultiBins = (params: DeleteBinCommand[]) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["delete", true]));

        for (const item of params) {
            await deleteBin(item);
        }

        toast.success(i18n.t("Bin deleted", { count: params.length }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const generateBinLabels = (params: GenerateBinLabelCommand[]) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["printLabel", true]));

        const results = await Promise.all(params.map(postGenerateBinLabel));

        const urls: string[] = [];
        for (const result of results) {
            if (!result.printQueued) {
                urls.push(withCdn(result.filePath));
            }
        }

        await multiDownload(urls);

        toast.success(i18n.t("Bin labels sent to printer", { count: params.length }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["printLabel", false]));
    }
}