import BusyOverlay from "Components/Common/BusyOverlay";
import CompanySelect from "Components/EntitySelects/CompanySelect";
import CountrySelect from "Components/EntitySelects/CountrySelect";
import StateSelect from "Components/EntitySelects/StateSelect";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import ValidatedInput from "Components/Form/Validated/ValidatedInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import { CountryContract, UnitOfCurrency, UnitOfLength, UnitOfWeight } from "api/types/contracts/common";
import { WarehouseContract, WarehouseForm, WarehouseStatus, WarehouseType, type WarehouseAddressForm } from "api/types/contracts/warehouses";
import classnames from "classnames";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { Form, Nav, NavItem, TabContent, TabPane, Row, Col, Label, NavLink, Input, Button } from "reactstrap";
import { createSelector } from "reselect";
import { createWarehouse, updateWarehose } from "slices/warehouse/thunk";
import * as Yup from "yup";
import { RootState } from "slices";
import { DangerAlert } from "Components/Alerts";
import UnitOfLengthSelect from "Components/EnumSelects/UnitOfLengthSelect";
import WarehouseTypeSelect from "Components/EnumSelects/WarehouseTypeSelect";
import UnitOfWeightSelect from "Components/EnumSelects/UnitOfWeightSelect";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import RequiredLabel from "Components/Common/RequiredLabel";
import Restricted from "Components/Common/Restricted";
import { useTenant } from "Components/Hooks/TenantHooks";
import { useProfile } from "Components/Hooks/ProfileHooks";
import UnitOfCurrencySelect from "Components/EnumSelects/UnitOfCurrencySelect";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";

type WizardFormProps = {
    warehouse?: WarehouseContract,
    onSuccess?: VoidFunction
}

const defaultForm: Partial<WarehouseForm> = {
    warehouseStatus: "active",
    warehouseType: "normal",
    settings: {
        unitOfLength: "inch",
        unitOfWeight: "lbs",
        unitOfCurrency: "usd",
        enableStripe: false,
        enablePayoneer: false,
        enableWise: false,
        enableWireTransfer: false,
        enableZelle: false,
        invoice: {
            paymentSupportEmail: "",
            invoicePrefix: "INV-"
        }
    }
};

const createWarehouseForm = (warehouse: WarehouseContract | undefined): WarehouseForm | undefined => {
    return warehouse ? {
        companyId: warehouse.company.companyId,
        name: warehouse.name,
        code: warehouse.code,
        warehouseType: warehouse.warehouseType,
        warehouseStatus: warehouse.warehouseStatus,
        address: {
            ...warehouse.address
        },
        settings: {
            ...warehouse.settings
        }
    } as WarehouseForm : undefined
};

const WizardForm = (props: WizardFormProps) => {
    const { company } = useTenant();
    const { canRead } = useProfile();
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { setPageTitle } = useMetadata();
    const warehouseForm = useMemo(() => createWarehouseForm(props.warehouse) || defaultForm, [props.warehouse]);
    const [activeTab, setActiveTab] = useState(1);
    const [passedSteps, setPassedSteps] = useState<number[]>([]);
    const [selectedCountry, setSelectedCountry] = useState<CountryContract>();
    const [selectedCompany, setSelectedCompany] = useState(company);

    const { error, loading } = useAppSelector(
        (state) => ({
            error: state.Warehouse.error,
            loading: {
                ...state.Warehouse.loading
            }
        })
    );

    if (!warehouseForm.companyId) {
        warehouseForm.companyId = company?.companyId;
    }
    
    setPageTitle(`${t(props.warehouse ? "Update Warehouse" : "Create Warehouse")} - ${t("Management")}`);
    
    const validation = useFormik({
        enableReinitialize: true,
        initialValues: warehouseForm,
        validationSchema: Yup.object<WarehouseForm, TypedShape<WarehouseForm>>({
            companyId: Yup.string().required(t("Please select a Company")),
            address: Yup.object({
                countryCode: Yup.string().required(t("Please select a Country")),
                state: Yup.string().required(t("Please enter a State")),
                stateCode: Yup.string().notRequired(),
                zipCode: Yup.string().required(t("Please enter a Zip Code")),
                address1: Yup.string().required(t("Please enter an Address")),
                address2: Yup.string().notRequired(),
                city: Yup.string().required(t("Please enter a City")),
                email: Yup.string().email(t("Please enter a Valid Email")).required(t("Please enter an Email")),
                phone: Yup.string().required(t("Please enter a Contact Number")).matches(/\+\d{1,5} ?\d{3} ?\d{3} ?\d{4}/, t("Please enter 10 digits phone number")),
                province: Yup.string().notRequired()
            }).required(),
            name: Yup.string().required(t("Please enter Warehouse Name")),
            code: Yup.string().notRequired(),
            warehouseType: Yup.string<WarehouseType>().required(),
            warehouseStatus: Yup.string<WarehouseStatus>().required(),
            settings: Yup.object({
                unitOfLength: Yup.string<UnitOfLength>().required(),
                unitOfWeight: Yup.string<UnitOfWeight>().required(),
                unitOfCurrency: Yup.string<UnitOfCurrency>().required(),
                enableStripe: Yup.boolean().required(),
                enablePayoneer: Yup.boolean().required(),
                enableWise: Yup.boolean().required(),
                enableWireTransfer: Yup.boolean().required(),
                enableZelle: Yup.boolean().required(),
                stripe: Yup.object({
                    publishableKey: Yup.string().required(t("Please enter Stripe Publishable Key")),
                    secretKey: Yup.string().required(t("Please enter Stripe Secret Key")),
                    testMode: Yup.boolean().required(t("Please select Test Mode")),
                    processingFee: Yup.number().required(t("Please enter Processing Fee"))
                }).when("enableStripe", ([enableStripe], schema) => enableStripe ? schema.required() : schema.notRequired()),
                wise: Yup.object({
                    accountName: Yup.string().required(),
                    email: Yup.string().required(),
                    processingFee: Yup.number().required(t("Please enter Processing Fee"))
                }).when("enableWise", ([enableWise], schema) => enableWise ? schema.required() : schema),
                payoneer: Yup.object({
                    accountName: Yup.string().required(),
                    email: Yup.string().required(),
                    processingFee: Yup.number().required(t("Please enter Processing Fee"))
                }).when("enablePayoneer", ([enablePayoneer], schema) => enablePayoneer ? schema.required() : schema),
                wireTransfer: Yup.object({
                    bankName: Yup.string().required(t("Please enter Bank Name")),
                    bankAddress: Yup.string().required(t("Please enter Bank Address")),
                    swiftCode: Yup.string().required(t("Please enter Swift Code")),
                    processingFee: Yup.number().required(t("Please enter Processing Fee")),
                    recipientName: Yup.string().required(t("Please enter Recipient Name")),
                    recipientNumber: Yup.string().required(t("Please enter Recipient Number")),
                    accountNumber: Yup.string().required(t("Please enter Account Number")),
                    recipientAddress: Yup.string().required(t("Please enter Recipient Address"))
                }).when("enableWireTransfer", ([enableWireTransfer], schema) => enableWireTransfer ? schema.required() : schema),
                zelle: Yup.object({
                    emailOrPhoneNumber: Yup.string().required("Please enter Email/Phone Number"),
                    processingFee: Yup.number().required(t("Please enter Processing Fee"))
                }).when("enableZelle", ([enableZelle], schema) => enableZelle ? schema.required() : schema),
                invoice: Yup.object({
                    paymentSupportEmail: Yup.string().email().required(),
                    invoicePrefix: Yup.string().required(),
                }).required()
            }).required()
        }),
        onSubmit: async (values) => {
            const warehouse = values as WarehouseForm;
            let success: boolean;

            if (props.warehouse?.warehouseId) {
                success = await dispatch(updateWarehose({ warehouseId: props.warehouse.warehouseId, warehouse }));
            }
            else {
                success = await dispatch(createWarehouse({ warehouse }));
            }

            if (success) {
                props.onSuccess?.();
            }
        },
    });

    useEffect(() => {
        if (selectedCountry) {
            validation.setFieldValue("unitOfCurrency", selectedCountry.currency.toLowerCase());
        }
    }, [selectedCountry])
    

    const useCompanyAddress = () => {
        if (selectedCompany) {
            validation.setFieldValue("countryCode", selectedCompany.contactAddress.countryCode);
            validation.setFieldValue("state", selectedCompany.contactAddress.state);
            validation.setFieldValue("stateCode", selectedCompany.contactAddress.stateCode);
            validation.setFieldValue("zipCode", selectedCompany.contactAddress.zipCode);
            validation.setFieldValue("address1", selectedCompany.contactAddress.address1);
            validation.setFieldValue("address2", selectedCompany.contactAddress.address2);
            validation.setFieldValue("city", selectedCompany.contactAddress.city);
            validation.setFieldValue("phone", selectedCompany.contactAddress.phone);
            validation.setFieldValue("email", selectedCompany.contactAddress.email);
        }
    };
    
    function toggleTab(tab: number) {
        if (activeTab !== tab) {
            var modifiedSteps = [...passedSteps, tab];

            setActiveTab(tab);
            setPassedSteps(modifiedSteps);
        }
    }

    return <>
        <Form className="needs-validation form-steps" action="#" onSubmit={validation.handleSubmit}>
            <div className="step-arrow-nav mb-4">
                <Nav className="nav-pills custom-nav nav-justified" role="tablist">
                    <NavItem>
                        <NavLink href="#" className={classnames({
                            active: activeTab === 1,
                            done: passedSteps.includes(1),
                        })} onClick={() => toggleTab(1)}>{t("General Information")}</NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink href="#" className={classnames({
                            active: activeTab === 2,
                            done: passedSteps.includes(2),
                        })} onClick={() => toggleTab(2)}>{t("Settings")}</NavLink>
                    </NavItem>
                </Nav>
            </div>
            <TabContent activeTab={activeTab}>
                <TabPane tabId={1}>
                    <div>
                        <div className="mb-4">
                            <div>
                                <h5 className="mb-1">{t("General Information")}</h5>
                                <p className="text-muted">
                                    {t("In this part warehouse general information exists")}
                                </p>
                            </div>
                        </div>
                        <Row>
                            <Restricted require="management.company" read>
                                <Col lg={6} className="g-3">
                                    <RequiredLabel htmlFor="companyId">{t("Company")}</RequiredLabel>
                                    {props.warehouse?.company ? 
                                        <Input type="text" readOnly className="bg-light" defaultValue={props.warehouse.company.name} /> 
                                    : 
                                        <ValidationWrapper validation={validation} field="companyId">
                                            <CompanySelect onSelect={setSelectedCompany} />
                                        </ValidationWrapper>
                                    }
                                </Col>
                            </Restricted>
                            <Col lg={canRead("management.company") ? 6 : 12} className="g-3">
                                <RequiredLabel htmlFor="name">{t("Name")}</RequiredLabel>
                                <ValidatedInput validation={validation} field="name" placeholder="Enter Name" />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} className="g-3">
                                <RequiredLabel htmlFor="type">{t("Type")}</RequiredLabel>
                                <ValidationWrapper validation={validation} field="warehouseType">
                                    <WarehouseTypeSelect />
                                </ValidationWrapper>
                            </Col>
                            <Col lg={6} className="g-3">
                                <Label htmlFor="code">{t("Warehouse Code")}</Label>
                                <ValidatedInput validation={validation} field="code" placeholder="Enter Code" />
                            </Col>
                        </Row>
                        <div className="hstack gap-3 align-items-center mt-3">
                            <h5 className="mb-0">{t("Address")}</h5>
                            <Button onClick={useCompanyAddress} size="sm" color="ghost-info" style={{ whiteSpace: "nowrap" }}>
                                {t("Use Company Address")}
                            </Button>
                            <hr className="w-100" />
                        </div>
                        <Row>
                            <Col lg={6} className="g-3">
                                <RequiredLabel htmlFor="address.countryCode">{t("Country")}</RequiredLabel>
                                <ValidationWrapper validation={validation} field="address.countryCode">
                                    <CountrySelect onSelect={setSelectedCountry} />
                                </ValidationWrapper>
                            </Col>
                            <Col lg={6} className="g-3">
                                <RequiredLabel htmlFor="address.state">{t("State")}</RequiredLabel>
                                <ValidationWrapper validation={validation} field="address.state">
                                    <StateSelect countryCode={selectedCountry?.code || validation.values.address?.countryCode} onSelect={val => {
                                        validation.setFieldValue("stateCode", val?.code);
                                    }} />
                                </ValidationWrapper>
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} className="g-3">
                                <RequiredLabel htmlFor="address.city">{t("City")}</RequiredLabel>
                                <ValidatedInput validation={validation} field="address.city" placeholder="Enter City" />
                            </Col>
                            <Col lg={6} className="g-3">
                                <RequiredLabel htmlFor="address.zipCode">{t("Zip Code")}</RequiredLabel>
                                <ValidatedInput validation={validation} field="address.zipCode" placeholder="Enter Zip code" />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} className="g-3">
                                <RequiredLabel htmlFor="address.address1">{t("Address 1")}</RequiredLabel>
                                <ValidatedInput validation={validation} field="address.address1" placeholder="Enter Address" />
                            </Col>
                            <Col lg={6} className="g-3">
                                <Label htmlFor="address.address2">{t("Address 2")}</Label>
                                <ValidatedInput validation={validation} field="address.address2" placeholder="Enter Address" />
                            </Col>
                        </Row>
                        <Row>
                            <Col lg={6} className="g-3">
                                <RequiredLabel htmlFor="address.phone">{t("Phone Number")}</RequiredLabel>
                                <ValidatedInput validation={validation} field="address.phone" placeholder="Enter Phone number" 
                                    //mask={`${selectedCountry?.phoneCode || ""} 000 000 0000`} 
                                />
                            </Col>
                            <Col lg={6} className="g-3">
                            <RequiredLabel htmlFor="address.email">{t("Email")}</RequiredLabel>
                                <ValidatedInput validation={validation} field="address.email" placeholder="Enter Email" />
                            </Col>
                        </Row>
                    </div>
                    <div className="d-flex justify-content-end gap-3 mt-4">
                        <ValidatorButton type="button" onClick={() => toggleTab(2)} 
                            validation={validation} fields={["companyId", "name", "address"]}
                            className="btn btn-success btn-label right ms-auto nexttab nexttab">
                            <i className="ri-arrow-right-line label-icon align-middle fs-16 ms-2"></i>
                            {t("Settings")}
                        </ValidatorButton>
                    </div>
                </TabPane>
                <TabPane tabId={2}>
                    <div>
                        <div className="mb-4">
                            <div>
                                <h5 className="mb-1">{t("Settings")}</h5>
                                <p className="text-muted">
                                    {t("In this part warehouse settings exists")}
                                </p>
                            </div>
                        </div>
                        <div className="mb-3">
                            <Row>
                                <Col lg={6} className="g-3">
                                    <Label htmlFor="settings.unitOfLength">{t("Unit of Length")}</Label>
                                    <ValidationWrapper validation={validation} field="settings.unitOfLength">
                                        <UnitOfLengthSelect />
                                    </ValidationWrapper>
                                </Col>
                                <Col lg={6} className="g-3">
                                    <Label htmlFor="settings.unitOfWeight">{t("Unit of Weight")}</Label>
                                    <ValidationWrapper validation={validation} field="settings.unitOfWeight">
                                        <UnitOfWeightSelect />
                                    </ValidationWrapper>
                                </Col>
                                <Col lg={6} className="g-3">
                                    <Label htmlFor="settings.unitOfCurrency">{t("Currency")}</Label>
                                    <ValidationWrapper validation={validation} field="settings.unitOfCurrency">
                                        <UnitOfCurrencySelect />
                                    </ValidationWrapper>
                                </Col>
                            </Row>
                        </div>
                    </div>
                    <div className="mt-4">{error && <DangerAlert>{error.message}</DangerAlert>}</div>
                    <div className="d-flex justify-content-end gap-3">
                        <Button type="button" color="light" className="btn-label previestab" onClick={() => toggleTab(1)}>
                            <i className="ri-arrow-left-line label-icon align-middle fs-16"></i>
                            {t("Back")}
                        </Button>
                        <BusyOverlay busy={loading.save} size="sm">
                            <ValidatorButton type="submit" validation={validation} className="btn btn-primary btn-label nexttab">
                                <i className="ri-save-3-line label-icon align-middle fs-16"></i>
                                {t("Save")}
                            </ValidatorButton>
                        </BusyOverlay>
                    </div>
                </TabPane>
                {/* <TabPane tabId={4}>
                    <div className="text-center">
                        <div className="avatar-md mt-5 mb-4 mx-auto">
                            <div className="avatar-title bg-light text-success display-4 rounded-circle">
                                <i className="ri-checkbox-circle-fill"></i>
                            </div>
                        </div>
                        <h5>{t("Well done")}!</h5>
                        <p className="text-muted">{t("You have successfully created the company")}</p>
                        <Button type="button" color="warning" onClick={() => props.onSuccess?.()}>{t("Close wizard")}</Button>
                    </div>
                </TabPane> */}
            </TabContent>
        </Form>
    </>;
}

export default WizardForm;