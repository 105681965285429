import BusyOverlay from "Components/Common/BusyOverlay";
import RequiredLabel from "Components/Common/RequiredLabel";
import Restricted from "Components/Common/Restricted";
import ProductCategorySelect from "Components/EntitySelects/ProductCategorySelect";
import Checkbox from "Components/Form/Checkbox";
import ValidatedInput from "Components/Form/Validated/ValidatedInput";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { ProductCategoryContract, ProductCategoryForm } from "api/types/contracts/products";
import { useFormik } from "formik";
import { TypedShape } from "helpers/types";
import { useTranslation } from "react-i18next";
import { Col, Container, Form, Label, Row } from "reactstrap";
import { createProductCategory, updateProductCategory } from "slices/product/category/thunk";
import * as Yup from "yup";

type FormProps = {
    productCategory?: ProductCategoryContract,
    onSuccess: (productCategoryId: string) => void
}

const defaultForm: Partial<ProductCategoryForm> = {
    isStandart: false
};

const createProductCategoryForm = (productCategory: ProductCategoryContract): ProductCategoryForm => {
    return {
        isStandart: productCategory.isStandart,
        name: productCategory.name,
        parentCategoryId: productCategory.parent?.categoryId,
    };
};

const CategoryForm = (props: FormProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const product = props.productCategory ? createProductCategoryForm(props.productCategory) : defaultForm;

    const { loading, error } = useAppSelector(
        (state) => ({
            error: state.ProductCategory.error,
            loading: state.ProductCategory.loading
        })
    );

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: product,
        validationSchema: Yup.object<ProductCategoryForm, TypedShape<ProductCategoryForm>>({
            name: Yup.string().required(t("Name is required")),
            isStandart: Yup.boolean().required(),
            parentCategoryId: Yup.string().nullable()
        }),
        onSubmit: async (values) => {
            const productCategory = values as ProductCategoryForm;
            const productCategoryId = props.productCategory?.categoryId;
            
            if (productCategoryId) {
                const success = await dispatch(updateProductCategory({
                    productCategoryId: productCategoryId,
                    productCategory
                }));

                if (success) {
                    props.onSuccess(productCategoryId)
                }
            }
            else {
                const created = await dispatch(createProductCategory({
                    productCategory
                }));

                if (created) {
                    props.onSuccess(created.categoryId);
                }
            }
        },
    });

    return <>
        <Form className="needs-validation form-steps" action="#" onSubmit={validation.handleSubmit}>
            <Container>
                <Row>
                    <Col className="g-3">
                        <RequiredLabel>{t("Category Name")}</RequiredLabel>
                        <ValidatedInput validation={validation} field="name" />
                    </Col>
                    <Restricted require="management.system" create={!props.productCategory} write={!!props.productCategory}>
                        <Col xs="auto" className="g-3 d-flex align-items-end pb-2">
                            <ValidationWrapper validation={validation} field="isStandart">
                                <Checkbox>{t("Is Standart")}</Checkbox>
                            </ValidationWrapper>
                        </Col>
                    </Restricted>
                    <Col xs={12} className="g-3">
                        <Label>{t("Parent Category")}</Label>
                        <ValidationWrapper validation={validation} field="parentCategoryId">
                            <ProductCategorySelect parentsOnly showClear />
                        </ValidationWrapper>
                    </Col>
                </Row>
                <Row>
                    <Col xs="auto" className="g-3 ms-auto">
                        <BusyOverlay busy={loading.save} size="sm">
                            <ValidatorButton validation={validation} color="primary" type="submit">
                                {t("Save")}
                            </ValidatorButton>
                        </BusyOverlay>
                    </Col>
                </Row>
            </Container>
        </Form>
    </>;
}

export default CategoryForm;