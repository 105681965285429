import { useEffect, useState } from 'react';
import { Card, Col, Container, Label, Row, Button, Form } from 'reactstrap';
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Yup from "yup";
import { useFormik } from "formik";
import { loginUser } from "../../slices/thunks";
import logoLight from "../../assets/images/tyloc/logo.png";
import { useRouter } from 'Components/Hooks/RouterHooks';
import { useMetadata } from 'Components/Hooks/MetadataHooks';
import ValidatedInput from 'Components/Form/Validated/ValidatedInput';
import ValidatedCheckbox from 'Components/Form/Validated/ValidatedCheckbox';
import ValidatorButton from 'Components/Form/Validated/ValidatorButton';
import BusyOverlay from 'Components/Common/BusyOverlay';
import { useTenant } from 'Components/Hooks/TenantHooks';
import { withCdn } from 'helpers/urlHelper';
import { DangerAlert } from 'Components/Alerts';
import config from 'config';
import Copyright from 'Components/Copyright';
import { useAppDispatch, useAppSelector } from 'Components/Hooks/StoreHooks';

const Login = () => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { navigate } = useRouter();
    const { setPageTitle } = useMetadata();
    const { tenant } = useTenant();

    const { user, error, loading } = useAppSelector(
        (state) => ({
            user: state.Login.user?.user,
            error: state.Login.error,
            loading: state.Login.loading
        })
    );

    const [userLogin, setUserLogin] = useState({ email: "", password: "" });
    const [passwordShow, setPasswordShow] = useState(false);

    useEffect(() => {
        if (user) {
            setUserLogin({
                email: user.email,
                password: ""
            });
        }
    }, [user]);

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: {
            email: userLogin.email || config.env.isDevelopment ? 'admin@tyloc.com' : '',
            password: userLogin.password || config.env.isDevelopment ? 'admin' : '',
            remember: false
        },
        validationSchema: Yup.object({
            email: Yup.string().required(t("Please Enter Your Email")),
            password: Yup.string().required(t("Please Enter Your Password")),
            remember: Yup.bool()
        }),
        onSubmit: async (values) => {
            const success = await dispatch(loginUser(values));
            
            if (success) {
                navigate("/dashboard");
            }
        }
    });

    setPageTitle(t("Sign In"));

    return <>
        <div className="auth-page-wrapper auth-bg-cover py-5 d-flex justify-content-center align-items-center min-vh-100">
            <div className="bg-overlay bg-tenant-primary"></div>
            <div className="auth-page-content overflow-hidden">
                <Container>
                    <Row>
                        <Col lg={12}>
                            <Card className="overflow-hidden m-0">
                                <Row>
                                    <Col lg={5} className='p-4'>
                                        <div className="text-center mt-2">
                                            <h2 className="mt-3 fw-medium text-tenant-primary">
                                                {tenant?.name}
                                            </h2>
                                            <h5 className="text-primary">Welcome Back !</h5>
                                            <p className="text-muted">Sign in to continue to {tenant?.name}.</p>
                                        </div>
                                        {error && <DangerAlert>{t(error.message)}</DangerAlert>}
                                        <div className="p-2 mt-4">
                                            <Form onSubmit={validation.handleSubmit} action="#">
                                                <div className="mb-3">
                                                    <Label htmlFor="email" className="form-label">{ t("Email") }</Label>
                                                    <ValidatedInput validation={validation} field="email" type="text"
                                                        placeholder={ t("Enter email") } />
                                                </div>

                                                <div className="mb-3">
                                                    <div className="float-end">
                                                        <Link to="/forgot-password" className="text-muted">{ t("Forgot Password?") }</Link>
                                                    </div>
                                                    <Label className="form-label" htmlFor="password-input">{ t("Password") }</Label>
                                                    <div className="position-relative auth-pass-inputgroup mb-3">
                                                        <ValidatedInput validation={validation} field="password" type={passwordShow ? "text" : "password"}
                                                            className='pe-5' placeholder={ t("Enter password") } />
                                                        <button className="btn btn-link position-absolute end-0 top-0 text-decoration-none text-muted" type="button" id="password-addon" onClick={() => setPasswordShow(!passwordShow)}><i className="ri-eye-fill align-middle"></i></button>
                                                    </div>
                                                </div>

                                                <ValidatedCheckbox validation={validation} field="remember" label={ t("Remember me") } />

                                                <div className="mt-4">
                                                    <BusyOverlay busy={loading} size="sm">
                                                        <ValidatorButton color='success' className='w-100' type='submit' validation={validation}>{t("Sign In")}</ValidatorButton>
                                                    </BusyOverlay>
                                                </div>

                                                <div className="mt-4 text-center">
                                                    <div className="signin-other-title">
                                                        <h5 className="fs-13 mb-4 title">{t("Or")}</h5>
                                                    </div>
                                                    <div>
                                                        <Link to="/register">
                                                            <Button color="info" className="btn btn-success w-100" type="button">
                                                                {t("Create New Account")}
                                                            </Button>
                                                        </Link>
                                                    </div>
                                                </div>
                                            </Form>
                                        </div>
                                    </Col>
                                    <Col lg={7}>
                                        <div className="p-lg-5 p-4 auth-one-bg tenant-cover-background h-100">
                                            <div className="bg-overlay bg-tenant-secondary"></div>
                                            <div className="position-relative h-100 d-flex flex-column text-center">
                                                <div className="text-center mt-sm-3 mb-4 text-white-50">
                                                    <div>
                                                        <Link to="/" className="d-inline-block auth-logo">
                                                            <img src={tenant?.logo ? withCdn(tenant.logo) : logoLight} alt="" className='mw-100' style={{ maxHeight: "210px" }} />
                                                        </Link>
                                                    </div>
                                                </div>
                                                <p className="">
                                                    {tenant?.coverPageText}
                                                </p>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <footer className="footer">
                <div className="text-center">
                    <Copyright />
                </div>
            </footer>
        </div>
    </>;
};

export default Login;