import { Button, Modal, ModalBody } from "reactstrap";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { reloadLocations } from "slices/location/thunk";
import CreateBinLocationFormView from "./_CreateBinLocationFormView";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";

type NewBinLocationButtonProps = {
    target: "area" | "shelf",
    shelfId?: string,
    iconButton?: boolean
}

const NewBinLocationButton = ({ target, iconButton, shelfId }: NewBinLocationButtonProps) => {
    const [newModal, setNewModal] = useState(false);
    const { t } = useTranslation();
    //const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const { warehouse, selectedArea, selectedShelf } = useAppSelector(
        (state) => {
            const location = state.Location;
            const selectedArea = location.areas?.find(a => a.areaId === location.selectedAreaId);
            const selectedAisle = selectedArea?.aisles?.find(a => a.aisleId === location.selectedAisleId);
            const selectedBay = selectedAisle?.bays?.find(b => b.bayId === location.selectedBayId);
            const selectedShelf = selectedBay?.shelves?.find(s => s.shelfId === location.selectedShelfId);

            return {
                ...location,
                selectedArea,
                selectedAisle,
                selectedBay,
                selectedShelf
            };
        }
    );

    const onBinAdded = () => { 
        setNewModal(false);
        dispatch(reloadLocations({ warehouseId: warehouse!.warehouseId }));
    };

    return warehouse ? <>
        {iconButton ? 
            <Button className="btn-ghost-info btn-icon p-4 me-1" type="button" onClick={() => setNewModal(true)} title={t("Add New Bin Location")}>
                <i className="ri-3x ri-add-circle-line"></i>
            </Button>
        : 
            <Button color="info" onClick={() => setNewModal(true)}>
                <i className="ri-add-line align-bottom me-1"></i> {t("Add New Bin Location")}
            </Button>
        }
        <Modal isOpen={newModal} toggle={() => setNewModal(prev => !prev)} size="lg">
            <ModalBody>
                <ModalCloseButton onClick={() => setNewModal(false)} />
                <CreateBinLocationFormView areaId={target === "area" ? selectedArea!.areaId : undefined} shelfId={target === "shelf" ? shelfId || selectedShelf?.shelfId : undefined} onSuccess={onBinAdded} />
            </ModalBody>
        </Modal>
    </> : null;
};

export default NewBinLocationButton;