import { setList, loading, apiError } from "./reducer";
import { ListProductCategoriesQuery } from "api/types/queries";
import { CreateProductCategoryCommand, DeleteProductCategoryCommand, UpdateProductCategoryCommand } from "api/types/commands";
import { ApiError } from "helpers/types";
import { toast } from "react-toastify";
import i18n from 'i18n';
import { deleteProductCategory, getProductCategoryList, postProductCategory, putProductCategory } from "api/productCategory";
import type { AppDispatch, RootState } from "slices";

export const loadList = (params: ListProductCategoriesQuery) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["list", true]));

        var result = await getProductCategoryList(params);
        
        dispatch(setList(result));
    } catch (error) {
        dispatch(apiError(error as ApiError));
    }
    finally {
        dispatch(loading(["list", false]));
    }
};

export const createProductCategory = (params: CreateProductCategoryCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true]));

        const category = await postProductCategory(params);
        toast.success(i18n.t("Product category created"));

        return category;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return undefined;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const updateProductCategory = (params: UpdateProductCategoryCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["save", true]));

        await putProductCategory(params);
        toast.success(i18n.t("Product category updated"));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["save", false]));
    }
}

export const removeProductCategory = (params: DeleteProductCategoryCommand) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["delete", true]));

        await deleteProductCategory(params);

        toast.success(i18n.t("Product category deleted", { count: 1 }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}

export const removeMultiProductCategories = (params: DeleteProductCategoryCommand[]) => async (dispatch: AppDispatch, getState: () => RootState) => {
    try {
        dispatch(loading(["delete", true]));

        for (const item of params) {
            await deleteProductCategory(item);
        }

        toast.success(i18n.t("Product categories deleted", { count: params.length }));

        return true;
    } catch (error) {
        dispatch(apiError(error as ApiError));
        return false;
    }
    finally {
        dispatch(loading(["delete", false]));
    }
}