import SelectInput from "Components/Form/SelectInput"
import { createAppSelector, useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import type { PrintSizeContract, PrintTemplateType } from "api/types/contracts/printing";
import type { ListPrintSizesQuery } from "api/types/queries";
import { EntitySelectInputProps } from "helpers/types";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { loadPrintSizes } from "slices/printers/thunk";
import { useImmer } from "use-immer";

type PrintSizeSelectProps = EntitySelectInputProps<PrintSizeContract, "printSizeId"> & {
    templateType: PrintTemplateType,
}

const PrintSizeSelect = ({ isMulti, value, onChange, onSelect, name, templateType, isValid, ...rest }: PrintSizeSelectProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const [query, updateQuery] = useImmer<ListPrintSizesQuery>({
        templateType
    });
    const { printSizes, loading } = useAppSelector(
        createAppSelector([state => state.Printers],
            (printers) => ({
                printSizes: printers.printSizes,
                loading: printers.loading.printSizeList
            })
        )
    );

    const printSizeList = useMemo(() => printSizes.map(c => ({ value: c.printSizeId, label: c.name})), [printSizes]);

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = printSizes.filter(c => val.includes(c.printSizeId));

        if (selected) {
            onSelect?.(selected);
            onChange?.(val);
            return;
        }

        onChange?.([]);
        onSelect?.([]);
    };

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            const selected = printSizes.find(c => c.printSizeId === val);

            if (selected) {
                onSelect?.(selected);
                onChange?.(selected.printSizeId);
                return;
            }
        }
        
        onChange?.(undefined);
        onSelect?.(undefined);
    };

    useEffect(() => {
        dispatch(loadPrintSizes(query));
    }, [query]);

    useEffect(() => {
        if (!value) {
            return;
        }

        if (printSizes.length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [printSizes]);
    
    const selectProps = {
        ...rest,
        placeholder: t("Print Size"),
        busy: loading,
        options: printSizeList,
        showClear: true,
        isValid: isValid
    }

    return <>
        {isMulti && <SelectInput<string> isMulti value={value} onChange={onChangedMulti} {...selectProps} />}
        {!isMulti && <SelectInput value={value} onChange={onChangedSingle} {...selectProps} />}
    </>;
}

export default PrintSizeSelect;