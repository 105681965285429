import { DangerAlert } from 'Components/Alerts';
import BusyOverlay from 'Components/Common/BusyOverlay';
import RequiredLabel from 'Components/Common/RequiredLabel';
import NumberInput from 'Components/Form/NumberInput';
import TextInput from 'Components/Form/TextInput';
import ValidationWrapper from 'Components/Form/Validated/ValidationWrapper';
import ValidatorButton from 'Components/Form/Validated/ValidatorButton';
import { useAppDispatch, useAppSelector } from 'Components/Hooks/StoreHooks';
import { BinLocationContract, BinLocationForm } from 'api/types/contracts/locations';
import { useFormik } from 'formik';
import { TypedShape } from 'helpers/types';
import { useTranslation } from 'react-i18next';
import { Col, Form, Label, Row } from 'reactstrap';
import { updateBinLocation } from 'slices/location/thunk';
import * as Yup from 'yup';

type FormProps = {
    binLocation: BinLocationContract,
    onSuccess: () => void
}

const createBinForm = (bin: BinLocationContract): BinLocationForm => {
    return {
        name: bin.name,
        ...bin.properties
    }
};

const UpdateBinLocationFormView = (props: FormProps) => {
    const aisle = createBinForm(props.binLocation);
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { loading, error } = useAppSelector(
        (state) => ({
            error: state.Location.error,
            loading: state.Location.loading
        })
    );

    const validation = useFormik({
        enableReinitialize: true,
        initialValues: aisle,
        validationSchema: Yup.object<BinLocationForm, TypedShape<BinLocationForm>>({
            name: Yup.string().required(t("Please enter bin location name")),
            height: Yup.number().notRequired(),
            width: Yup.number().notRequired(),
            depth: Yup.number().notRequired()
        }),
        onSubmit: async (values) => {
            const success = await dispatch(updateBinLocation({
                binLocationId: props.binLocation.binLocationId,
                binLocation: values
            }));
            
            if (success) {
                props.onSuccess();
            }
        },
    });
    
    return <>
        <Form className="needs-validation form-steps" action="#" onSubmit={validation.handleSubmit}>
            <div>
                <div className="mb-4">
                    <div>
                        <h5 className="mb-1">{t("Bin Location Information")}</h5>
                    </div>
                </div>
                <Row>
                    <Col lg={4} className="g-3">
                        <RequiredLabel htmlFor="name">{t("Name")}</RequiredLabel>
                        <ValidationWrapper validation={validation} field="name">
                            <TextInput placeholder="Enter Bin Location Name" />
                        </ValidationWrapper>
                    </Col>
                </Row>
                <Row>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="width">{t("Width ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="width">
                            <NumberInput placeholder="Enter Bin Location width" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="height">{t("Height ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="height">
                            <NumberInput placeholder="Enter Bin Location height" />
                        </ValidationWrapper>
                    </Col>
                    <Col lg={4} className="g-3">
                        <Label htmlFor="depth">{t("Depth ({{unit}})", { unit: "inch" })}</Label>
                        <ValidationWrapper validation={validation} field="depth">
                            <NumberInput placeholder="Enter Bin Location depth" />
                        </ValidationWrapper>
                    </Col>
                </Row>
            </div>
            <div className="mt-4">{error && <DangerAlert>{error.message}</DangerAlert>}</div>
            <div className="d-flex justify-content-end gap-3">
                <BusyOverlay busy={loading.save} size="sm">
                    <ValidatorButton type="submit" validation={validation} className="btn btn-primary btn-label right nexttab">
                        <i className="ri-save-3-line label-icon align-middle fs-16 ms-2"></i>
                        {t("Save")}
                    </ValidatorButton>
                </BusyOverlay>
            </div>
        </Form>
    </>;
}

export default UpdateBinLocationFormView;