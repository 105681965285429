import SelectInput from "Components/Form/SelectInput"
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { CarrierOverweightRuleContract } from "api/types/contracts/carrier";
import { EntitySelectInputProps } from "helpers/types";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { loadOverweightRules } from "slices/carrierRules/thunk";

type CarrierOverweightRuleSelectProps = EntitySelectInputProps<CarrierOverweightRuleContract, "carrierOverweightRuleId"> & {

};

const CarrierOverweightRuleSelect = ({ isMulti, value, onChange, onSelect, name, isValid, ...rest }: CarrierOverweightRuleSelectProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { overweightRules, loading } = useAppSelector(
        (state) => ({
            overweightRules: state.CarrierRules.overweight?.items ?? [],
            loading: state.CarrierRules.loading.importTaxPricing.list
        })
    );

    const rulesList = useMemo(() => overweightRules.map(c => ({ value: c.carrierOverweightRuleId, label: c.name})), [overweightRules]);

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = overweightRules.filter(c => val.includes(c.carrierOverweightRuleId));

        if (selected) {
            onSelect?.(selected);
            onChange?.(val);
            return;
        }

        onChange?.([]);
        onSelect?.([]);
    };

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            const selected = overweightRules.find(c => c.carrierOverweightRuleId === val);

            if (selected) {
                onSelect?.(selected);
                onChange?.(selected.carrierOverweightRuleId);
                return;
            }
        }

        onChange?.(undefined);
        onSelect?.(undefined);
    };

    useEffect(() => {
        dispatch(loadOverweightRules({
            page: 1,
            pageSize: 9999
        }));
    }, []);

    useEffect(() => {
        if (!value) {
            return;
        }

        if (overweightRules.length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [overweightRules]);
    
    const selectProps = {
        ...rest,
        placeholder: t("Carrier Import Tax Pricing Rule"),
        busy: loading,
        options: rulesList,
        showClear: true,
        isValid: isValid
    }

    return <>
        {isMulti && <SelectInput<string> isMulti value={value} onChange={onChangedMulti} {...selectProps} />}
        {!isMulti && <SelectInput value={value} onChange={onChangedSingle} {...selectProps} />}
    </>;
}

export default CarrierOverweightRuleSelect;
