import { APIClient, type PagedList } from "helpers/api_helper";
import { GetInvoiceQuery, type ListInvoicesQuery } from "./types/queries";
import { InvoiceContract } from "./types/contracts/payment";
import type { CancelInvoiceCommand, PayInvoiceCommand, RefundInvoiceCommand } from "./types/commands";

const api = new APIClient();

export const getInvoice = async (data: GetInvoiceQuery) => {
    const result = await api.get<InvoiceContract>(`/api/invoice/${data.invoiceId}/`);
    return result;
}

export const postCancelInvoice = async (data: CancelInvoiceCommand) => {
    const result = await api.post(`/api/invoice/${data.invoiceId}/cancel`, {});
    return result;
}

export const postPayInvoice = async (data: PayInvoiceCommand) => {
    const result = await api.post(`/api/invoice/${data.invoiceId}/pay`, {});
    return result;
}

export const postRefundInvoice = async (data: RefundInvoiceCommand) => {
    const result = await api.post(`/api/invoice/${data.invoiceId}/refund`, {});
    return result;
}

export const getInvoiceList = async (data: ListInvoicesQuery) => {
    const result = await api.get<PagedList<InvoiceContract>>(`/api/invoice`, data);
    return result;
}