import { Card, CardBody, CardHeader, CardTitle, FormGroup } from "reactstrap";
import type { EditSectionProps } from "./PrintDocumentEditPage";
import { useTranslation } from "react-i18next";
import Checkbox from "Components/Form/Checkbox";
import { useImmer } from "use-immer";
import { useEffect } from "react";
import type { BinLocationLabelPrintPreferences } from "api/types/contracts/printing";
import LocationLabelArrowDirectionSwitch from "Components/EnumSelects/LocationLabelArrowDirectionSwitch";

const BinLocationLabelEditSection = (props: EditSectionProps) => {
    const [values, updateValues] = useImmer(props.value as Record<keyof BinLocationLabelPrintPreferences, string | undefined>);

    const { t } = useTranslation();

    useEffect(() => {
        props.onChange(values);
        
    }, [values])

    return <>
        <Card>
            <CardHeader>
                <CardTitle tag="h6" className="mb-0">{t("Label Customization")}</CardTitle>
            </CardHeader>
            <CardBody>
                <FormGroup>
                    <Checkbox switch value={values.showArrow === "true"} onChange={val => updateValues(prev => {
                        prev.showArrow = val ? "true" : "false";
                        return prev;
                    })}>
                        <span>{t("Show Arrow")}</span>
                    </Checkbox>
                    <div className="hstack gap-2 align-items-center">
                        <i className="ri-arrow-right-down-line fs-24 "></i>
                        <strong>{t("Arrow Direction:")}</strong>
                        <LocationLabelArrowDirectionSwitch value={values.arrowDirection === "up" ? "up" : "down"} onChange={val => updateValues(prev => {
                            prev.arrowDirection = val;
                            return prev;
                        })} />
                    </div>
                    <span className="text-muted fw-light">
                        {t("paragraphs:BinLocationLabelShowArrowDescription")}
                    </span>
                </FormGroup>
            </CardBody>
        </Card>
    </>;
}

export default BinLocationLabelEditSection;