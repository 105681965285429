import { InfoAlert } from "Components/Alerts";
import Loader from "Components/Common/Loader";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import DateDisplay from "Components/Displays/DateDisplay";
import Dimensions from "Components/Displays/UnitDisplay/Dimensions";
import Weight from "Components/Displays/UnitDisplay/Weight";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardHeader, CardTitle, Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import CreateShipmentForm from "./_CreateShipmentForm";
import Restricted from "Components/Common/Restricted";
import _ from "lodash";
import Currency from "Components/Displays/UnitDisplay/Currency";
import { useAppSelector } from "Components/Hooks/StoreHooks";

type ShipmentListProps = {
    
}

const ShipmentList = (props: ShipmentListProps) => {
    const { t } = useTranslation();
    const [createModal, setCreateModal] = useState(false);
    const toggleCreateModal = () => setCreateModal(prev => !prev);

    const { fbaShipment, loading } = useAppSelector(
        (state) => ({
            fbaShipment: state.FbaShipping.processingItem,
            loading: state.FbaShipping.loading
        })
    );

    if (!fbaShipment) {
        return <Loader height="50px" />;
    }

    const list = fbaShipment.packages.filter(p => p.transport);

    return <>
        {fbaShipment.status === "boxLabelPending" && <>
            <Restricted require="shipping.fbashipping" write>
                <div className="d-flex justify-content-center mb-3">
                    <Button color="primary" onClick={toggleCreateModal}>{t("Create Shipments")}</Button>
                </div>
            </Restricted>
        </>}
        <Card>
            <CardHeader>
                <CardTitle tag="h5" className="mb-0">{t("Shipments")}</CardTitle>
            </CardHeader>
            <CardBody>
                {list.length > 0 ? <>
                    <div className="table-responsive table-card">
                        <Table className="mb-0 align-middle">
                            <thead className="table-light text-muted">
                                <tr>
                                    <th>{t("Created Date")}</th>
                                    <th>{t("Dimensions")}</th>
                                    <th>{t("Weight")}</th>
                                    <th>{t("Total Items")}</th>
                                    <th>{t("SKU Count")}</th>
                                    <th>{t("Carrier")}</th>
                                    <th>{t("Track ID")}</th>
                                    <th>{t("Price")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list.map((item, index) => <tr key={index}>
                                    <td>
                                        <DateDisplay date={item.transport!.createdAt} />
                                    </td>
                                    <td>
                                        <Dimensions value={item.box} unit={item.box.lengthUnit} />
                                    </td>
                                    <td>
                                        <Weight value={item.box.weight} unit={item.box.weightUnit} />
                                    </td>
                                    <td>
                                        {_.sumBy(item.box.products, p => p.count)}
                                    </td>
                                    <td>
                                        {item.box.products.length}
                                    </td>
                                    <td>
                                        {item.transport!.carrierAccountService.carrierService.carrier.name}
                                    </td>
                                    <td>
                                        {item.transport!.trackingNumber}
                                    </td>
                                    <td>
                                        <Currency value={item.transport!.totalCharge} currency={fbaShipment.warehouse.settings.unitOfCurrency} />
                                    </td>
                                </tr>)}
                            </tbody>
                        </Table>
                    </div>
                </> : <>
                    <InfoAlert className="mb-0">
                        {t("No shipments found")}
                    </InfoAlert>
                </>}
            </CardBody>
        </Card>
        <Modal size="xl" isOpen={createModal} toggle={toggleCreateModal} unmountOnClose>
            <ModalCloseButton onClick={toggleCreateModal} />
            <ModalHeader>
                {t("Create Shipment")}
            </ModalHeader>
            <ModalBody>
                <CreateShipmentForm onSuccess={toggleCreateModal} />
            </ModalBody>
        </Modal>
    </>;
}

export default ShipmentList;