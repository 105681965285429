import { APIClient, PagedList } from "helpers/api_helper";
import { GetCustomerQuery, ListCustomersQuery, ListCustomersQueryForSelect } from "./types/queries";
import { CustomerContract } from "./types/contracts/customers";
import { DeleteCustomerCommand, RegisterCustomerCommand, UpdateCustomerCommand, type UpdateCustomerInvoiceAddressCommand } from "./types/commands";

const api = new APIClient();

export const getCustomer = async (data: GetCustomerQuery) => {
    const result = await api.get<CustomerContract>(`/api/customer/${data.customerId}`);
    return result;
}

export const getCurrentCustomer = async () => {
    const result = await api.get<CustomerContract>(`/api/customer/current`);
    return result;
}

export const getCustomerList = async (data: ListCustomersQuery) => {
    const result = await api.get<PagedList<CustomerContract>>(`/api/customer`, data);
    return result;
}

export const getCustomerListForSelect = async (data: ListCustomersQueryForSelect) => {
    const result = await api.get<Promise<CustomerContract[]>>(`/api/customer/select`, data);
    return result;
}

export const postCustomerRegister = async (data: RegisterCustomerCommand): Promise<CustomerContract | undefined> => {
    const response = await api.post(`/api/customer/register`, data);
    
    if (response.status === 201) {
        return response.data as CustomerContract;
    } else {
        return undefined;
    }
}

export const putCustomer = async (data: UpdateCustomerCommand): Promise<void> => {
    await api.put(`/api/customer/${data.customerId}`, { ...data.customer });
}

export const deleteCustomer = async (data: DeleteCustomerCommand): Promise<void> => {
    await api.delete(`/api/customer/${data.customerId}`);
}

export const patchCustomerInvoiceAddress = async (data: UpdateCustomerInvoiceAddressCommand) => {
    await api.patch(`/api/customer/${data.customerId}/invoice-address`, { ...data.address});
}