import TextInput from "Components/Form/TextInput";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Form, InputGroup, Label, Modal, ModalBody, Row, Table } from "reactstrap";
import ProductGrid from "./_ProductGrid";
import ProductCard from "./_ProductCard";
import CreateProductLabel from "./_CreateProductLabel";
import CreateUnusableProductLabel from "./_CreateUnusableProductLabel";
import _ from "lodash";
import { assignReceivingProduct, loadReceivingEntry, removeReceivingProduct, selectBox } from "slices/receivings/thunk";
import { useFormik } from "formik";
import { AssignRecevingProductsCommand } from "api/types/commands";
import * as Yup from "yup";
import { TypedShape } from "helpers/types";
import ValidatedInput from "Components/Form/Validated/ValidatedInput";
import BusyOverlay from "Components/Common/BusyOverlay";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import { UnusableProductReasonContract } from "api/types/contracts/inventory";
import ProductPickList from "Components/EntitySelects/ProductPickList";
import { InfoAlert } from "Components/Alerts";
import ValidationWrapper from "Components/Form/Validated/ValidationWrapper";
import ValidatorButton from "Components/Form/Validated/ValidatorButton";
import { useSearchParams } from "react-router-dom";
import { InboundItemContract } from "api/types/contracts/inbound";
import CompleteButton from "../_CompleteButton";
import { inputKeyEventHandler } from "helpers/componentHelpers";
import { locationOf } from "helpers/locationOf";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";

const ProcessStorage = () => {
    const [params, setParams] = useSearchParams();
    const { t } = useTranslation();
    const dispatch = useAppDispatch();

    const [productBarcode, setProductBarcode] = useState<string>();
    const [selectedProduct, setSelectedProduct] = useState<InboundItemContract>();
    const [printLabelModal, setPrintLabelModal] = useState(false);
    const [productSelectModal, setProductSelectModal] = useState(false);
    const [labels, setLabels] = useState<{
        label: string,
        unusableReason?: UnusableProductReasonContract
    }[]>([]);
    
    const { processedEntry, selectedBox, loading } = useAppSelector(
        (state) => ({
            processedEntry: state.Receiving.processedEntry!,
            selectedBox: state.Receiving.selectedBox,
            loading: state.Receiving.loading
        })
    );

    const [inboundItems, setInboundItems] = useState(processedEntry.inbound?.items || []);

    const selectedScannedProducts = useMemo(() => processedEntry.productLabels.filter(p => p.productLabel.product.productId === selectedProduct?.product.productId && p.processed), [processedEntry.productLabels, selectedProduct]);
    const summary = useMemo(() => _.chain(processedEntry.productLabels).filter(p => !!p.processed).groupBy(p => p.productLabel.product.productId).map((g, productId) => ({
        product: g[0].productLabel.product,
        totalCount: g.length,
        sellableCount: g.filter(p => !p.productLabel.unusableProductReason).length,
        unusableCount: g.filter(p => !!p.productLabel.unusableProductReason).length
    })).value(), [processedEntry.productLabels]);

    const selectedSummary = useMemo(() => summary.find(s => s.product.productId === selectedProduct?.product.productId), [summary, selectedProduct]);
    
    const scanFormValidation = useFormik({
        initialValues: {} as AssignRecevingProductsCommand,
        validationSchema: Yup.object<AssignRecevingProductsCommand, TypedShape<Omit<AssignRecevingProductsCommand, "receivingBoxId">>>({
            serialNumber: Yup.string().required(t("You must enter a product label")),
            putawayBinCode: Yup.string().required(t("You must enter a putaway code"))
        }),
        onSubmit: (values) => {
            scan(values);
        },
    });

    const scan = async (values: AssignRecevingProductsCommand) => {

        const result = await dispatch(assignReceivingProduct(values.serialNumber, values.putawayBinCode, selectedBox!.receivingBoxId));

        if (!result) {
            return;
        }

        scanFormValidation.resetForm();
        scanFormValidation.setTouched({
            serialNumber: false,
            putawayBinCode: false
        });

        focusOnProductLabel();

        await dispatch(loadReceivingEntry({
            receivingEntryId: processedEntry.receivingEntryId
        }));

        if (labels.length === 1) {
            setSelectedProduct(undefined);
        }
    }

    const focusOnProductLabel = () => document.querySelector<HTMLInputElement>("#product-label")?.focus();
    const focusOnPutawayBin = () => document.querySelector<HTMLInputElement>("#putaway-bin")?.focus();

    const removeLabel = async (serialNumber: string) => {
        await dispatch(removeReceivingProduct(serialNumber));
        await dispatch(loadReceivingEntry({
            receivingEntryId: processedEntry.receivingEntryId
        }))
    }

    useEffect(() => {
        if (labels.length > 0) {
            setPrintLabelModal(true);
        }
    }, [labels]);

    const resetBoxSelection = () => {
        setParams({});
        dispatch(selectBox(undefined));
    }

    return <>
        {!selectedProduct && <>
            <Row className="mb-2">
                <Col xs="auto">
                    <div className="hstack gap-2 align-items-center">
                        <Label className="flex-shrink-0 mb-0">{t("Select Product")}</Label>
                        <TextInput placeholder={"Enter Product Barcode"} value={productBarcode} onChange={setProductBarcode} />
                    </div>
                </Col>
                <Col xs="auto" className="ms-auto">
                    <Button type="button" color="secondary" onClick={() => setProductSelectModal(true)}>{t("Select From Inventory")}</Button>
                </Col>
            </Row>
            <Row>
                <Col>
                    <ProductGrid items={inboundItems} onProductSelected={setSelectedProduct} />
                </Col>
            </Row>
        </>}
        {selectedProduct && <>
            <Row>
                <Col xs={12} sm={6} md={4}>
                    <ProductCard item={selectedProduct} />
                </Col>
                <Col xs={12} sm={6} md={8}>
                    <Card body>
                        <CreateProductLabel productId={selectedProduct.product.productId} onCreated={newLabels => setLabels([...newLabels.map(l => ({
                            label: l
                        }))])} />
                        <hr />
                        <CreateUnusableProductLabel productId={selectedProduct.product.productId} onCreated={(reason, newLabels) => setLabels([...newLabels.map(l => ({
                            label: l,
                            unusableReason: reason
                        }))])} />
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <CardHeader>
                            <CardTitle className="mb-0">
                                <h5 className="mb-0">{t("Putaway")}</h5>
                            </CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col sm={6}>
                                    <Form onSubmit={scanFormValidation.handleSubmit}>
                                        <div className="vstack gap-3">
                                            <ValidationWrapper validation={scanFormValidation} field="serialNumber">
                                                <TextInput id="product-label" placeholder={t("Product Label")} disabled={loading.scan} 
                                                    onKeyDown={inputKeyEventHandler("Enter", e => focusOnPutawayBin())} />
                                            </ValidationWrapper>
                                            <BusyOverlay busy={loading.scan} size="sm">
                                                <InputGroup>
                                                    <ValidatedInput id="putaway-bin" disabled={loading.scan} validation={scanFormValidation} field="putawayBinCode" placeholder={t("Putaway Bin Barcode")} />
                                                    <ValidatorButton validation={scanFormValidation} type="submit" color="secondary" disabled={loading.scan}>{t("Scan (or Press Enter)")}</ValidatorButton>
                                                </InputGroup>
                                            </BusyOverlay>
                                            <Button type="button" color="warning" onClick={() => setSelectedProduct(undefined)}>{t("Select Another Product")}</Button>
                                        </div>
                                    </Form>
                                </Col>
                                <Col sm={4} className="mx-auto">
                                    <Table borderless className="fs-18 align-middle">
                                        <tbody>
                                            <tr>
                                                <th>{t("Total Sellable Count")}</th>
                                                <td>{selectedSummary?.sellableCount || 0}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("Total Unusable Count")}</th>
                                                <td>{selectedSummary?.unusableCount || 0}</td>
                                            </tr>
                                            <tr>
                                                <th>{t("Total Received")}</th>
                                                <td>{selectedSummary?.totalCount || 0}</td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col>
                    <h5 className="my-3">{t("Scanned Products")}</h5>
                    <BusyOverlay busy={loading.load}>
                        {selectedScannedProducts.length > 0 ? <>
                            <Card>
                                <CardBody>
                                    <div className="table-card table-responsive">
                                        <Table striped className="mb-0 align-middle">
                                            <thead>
                                                <tr>
                                                    <th>{t("Action")}</th>
                                                    <th>{t("Product Label")}</th>
                                                    <th>{t("Putaway Bin")}</th>
                                                    <th>{t("Sellable")} / {t("Unusable")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {selectedScannedProducts.map((item, i) => <tr key={i}>
                                                    <td>
                                                        <div className="hstack gap-2">
                                                            <BusyOverlay busy={loading.scan} size="sm">
                                                                <Button color="soft-danger" size="sm" onClick={() => removeLabel(item.productLabel.serialNumber.toString())}>
                                                                    {t("Remove")}
                                                                </Button>
                                                            </BusyOverlay>
                                                        </div>
                                                    </td>
                                                    <td>#{item.productLabel.serialNumber}</td>
                                                    <td>{locationOf(item.productLabel)}</td>
                                                    <td>{item.productLabel.unusableProductReason ? `Unusable (${item.productLabel.unusableProductReason.name})` : "Sellable"}</td>
                                                </tr>)}
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </> : <>
                            <InfoAlert>{t("No products scanned yet")}</InfoAlert>
                        </>}
                    </BusyOverlay>
                </Col>
            </Row>
        </>}
        <Row>
            <Col>
                <h5 className="my-3">{t("Summary")}</h5>
                {summary.length > 0 ? <>
                    <Card>
                        <CardBody>
                            <div className="table-card table-responsive">
                                <Table striped className="mb-0 align-middle">
                                    <thead>
                                        <tr>
                                            <th>{t("SKU")}</th>
                                            <th>{t("Title")}</th>
                                            <th>{t("Total Received")}</th>
                                            <th>{t("Sellable Count")}</th>
                                            <th>{t("Unusable Count")}</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {summary.map(item => <tr key={item.product.productId}>
                                            <td>{item.product.sku}</td>
                                            <td>{item.product.name}</td>
                                            <td>{item.totalCount}</td>
                                            <td>{item.sellableCount}</td>
                                            <td>{item.unusableCount}</td>
                                        </tr>)}
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                </> : <>
                    <InfoAlert>{t("No products added yet")}</InfoAlert>
                </>}
            </Col>
        </Row>
        <Row>
            <Col>
                <div className="d-flex justify-content-end gap-2 mb-3">
                    <Button color="warning" onClick={resetBoxSelection}>{t("Back to Box Selection")}</Button>
                    <CompleteButton disabled={summary.length > 0}>{t("Complete Receiving")}</CompleteButton>
                </div>
            </Col>
        </Row>
        <Modal isOpen={printLabelModal} toggle={() => setPrintLabelModal(prev => !prev)}>
            <ModalBody>
                <ModalCloseButton onClick={() => setPrintLabelModal(false)} />
                <Table striped borderless className="mb-0 align-middle" size="sm">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t("Product Label")}</th>
                            <th>{t("Unusable Reason")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {labels.map((item, i) => <tr key={i}>
                            <td>
                                <Button color="soft-secondary" size="sm">
                                    {t("Print")}
                                </Button>
                            </td>
                            <td># {item.label}</td>
                            <td>{item.unusableReason?.name || "-"}</td>
                        </tr>)}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={3}>
                                <div className="d-flex justify-content-end mt-2">
                                    <Button color="success" size="sm" onClick={() => {}}>{t("Print All")}</Button>
                                </div>
                            </td>
                        </tr>
                    </tfoot>
                </Table>
            </ModalBody>
        </Modal>
        <Modal isOpen={productSelectModal} toggle={() => setProductSelectModal(prev => !prev)} size="xl">
            <ModalBody>
                <ModalCloseButton onClick={() => setProductSelectModal(false)} />
                <ProductPickList onSelectComplete={products => {
                    const newUniqueItems = products
                        .filter(p => !inboundItems.find(i => i.product.productId === p.productId))
                        .map(p => ({ product: p, quantity: 0, received: 0 }));
                    setInboundItems(prev => [...prev, ...newUniqueItems]);
                    setProductSelectModal(false);
                }} />
            </ModalBody>
        </Modal>
    </>;
}

export default ProcessStorage;