import SelectInput from "Components/Form/SelectInput"
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { ShipmentTargetContract } from "api/types/contracts/shipping";
import { EntitySelectInputProps } from "helpers/types";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { loadCountries } from "slices/common/thunk";
import { loadShipmentTargetList } from "slices/shipping/fbaShipping/thunk";

type ShipmentTargetSelectProps = EntitySelectInputProps<ShipmentTargetContract, "shipmentTargetId"> & {
    defaults: true
} | EntitySelectInputProps<ShipmentTargetContract, "shipmentTargetId"> & {
    defaults?: false,
    countryCode: string
}

const ShipmentTargetSelect = ({ isMulti, value, onChange, onSelect, name, isValid, ...rest }: ShipmentTargetSelectProps) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { shipmentTargets, loading } = useAppSelector(
        (state) => ({
            shipmentTargets: state.FbaShipping.shipmentTargets,
            loading: state.FbaShipping.loading.shipmentTargetList
        })
    );

    const shipmentTargetList = useMemo(() => shipmentTargets.map(st => ({ 
        label: st.isDefault ? st.name : `${st.name} - ${st.address.address1}, ${st.address.zipCode}, ${st.address.city}, ${st.address.stateCode}`,
        value: st.shipmentTargetId })), [shipmentTargets]);

    useEffect(() => {
        dispatch(loadShipmentTargetList({
            listDefaults: rest.defaults ?? false,
            countryCode: rest.defaults ? undefined : rest.countryCode
        }));
    }, [rest.defaults]);

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = shipmentTargets.filter(st => val.includes(st.shipmentTargetId));

        if (selected) {
            onSelect?.(selected);
            onChange?.(val);
            return;
        }

        onChange?.([]);
        onSelect?.([]);
    };

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            const selected = shipmentTargets.find(st => st.shipmentTargetId=== val);

            if (selected) {
                onSelect?.(selected);
                onChange?.(selected.shipmentTargetId);
                return;
            }
        }
        
        onChange?.(undefined);
        onSelect?.(undefined);
    };

    useEffect(() => {
        dispatch(loadCountries());
    }, [dispatch]);

    useEffect(() => {
        if (!value) {
            return;
        }

        if (shipmentTargets.length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shipmentTargets]);
    
    const selectProps = {
        ...rest,
        placeholder: t("Shipment Target"),
        busy: loading,
        options: shipmentTargetList,
        showClear: true,
        isValid: isValid
    }

    return <>
        {isMulti && <SelectInput<string> isMulti value={value} onChange={onChangedMulti} {...selectProps} />}
        {!isMulti && <SelectInput value={value} onChange={onChangedSingle} {...selectProps} />}
    </>;
}

export default ShipmentTargetSelect;