import type { UnitOfCurrency } from "./common"
import type { CustomerContract } from "./customers";
import type { FbaShipmentContract } from "./shipping"
import type { UserContract } from "./users";

export const InvoiceStatusList = ["pending", "refund", "cancelled", "paid"] as const;
export const PaymentMethodList = ["stripe", "wise", "payoneer", "wireTransfer", "zelle"] as const;
export type InvoiceStatus = typeof InvoiceStatusList[number];
export type PaymentMethod = typeof PaymentMethodList[number];
export type CreditCardVault = "stripe";

export type InvoiceContract = {
    invoiceId: string,
    fbaShipment: FbaShipmentContract | undefined,
    invoiceCode: string,
    status: InvoiceStatus,
    shippingCost: number,
    invoiceDate: Date,
    importCost: number,
    total: number,
    unitOfCurrency: UnitOfCurrency,
    items: InvoiceItemContract[],
    customer: CustomerContract,
    createdAt: Date,
    owner: UserContract
}
export type InvoiceItemContract = {
    invoiceItemId: string,
    serviceName: string,
    prepServiceId: string,
    unitPrice: number,
    discount?: number,
    price: number,
    count: number,
    total: number,
    createdAt: Date,
}

export type InvoiceForm = {
    //invoiceCode: string,
    shippingCost: number,
    importCost: number,
    unitOfCurrency: UnitOfCurrency,
    items: InvoiceItemForm[]
}

export type InvoiceItemForm = {
    prepServiceId: string,
    serviceName: string,
    unitPrice: number,
    discount?: number | undefined | null,
    count: number,
    total: number
}

export type WalletContract = {
    walletId: string,
    warehouseId: string,
    balance: number,
    unitOfCurrency: UnitOfCurrency,
    createdAt: Date,
    updatedAt: Date
}

export type CreditCardContract = {
    creditCardId: string,
    customerId: string,
    maskedCardNumber: string,
    identifier: string,
    vault: CreditCardVault,
    isDefault: boolean,
    type: string,
    expirationYear: number,
    expirationMonth: number,
    ownerUserId: string,
    createdAt: Date,
    owner: UserContract
}

/*
public record TransactionHistoryContract
{
    public Guid TransactionHistoryId { get; set; }
    public Guid? WalletId { get; set; }
    public decimal? Debit { get; set; }
    public decimal? Credit { get; set; }
    public decimal Balance { get; set; }
    public string UnitOfCurrency { get; set; }
    public string Description { get; set; }
    public DateTimeOffset CreatedAt { get; set; }

    public virtual CustomerContract Customer { get; set; }
    public virtual InvoiceContract? Invoice { get; set; }
}
*/
export type TransactionHistoryContract = {
    transactionHistoryId: string,
    transactionCode: string,
    walletId: string | undefined,
    debit: number | undefined,
    credit: number | undefined,
    balance: number,
    unitOfCurrency: UnitOfCurrency,
    description: string,
    createdAt: Date,
    customer: CustomerContract,
    invoice: InvoiceContract | undefined
}