import type { LoginInfo } from "api/types/contracts/users";

/**
 * Retrieves the logged-in user information from the local storage.
 * @returns The logged-in user information if available, otherwise undefined.
 */
const getLoggedInUser = (): LoginInfo | undefined => {
    const user = localStorage.getItem("authUser");
    if (!user) {
        return undefined;
    } else {
        return JSON.parse(user);
    }
};

/**
 * Sets the logged-in user in the local storage.
 * @param user The user information to be stored.
 */
const setLoggedInUser = (user: LoginInfo | undefined) => { 
    if (user) {
        localStorage.setItem("authUser", JSON.stringify(user));
    }
    else {
        localStorage.removeItem("authUser");
    }
}

export { getLoggedInUser, setLoggedInUser };