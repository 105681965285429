import type { Maybe } from "yup";
import type { WarehouseContract } from "./warehouses";
import type { CustomerContract } from "./customers";
import type { UserContract } from "./users";
import type { ProductContract } from "./products";
import type { AddressContract, DocumentContract, UnitOfLength, UnitOfWeight } from "./common";
import type { CarrierAccountServiceContract } from "./carrier";
import type { InvoiceContract } from "./payment";

export const PrepServiceChannelList = ["fba", "d2c"] as const;
export const PrepServicePricingTypeList = ["fixPrice", "perUnit", "perBox", "perSku", "custom"] as const;
export const PrepServiceTypeList = ["standart", "expireDate", "bundling", "splitting"] as const;
export const FbaShipmentStatusList = ["productPending", "fnskuPending", "ready", "processing", "boxLabelPending", "paymentWaiting", "paymentCompleted", "shipped"] as const;
export const FbaShipmentItemStatusList = ["waiting", "ready", "prepared"] as const;
export const ShipmentTargetKindList = ["other", "amazonWarehouse"] as const;
export const ShipmentDutyTypeList = ["sender", "receiver"] as const;
export const TransportStatusList = ["pending", "intransit", "delivered", "returned", "cancelled"] as const;

export type PrepServiceChannel = typeof PrepServiceChannelList[number];
export type PrepServicePricingType = typeof PrepServicePricingTypeList[number];
export type PrepServiceType = typeof PrepServiceTypeList[number];
export type FbaShipmentStatus = typeof FbaShipmentStatusList[number];
export type FbaShipmentItemStatus = typeof FbaShipmentItemStatusList[number];
export type ShipmentTargetKind = typeof ShipmentTargetKindList[number];
export type ShipmentDutyType = typeof ShipmentDutyTypeList[number];
export type TransportStatus = typeof TransportStatusList[number];

export type PrepServiceContract = {
    prepServiceId: string,
    name: string,
    description?: string,
    includeAsDefault: boolean,
    showInProductOptions: boolean,
    pricingType: PrepServicePricingType,
    serviceType: PrepServiceType,
    price?: number,
    companyId: string,
    channels: PrepServiceChannel[],
    createdAt: Date,
    priceRanges: PrepServiceRangePricingContract[],
    warehouse: WarehouseContract,
    owner: UserContract,
}

export type PrepServiceForm = {
    name: string,
    description: Maybe<string>,
    warehouseId: string,
    includeAsDefault: boolean,
    showInProductOptions: boolean,
    price: Maybe<number>,
    pricingType: PrepServicePricingType,
    serviceType: PrepServiceType,
    channels: PrepServiceChannel[],
    priceRanges: PrepServiceRangePricingContract[]
}

export type PrepServiceRangePricingContract = {
    from: number,
    to: number,
    value: number
}

export type FbaShipmentContract = {
    fbaShipmentId: string,
    shipmentCode: string,
    status: FbaShipmentStatus,
    statusUpdateMap: Record<FbaShipmentStatus, Date>,
    estimatedCost: FbaShipmentCostContract,
    actualCost: FbaShipmentCostContract,
    shipmentCharges: ShipmentChargesContract,
    estimatedBoxes: FbaShipmentBoxContract[],
    createdAt: Date,
    warehouse: WarehouseContract,
    owner: UserContract,
    customer: CustomerContract
    items: FbaShipmentItemContract[],
    prepServices: FbaShipmentPrepServiceContract[],
    packages: FbaShipmentPackageContract[],
    documents: DocumentContract[],
    invoices: InvoiceContract[],
    carrierAccountService: CarrierAccountServiceContract,
    shipmentTarget: ShipmentTargetContract
}

export type FbaShipmentCostContract = {
    shipping: number,
    import: number,
    prep: number,
    oversize: number,
    overweight: number,
    insurance: number,
    total: number,
    items: FbaShipmentCostTableItemContract[]
}

export type FbaShipmentCostTableItemContract = {
    prepServiceId: string,
    serviceName: string,
    discount?: Maybe<number>,
    unitPrice: number,
    count: number,
    total: number
}

export type FbaShipmentItemContract = {
    fbaShipmentItemId: string,
    fnsku?: string,
    count: number,
    reserved: number,
    buyPrice: number,
    isBundle: boolean,
    isSplit: boolean,
    expireDate?: Date,
    status: FbaShipmentItemStatus,
    createdAt: Date,
    product: ProductContract,
    splitItem?: FbaShipmentSplitItemContract,
    bundleItems: FbaShipmentBundleItemContract[],
    prepServices: FbaShipmentPrepServiceContract[]
}

export type FbaShipmentSplitItemContract = {
    fbaShipmentSplitItemId: string,
    count: number,
    totalItemCount: number,
    splittedItemCount: number,
    createdAt: Date,
    product: ProductContract
}

export type FbaShipmentBundleItemContract = {
    fbaShipmentBundleItemId: string,
    count: number,
    totalNeeded: number,
    createdAt: Date,
    product: ProductContract
}

export type FbaShipmentPrepServiceContract = {
    fbaShipmentPrepServiceId: string,
    count: number,
    price: number,
    completed: boolean,
    createdAt: Date,
    prepService: PrepServiceContract
}

export type FbaShipmentBoxContract = {
    boxNumber: number,
    width: number,
    height: number,
    length: number,
    weight: number,
    desi: number,
    lengthUnit: UnitOfLength,
    weightUnit: UnitOfWeight,
    products: FbaShipmentBoxItemContract[],
}

export type FbaShipmentBoxItemContract = {
    productId: string,
    asin: string,
    count: number
}

export type FbaShipmentPackageContract = {
    fbaShipmentPackageId: string,
    fbaShipmentId: string,
    shipmentTargetId: string | undefined,
    box: FbaShipmentBoxContract,
    value: number | undefined,
    createdAt: Date,
    owner: UserContract,
    shipmentTarget: ShipmentTargetContract,
    transport: FbaShipmentTransportContract | undefined
}

export type FbaShipmentTransportContract = {
    fbaShipmentTransportId: string,
    fbaShipmentId: string,
    carrierAccountServiceId: string,
    packageTypeCode: string | undefined,
    dutyType: ShipmentDutyType,
    trackingNumber: string,
    commercialInvoicePath: string
    labelPath: string,
    shipmentCharges: ShipmentChargesContract,
    totalCharge: number,
    status: TransportStatus,
    createdAt: Date,
    owner: UserContract,
    carrierAccountService: CarrierAccountServiceContract
}

export type FbaShipmentPackageFormItem = {
    box: FbaShipmentBoxContract
}

export type FbaShipmentUpdateFormItem = {
    productId: string,
    count?: Maybe<number>,
    expireDate?: Maybe<Date>,
    fnsku?: Maybe<string>,
    status?: Maybe<FbaShipmentItemStatus>,
    prepServices?: Maybe<string[]>
}

export type ShipmentTargetContract = {
    shipmentTargetId: string,
    name: string,
    targetKind: ShipmentTargetKind,
    isStandart: boolean,
    isDefault: boolean,
    countryCode: string,
    addressId: string,
    ownerUserId: string,
    createdAt: Date,
    address: AddressContract,
    owner: UserContract
}

export type CarrierPackageTypeContract = {
    packageTypeCode: string,
    name: string,
    maxWeight: number | undefined,
    width: number | undefined,
    height: number | undefined,
    length: number | undefined
}

export type ShipmentChargesContract = {
    totalCost: number,
    shippingCost: number,
    importTaxCost: number,
    insuranceCost: number,
    oversizeCost: number,
    overweightCost: number,
    details: Record<string, string | undefined>
}

export type FbaShipmentTransportForm = {
    fbaShipmentPackageId: string,
    carrierAccountServiceId: string,
    shipmentTargetId: string,
    packageTypeCode: Maybe<string>,
    dutyType: ShipmentDutyType,
    trackingNumber: string,
    commercialInvoicePath: Maybe<string>,
    labelPath: string,
    shipmentCharges: ShipmentChargesContract,
    totalCharge: number,
    packageValue: number
}