import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import type { ClientPrinterContract, PrintingSetupContract, PrintSizeContract } from "api/types/contracts/printing";
import type { LoadingStates } from "helpers/types";

type PrinterLoadingStates = LoadingStates<"list" | "test" | "preferenceList" | "preferenceSave" | "printSizeList" | "savePrinter">;

export type PrinterState = {
    printers: ClientPrinterContract[],
    printSizes: PrintSizeContract[],
    printerPreferences: PrintingSetupContract | undefined,
    error: string | undefined,
    loading: PrinterLoadingStates
};

export const initialState: PrinterState = {
    printers: [],
    printSizes: [],
    printerPreferences: undefined,
    error: undefined,
    loading: {
        list: false,
        test: false,
        preferenceList: false,
        preferenceSave: false,
        printSizeList: false,
        savePrinter: false
    }
};

const PrinterSlice = createSlice({
    name: "PrinterSlice",
    initialState,
    reducers: {
        setPrinters(state, payload: PayloadAction<ClientPrinterContract[]>) {
            state.printers = payload.payload;
        },
        setPrintSizes(state, payload: PayloadAction<PrintSizeContract[]>) {
            state.printSizes = payload.payload;
        },
        setPrinterPreferences(state, payload: PayloadAction<PrintingSetupContract | undefined>) {
            state.printerPreferences = payload.payload;
        },
        setError(state, payload: PayloadAction<string>) {
            state.error = payload.payload;
        },
        setLoading(state, payload: PayloadAction<[keyof PrinterLoadingStates, boolean]>) {
            const [key, value] = payload.payload;
            state.loading[key] = value;
        }
    }
});

export const {
    setPrinters,
    setError,
    setLoading,
    setPrintSizes,
    setPrinterPreferences
} = PrinterSlice.actions;

export default PrinterSlice.reducer;
