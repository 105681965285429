import BusyOverlay from "Components/Common/BusyOverlay";
import ListSummaryDisplay from "Components/Common/ListSummaryDisplay";
import DateDisplay from "Components/Displays/DateDisplay";
import ReceivingEntryStatusBadge from "Components/Displays/ReceivingEntryStatusDisplay";
import { useDebounce } from "Components/Hooks/HelperHooks";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { ReceivingEntryContract } from "api/types/contracts/receivings";
import { LatestReceivingEntriesQuery } from "api/types/queries";
import { dotDotDot } from "helpers/string";
import _ from "lodash";
import { useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Button, Table } from "reactstrap";
import { loadLatest, selectReceivingForGateEntry } from "slices/receivings/thunk";
import { useImmer } from "use-immer";

type LastReceivingsProps = {
    
}

const LastReceivings = (props: LastReceivingsProps) => {
    const [query, updateQuery] = useImmer<LatestReceivingEntriesQuery>({
        customerId: "",
        inboundId: ""
    });
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { receivings, loading, inbound, customer } = useAppSelector(
        (state) => ({
            loading: state.Receiving.loading,
            receivings: state.Receiving.gateEntry.latest,
            inbound: state.Receiving.gateEntry.inbound,
            customer: state.Receiving.gateEntry.customer,
            error: state.Receiving.error,
        })
    );

    const debouncedLoadList = useDebounce(() => {
        dispatch(loadLatest(query));
    }, 200);

    useEffect(() => {
        debouncedLoadList();
    }, [debouncedLoadList, dispatch, query]);

    const list = useMemo<(ReceivingEntryContract | undefined)[]>(() => Array.from({ ...receivings, length: 5 }), [receivings]);

    useEffect(() => {
        updateQuery(q => {
            q.inboundId = inbound?.inboundId;
            q.customerId = customer?.customerId;
        });

    }, [customer, inbound, updateQuery]);

    const onContinue = (receiving: ReceivingEntryContract) => {
        dispatch(selectReceivingForGateEntry(receiving));
    }

    // receivings table
    return <>
        <div className="table-responsive table-card">
            <BusyOverlay busy={loading.latest} backgroundColor="white" spinnerColor="tenant-primary">
                <Table striped className="align-middle mb-0 table-nowrap">
                    <thead>
                        <tr>
                            <th></th>
                            <th>{t("Create Date")}</th>
                            <th>{t("Barcode ID")}</th>
                            <th>{t("Inbound ID")}</th>
                            <th>{t("Tracking Numbers")}</th>
                            <th>{t("Box Count")}</th>
                            <th>{t("Products")}</th>
                            <th>{t("Status")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {list.map((item, index) => {
                            return item ? <tr key={index}>
                                <td>
                                    {item?.status === "grn" && <>
                                        <Button color="success" size="sm" onClick={() => onContinue(item)}>
                                            {t("Continue")}
                                        </Button>
                                    </>}
                                </td>
                                <td><DateDisplay date={item.createdAt} /></td>
                                <td># {item.receivingNumber}</td>
                                <td>{item.inbound ? `# ${item.inbound.inboundCode}` : "-"}</td>
                                <td>
                                    <ListSummaryDisplay items={item.boxes || []} displayFn={t => t.trackingNumber} />
                                </td>
                                <td>{item.boxes.length}</td>
                                <td>
                                    {item.inbound ?
                                        <ListSummaryDisplay items={_.uniqBy(item.inbound.items, "product.productId")} displayFn={i => dotDotDot(i.product.name, 50)} listFn={i => i.product.name} />
                                        : <>-</>}
                                </td>
                                <td>
                                    <ReceivingEntryStatusBadge value={item.status} />
                                </td>
                            </tr> : <tr key={index}><td colSpan={8}>&nbsp;</td></tr>;
                        })}
                    </tbody>
                </Table>
            </BusyOverlay>
        </div>
    </>;
}

export default LastReceivings;