import TitleBreadcrumb from "Components/Common/TitleBreadcrumb";
import { useTranslation } from "react-i18next";
import { AccordionBody, AccordionHeader, AccordionItem, Badge, Button, Card, CardBody, CardHeader, Col, Container, DropdownItem, DropdownMenu, DropdownToggle, FormGroup, Label, Modal, ModalBody, Row, UncontrolledAccordion, UncontrolledButtonDropdown, UncontrolledCollapse, UncontrolledDropdown, UncontrolledTooltip } from "reactstrap";
import AddCarrierButton from "./_AddCarrierButton";
import WarehouseSelect from "Components/EntitySelects/WarehouseSelect";
import { useImmer } from "use-immer";
import { ListCarrierAccountsQuery } from "api/types/queries";
import SearchBox from "Components/Form/SearchBox";
import { createSelector } from "reselect";
import { RootState } from "slices";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useRef, useState } from "react";
import { useDebounce } from "Components/Hooks/HelperHooks";
import { loadCarrierAccounts, removeCarrierAccount, removeCarrierAccountService } from "slices/carrier/thunk";
import Loader from "Components/Common/Loader";
import _ from "lodash";
import { useMetadata } from "Components/Hooks/MetadataHooks";
import classNames from "classnames";
import { CarrierAccountContract, CarrierAccountServiceContract } from "api/types/contracts/carrier";
import Dialog, { DialogRef } from "Components/Common/Dialog";
import ModalCloseButton from "Components/Common/ModalCloseButton";
import UpdateCarrierAccountServiceForm from "./_UpdateCarrierAccountServiceForm";
import EditCarrierAccountForm from "./_EditCarrierAccountForm";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";

type CarriersPageProps = {};

const CarriersPage = (props: CarriersPageProps) => {
    const { t } = useTranslation();
    const dispatch = useAppDispatch();
    const { setPageTitle } = useMetadata();
    const [selectedItem, setSelectedItem] = useState<[CarrierAccountContract, CarrierAccountServiceContract | undefined]>();
    const [editCarrierAccountServiceModal, setEditCarrierAccountServiceModal] = useState(false);
    const [editCarrierAccountModal, setEditCarrierAccountModal] = useState(false);
    const deleteCarrierAccountServiceDialogRef = useRef<DialogRef>(null);
    const deleteCarrierAccountDialogRef = useRef<DialogRef>(null);
    const [query, updateQuery] = useImmer<ListCarrierAccountsQuery>({
        warehouseId: "",
        search: ""
    });
    
    const { carrierAccounts, loading } = useAppSelector(
        (state) => ({
            loading: state.Carrier.loading,
            carriers: state.Carrier.carriers,
            carrierAccounts: state.Carrier.carrierAccounts,
            error: state.Carrier.error,
        })
    );
    
    // Inside your component
    const loadCarrierAccountsDebounced = useDebounce(() => dispatch(loadCarrierAccounts(query)), 500);

    setPageTitle(`${t("Carrier List")} - ${t("Management")}`);

    useEffect(() => {
        loadCarrierAccountsDebounced();
    }, [query]);

    const editCarrierAccount = (value: CarrierAccountContract) => {
        setSelectedItem([value, undefined]);
        setEditCarrierAccountModal(true);
    }

    const deleteCarrierAccount = (value: CarrierAccountContract) => {
        setSelectedItem([value, undefined]);
        deleteCarrierAccountDialogRef.current?.show();
    }

    const editCarrierAccountService = (value: [CarrierAccountContract, CarrierAccountServiceContract]) => {
        setSelectedItem(value);
        setEditCarrierAccountServiceModal(true);
    };

    const deleteCarrierAccountService = (value: [CarrierAccountContract, CarrierAccountServiceContract]) => {
        setSelectedItem(value);
        deleteCarrierAccountServiceDialogRef.current?.show();
    }

    return <>
        <div className="page-content">
            <Container fluid>
                <TitleBreadcrumb active={t("Carriers")} parents={[t("Management")]} />
                <Card>
                    <CardHeader className="border-0">
                        <Row className="align-items-center gy-3">
                            <div className="col-sm">
                                <h5 className="card-title mb-0">{t("Carrier List")}</h5>
                            </div>
                            <div className="col-sm-auto">
                                <div className="d-flex gap-2 flex-wrap">
                                    <AddCarrierButton onSuccess={params => {
                                        updateQuery(draft => {
                                            draft.warehouseId = params.selectedWarehouseId;
    
                                            return draft;
                                        });
                                        loadCarrierAccountsDebounced();
                                    }} />
                                </div>
                            </div>
                        </Row>
                    </CardHeader>
                    <CardBody>
                        <Row>
                            <Col sm={6}>
                                <FormGroup row className="align-items-center">
                                    <Label className="col-auto">{t("Name")}</Label>
                                    <Col>
                                        <WarehouseSelect value={query.warehouseId} onChange={val => {
                                            if (val) {
                                                updateQuery(draft => {
                                                    draft.warehouseId = val;
    
                                                    return draft;
                                                });
                                            }
                                        }} />
                                    </Col>
                                </FormGroup>
                            </Col>
                            <Col sm={6}>
                                <FormGroup row className="align-items-center">
                                    <Label className="col-auto">{t("Search")}</Label>
                                    <Col>
                                        <SearchBox value={query.search} placeholder={t("Filter by carrier name, account name, country or description")} onChange={val => {
                                            updateQuery(draft => {
                                                draft.search = val;
    
                                                return draft;
                                            });
                                        }} />
                                    </Col>
                                </FormGroup>
                            </Col>
                        </Row>
                    </CardBody>
                </Card>
                <h5 className="mb-3">
                    <i className="ri-truck-fill me-2"></i>
                    {t("Your Courier Accounts")}
                </h5>
                {loading.listAccounts ? <Loader height="50px" /> : <>
                    {carrierAccounts.length === 0 ? <p className="text-muted text-center">{t("No carrier accounts found")}</p> : <>
                        {carrierAccounts.map((carrierAccount, i) => {
                            const accountServices = _.groupBy(carrierAccount.carrierAccountServices, x => x.carrierServiceId);

                            return <Card key={i} className="mb-3">
                                <CardBody>
                                    <div className="d-flex mb-4 align-items-center">
                                        <div className="flex-shrink-0">
                                            <img src={carrierAccount.carrier.logoImage} alt="" className="avatar-sm rounded-circle object-fit-contain" />
                                        </div>
                                        <div className="flex-grow-1 ms-2">
                                            <h5 className="card-title mb-1">
                                                <span>{carrierAccount.carrier.name} - {carrierAccount.accountName}</span>
                                                <Badge color="light" className="ms-2 fs-13 p-2 text-body font-monospace">{_.values(carrierAccount.apiParameters)[0]}</Badge>
                                            </h5>
                                            <p className="text-muted mb-0">
                                                <span>{t("{{count}} service", { count: _.size(accountServices) })}</span>
                                            </p>
                                        </div>
                                        <div className="flex-shrink-0">
                                            <UncontrolledDropdown>
                                                <DropdownToggle tag="button" className="btn btn-ghost-dark btn-icon">
                                                    <i className="ri-more-2-fill fs-3"></i>
                                                </DropdownToggle>
                                                <DropdownMenu>
                                                    <DropdownItem onClick={() => editCarrierAccount(carrierAccount)}>{t("Edit Account")}</DropdownItem>
                                                    <DropdownItem onClick={() => deleteCarrierAccount(carrierAccount)}>{t("Delete Account")}</DropdownItem>
                                                </DropdownMenu>
                                            </UncontrolledDropdown>
                                        </div>
                                    </div>
                                    {carrierAccount.carrierAccountServices.length === 0 ? <>
                                    </> : <UncontrolledAccordion defaultOpen={i.toString()} toggle={() => {}}>
                                        <AccordionItem>
                                            <AccordionHeader tag="h5" targetId={i.toString()}>
                                                {t("Enabled Services")}
                                            </AccordionHeader>
                                            <AccordionBody accordionId={i.toString()}>
                                                {_.map(accountServices, (carrierAccountServices, carrierServiceId) => {
                                                    const carrierService = carrierAccountServices[0].carrierService;

                                                    return <>
                                                        <h6 className="fw-bold">{carrierService.name}</h6>
                                                        {carrierAccountServices.map(accountService => <>
                                                            <div className={classNames("px-4", {
                                                                "text-dark": accountService.enabled
                                                            })}>
                                                                {<Row className="mb-2 align-items-center position-relative">
                                                                    {accountService.needsSetup && <>
                                                                        <i id={`setup-${accountService.carrierAccountServiceId}`} className="position-absolute top-0 start-0 bottom-0 text-warning ri-alert-fill translate-middle-x aspect-1x1 fs-17 w-auto"></i>
                                                                        <UncontrolledTooltip placement="left" target={`setup-${accountService.carrierAccountServiceId}`}>{t("Setup required")}</UncontrolledTooltip>
                                                                    </>}
                                                                    <Col xs={6} sm={3}>{accountService.country.name}</Col>
                                                                    <Col xs={6} sm={2}>{accountService.deliveryWindow || "-"}</Col>
                                                                    <Col xs={6} sm={3}>
                                                                        {accountService.carrierRateSource === "api" && <span><i className="ri-settings-4-line me-1"></i>{t("API Rates Enabled")}</span>}
                                                                        {accountService.carrierRateSource === "lookupTable" && <span><i className="ri-file-text-line me-1"></i>{t("Lookup Rates Enabled ({{count}})", { count: _.values(accountService.lookupTable).length })}</span>}
                                                                    </Col>
                                                                    <Col xs={3} sm={2}>{accountService.enabled ? <Badge color="success">{t("Active")}</Badge> : <Badge color="light" className="text-body">{t("Passive")}</Badge>}</Col>
                                                                    <Col xs={3} sm={2}>
                                                                        <div className="hstack gap-2">
                                                                            <Button size="sm" color="ghost-dark" className="btn-icon" onClick={() => editCarrierAccountService([carrierAccount, accountService])}>
                                                                                <i className="ri-settings-5-fill fs-16"></i>
                                                                            </Button>
                                                                            <Button size="sm" color="ghost-danger" className="btn-icon" onClick={() => deleteCarrierAccountService([carrierAccount, accountService])}>
                                                                                <i className="ri-delete-bin-7-fill fs-16"></i>
                                                                            </Button>
                                                                        </div>
                                                                    </Col>
                                                                </Row>}
                                                            </div>
                                                        </>)}
                                                    </>;
                                                })}
                                            </AccordionBody>
                                        </AccordionItem>
                                    </UncontrolledAccordion>}
                                </CardBody>
                            </Card>;
                        })}
                    </>}
                </>}
            </Container>
        </div>
        {selectedItem && selectedItem[1] && <Modal isOpen={editCarrierAccountServiceModal} toggle={() => setEditCarrierAccountServiceModal(!editCarrierAccountServiceModal)} size="md">
            <ModalCloseButton onClick={() => setEditCarrierAccountServiceModal(false)} />
            <ModalBody>
                <UpdateCarrierAccountServiceForm
                    carrier={selectedItem[0].carrier}
                    carrierAccount={selectedItem[0]}
                    carrierAccountService={selectedItem[1]}
                    onCancel={() => setEditCarrierAccountServiceModal(false)}
                    onSuccess={() => {
                        setEditCarrierAccountServiceModal(false);
                        setSelectedItem(undefined);
                        loadCarrierAccountsDebounced();
                    }}
                />
            </ModalBody>
        </Modal>}
        {selectedItem && <Modal isOpen={editCarrierAccountModal} toggle={() => setEditCarrierAccountModal(!editCarrierAccountModal)} size="md">
            <ModalCloseButton onClick={() => setEditCarrierAccountModal(false)} />
            <ModalBody>
                <EditCarrierAccountForm
                    carrier={selectedItem[0].carrier}
                    carrierAccount={selectedItem[0]}
                    onCancel={() => setEditCarrierAccountModal(false)}
                    onSuccess={() => {
                        setEditCarrierAccountModal(false);
                        setSelectedItem(undefined);
                        loadCarrierAccountsDebounced();
                    }}
                />
            </ModalBody>
        </Modal>}
        <Dialog ref={deleteCarrierAccountServiceDialogRef} busy={loading.delete} buttons={["yes", "no"]}  color="warning" iconClass="ri-delete-bin-line"  
            message={`Do you want to continue?`} title={t("Deleting carrier service '{{name}}' for '{{country}}'", { 
                name: selectedItem?.[1]?.carrierService.name, 
                country: selectedItem?.[1]?.country.name })}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    dispatch(removeCarrierAccountService({
                        carrierAccountServiceId: selectedItem![1]!.carrierAccountServiceId
                    })).then(() => {
                        hide();
                        loadCarrierAccountsDebounced();
                    });
                }
                else {
                    hide();
                }
            }} />
        <Dialog ref={deleteCarrierAccountDialogRef} busy={loading.delete} buttons={["yes", "no"]}  color="warning" iconClass="ri-delete-bin-line"  
            message={`Do you want to continue?`} title={t("Deleting carrier account '{{name}}'", { name: selectedItem?.[0].accountName })}
            onButtonClick={(button, hide) => {
                if (button === "yes") {
                    dispatch(removeCarrierAccount({
                        carrierAccountId: selectedItem![0]!.carrierAccountId
                    })).then(() => {
                        hide();
                        loadCarrierAccountsDebounced();
                    });
                }
                else {
                    hide();
                }
            }} />
    </>;
}

export default CarriersPage;