import { PropsWithChildren, useEffect } from "react";
import { Navigate } from "react-router-dom";

import { useProfile } from "Components/Hooks/ProfileHooks";
import { logoutUser } from "slices/auth/login/thunk";
import { useAppDispatch } from "Components/Hooks/StoreHooks";

const AuthProtected = (props: PropsWithChildren) => {
    const dispatch = useAppDispatch();
    const { userProfile } = useProfile();

    useEffect(() => {
        if (!userProfile || new Date(userProfile.expiresAt) < new Date()) {
            dispatch(logoutUser());
        }
    }, [userProfile, dispatch]);

    /*
        Navigate is un-auth access protected routes via url
    */

    if (!userProfile || new Date(userProfile.expiresAt) < new Date()) {
        return (
            <Navigate to={{ pathname: "/login" }} />
        );
    }

    return <>{props.children}</>;
};


export default AuthProtected;