import { InfoAlert } from "Components/Alerts";
import Loader from "Components/Common/Loader";
import DateDisplay from "Components/Displays/DateDisplay";
import { useAppSelector } from "Components/Hooks/StoreHooks";
import { FbaShipmentStatus } from "api/types/contracts/shipping";
import classNames from "classnames";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { Progress } from "reactstrap";

const step = 100 / 8;
const ProgressMap: Record<FbaShipmentStatus, number> = {
    productPending: step * 1,
    fnskuPending: step * 2,
    ready: step * 3,
    processing: step * 4,
    boxLabelPending: step * 5,
    paymentWaiting: step * 6,
    paymentCompleted: step * 7,
    shipped: step * 8,
}

type ShipmentProgressProps = {
    
}

const ShipmentProgress = (props: ShipmentProgressProps) => {
    const { t } = useTranslation("enums");
    const { fbaShipment, loading } = useAppSelector(
        (state) => ({
            fbaShipment: state.FbaShipping.processingItem,
            loading: state.FbaShipping.loading
        })
    );

    if (!fbaShipment) {
        return <Loader height="50px" />;
    }

    const progress = ProgressMap[fbaShipment.status];

    return <div className="bg-body-secondary p-1 rounded-3">
        <Progress value={progress} color="vertical-gradient" className="custom-progress" />
        <div className="hstack gap-1 text-center" style={{ height: "3.5rem" }}>
            {_.map(ProgressMap, (value: number, key: FbaShipmentStatus) => 
                <div key={key} className={classNames("w-100", fbaShipment.statusUpdateMap[key] ? "pt-2 pb-1" : "py-3", { 
                    "bg-light text-body": progress < value, 
                    "bg-primary text-light": progress > value,
                    "bg-vertical-gradient text-light": progress === value,
                    "rounded-start-3": key === "productPending",
                    "rounded-end-3": key === "shipped"
                    })}>
                    {t(`FbaShipmentStatus.${key}`)}
                    <br />
                    {fbaShipment.statusUpdateMap[key] && <span className="fs-10"><DateDisplay date={fbaShipment.statusUpdateMap[key]} dateOnly /></span>}
                </div>
            )}
        </div>
        <InfoAlert className="mb-0 mt-2" kind="left-border">{t(`paragraphs:FbaShipmentStatusDescription.${fbaShipment.status}`)}</InfoAlert>
    </div>;
}

export default ShipmentProgress;