import SelectInput from "Components/Form/SelectInput"
import { useDebounce } from "Components/Hooks/HelperHooks";
import { useProfile } from "Components/Hooks/ProfileHooks";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { PrepServiceChannel, PrepServiceContract } from "api/types/contracts/shipping";
import { ListPrepServicesQuery } from "api/types/queries";
import { EntitySelectInputProps, SelfPopulatedSelectInputRef } from "helpers/types";
import { ForwardedRef, RefAttributes, forwardRef, useEffect, useImperativeHandle, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { loadPrepServiceList } from "slices/shipping/prepService/thunk";
import { useImmer } from "use-immer";

type PrepServiceSelectProps = EntitySelectInputProps<PrepServiceContract, "prepServiceId"> & {
    productOptionsOnly?: boolean,
    channel: PrepServiceChannel,
    warehouseId?: string,
    checkboxes?: boolean
}

const PrepServiceSelect = ({ isMulti, value, onChange, onSelect, channel, productOptionsOnly, warehouseId, checkboxes, name, isValid, ...rest }: PrepServiceSelectProps, ref: ForwardedRef<SelfPopulatedSelectInputRef>) => {
    const dispatch = useAppDispatch();
    const { t } = useTranslation();
    const { userProfile } = useProfile();
    const [query, updateQuery] = useImmer<ListPrepServicesQuery>({
        page: 1,
        pageSize: 99
    });

    const { prepServices, loading } = useAppSelector(
        (state) => ({
            prepServices: state.PrepService.list?.items || [], 
            loading: state.PrepService.loading.list
        })
    );
    
    const loadPrepServiceListDebounced = useDebounce(() => {
        dispatch(loadPrepServiceList(query));
    }, 200, [query, dispatch]);

    useImperativeHandle(ref, () => {
        return {
            reload: async () => {
                loadPrepServiceListDebounced();
            }
        };
    }, [loadPrepServiceListDebounced]);

    const selectedWarehouseId = warehouseId ?? userProfile?.user.warehouse?.warehouseId;

    const prepServiceList = useMemo(() => prepServices.filter(p => selectedWarehouseId === p.warehouse.warehouseId
        && (!productOptionsOnly || p.showInProductOptions)
        && p.channels.includes(channel)).map(c => ({ 
            value: c.prepServiceId, 
            label: c.name,
            disabled: c.includeAsDefault
        })), [prepServices, channel, productOptionsOnly, selectedWarehouseId]);

    const onChangedMulti = (val: string[]) => {
        if (!isMulti) {
            return;
        }

        const selected = prepServices.filter(p => val.includes(p.prepServiceId));

        if (selected) {
            onSelect?.(selected);
            onChange?.(val);
            return;
        }

        onChange?.([]);
        onSelect?.([]);
    };

    const onChangedSingle = (val?: string) => {
        if (isMulti) {
            return;
        }

        if (val) {
            const selected = prepServices.find(p => p.prepServiceId === val);

            if (selected) {
                onSelect?.(selected);
                onChange?.(selected.prepServiceId);
                return;
            }
        }

        onChange?.(undefined);
        onSelect?.(undefined);
    };

    useEffect(() => {
        loadPrepServiceListDebounced();
    }, [loadPrepServiceListDebounced]);

    useEffect(() => {
        if (!value) {
            return;
        }

        if (prepServices.length === 0) {
            return;
        }

        if (isMulti) {
            onChangedMulti(value);
        }
        else {
            onChangedSingle(value);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [prepServices]);
    
    const selectProps = {
        ...rest,
        placeholder: t("Prep Service"),
        busy: loading,
        options: prepServiceList,
        showClear: true,
        isValid: isValid
    }

    return prepServiceList.length > 0 ? <>
        {isMulti && <SelectInput<string> isMulti display={checkboxes ? "inline-checkbox" : "select"} value={value} onChange={onChangedMulti} {...selectProps} />}
        {!isMulti && <SelectInput value={value} onChange={onChangedSingle} {...selectProps} />}
    </> : <>
        <span className="text-warning-emphasis">{t("No Prep Services available")}</span>
    </>;
}

export default forwardRef(PrepServiceSelect) as (
    props: PrepServiceSelectProps & RefAttributes<SelfPopulatedSelectInputRef>
) => ReturnType<typeof PrepServiceSelect>;