import seq from 'seq-logging/browser';
import { getLoggedInUser } from './localStorage';

const logger = new seq.Logger({
    serverUrl: "https://log.tyloc.com",
    apiKey: "HanwjfvCoXFa5cpWhoc6",
    onError: (error: any) => {
        console.log("SeqLogError", error);
    }
});

const getLoggingProperties = (data?: {}) => ({
    AppName: "Tyloc Web",
    Host: window.location.host,
    Href: window.location.href,
    Pathname: window.location.pathname,
    CurrentUser: getLoggedInUser()?.user.userId,
    Tenant: JSON.parse(localStorage.getItem("tenant") || "undefined")?.identifier,
    ...(data || {})
});

/**
 * Logs an error message with optional data.
 * @param messageTemplate - The template for the error message.
 * @param data - Optional data to be logged along with the error message.
 */
export const logError = (messageTemplate: string, data?: {}) => {
    try {
        logger.emit({
            level: "Error",
            messageTemplate: messageTemplate,
            timestamp: new Date(),
            properties: getLoggingProperties(data)
        });
    } catch (error) {
        
    }
    finally {
        console.error(messageTemplate, {
            level: "Error",
            properties: getLoggingProperties(data)
        })
    }
}

/**
 * Logs a warning message.
 * @param messageTemplate - The template for the warning message.
 * @param data - Optional data to be included in the log.
 */
export const logWarn = (messageTemplate: string, data?: {}) => {
    try {
        logger.emit({
            level: "Warning",
            messageTemplate: messageTemplate,
            timestamp: new Date(),
            properties: getLoggingProperties(data)
        });
    } catch (error) {
        
    }
    finally {
        console.warn(messageTemplate, {
            level: "Warning",
            properties: getLoggingProperties(data)
        })
    }
}

/**
 * Logs an information message with optional data.
 * @param messageTemplate The template for the message.
 * @param data Optional data to include in the log.
 */
export const logInfo = (messageTemplate: string, data?: {}) => {
    try {
        logger.emit({
            level: "Information",
            messageTemplate: messageTemplate,
            timestamp: new Date(),
            properties: getLoggingProperties(data)
        });
    } catch (error) {
        
    }
    finally {
        console.info(messageTemplate, {
            level: "Information",
            properties: getLoggingProperties(data)
        })
    }
}