import { FormikValidation } from "helpers/types";
import { CSSProperties } from "react";
import { FormFeedback } from "reactstrap";
import TextInput from "../TextInput";

export type ValidatedInputProps<T> = {
    validation: FormikValidation<T>,
    field: keyof T | string,
    type?: "text" | "email" | "url" | "textarea" | "password",
    id?: string,
    disabled?: boolean,
    className?: string,
    style?: CSSProperties,
    placeholder?: string,
    mask?: string
}

const ValidatedInput = <T,>({ field, validation, ...rest}: ValidatedInputProps<T>) =>  {
    const fieldName = field.toString(); 
    const { error, touched, value } = validation.getFieldMeta(fieldName);

    return (<>
        <TextInput
            name={fieldName}
            isValid={touched ? !error : undefined}
            onChange={val => validation.setFieldValue(fieldName, val)}
            onBlur={validation.handleBlur}
            value={value}
            {...rest}
        />
        {touched && error ? (
            <FormFeedback type="invalid" tooltip>
                <div>{error}</div>
            </FormFeedback>
        ) : null}
    </>)
}

export default ValidatedInput;