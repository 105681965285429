import SelectInput from "Components/Form/SelectInput";
import { PrintOrientationList, type PrintOrientation } from "api/types/contracts/printing";
import { EnumSelectInput, SelectOption } from "helpers/types";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";

const PrintOrientationSelect = (props: EnumSelectInput<PrintOrientation>) => {
    const { t } = useTranslation();
    const options = useMemo<SelectOption<PrintOrientation>[]>(() => PrintOrientationList.map((item) => ({ 
        value: item, 
        label: t(`enums:PrintOrientation.${item}`) 
    })), [t]);

    const { isMulti, value, onChange, ...rest } = props;

    return <>
        {isMulti && <SelectInput<PrintOrientation> isMulti value={value} onChange={onChange} {...rest} options={options} placeholder={t("Print Orientation")} />}
        {!isMulti && <SelectInput {...rest} value={value} onChange={onChange} options={options} placeholder={t("Print Orientation")} />}
    </>;
};

export default PrintOrientationSelect;