import BusyOverlay from "Components/Common/BusyOverlay";
import CustomerSuitDisplay from "Components/Displays/CustomerSuitDisplay";
import Radiobox from "Components/Form/Radiobox";
import SearchBox from "Components/Form/SearchBox";
import { useDebounce } from "Components/Hooks/HelperHooks";
import { useAppDispatch, useAppSelector } from "Components/Hooks/StoreHooks";
import { CustomerContract } from "api/types/contracts/customers";
import { ListCustomersQuery } from "api/types/queries";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, Col, Row, Table } from "reactstrap";
import { loadList, resetList } from "slices/customer/thunk";
import { selectCustomerForGateEntry } from "slices/receivings/thunk";
import { useImmer } from "use-immer";

type CustomerSearchProps = {
    onInboundSearchRequested: VoidFunction
}

const CustomerSearch = (props: CustomerSearchProps) => {
    const [selected, setSelected] = useState<CustomerContract>();
    const [query, updateQuery] = useImmer<ListCustomersQuery>({
        search: "",
        page: 1,
        pageSize: 10
    });
    const dispatch = useAppDispatch();
    const { t } = useTranslation();

    const { customers, loading } = useAppSelector(
        (state) => ({
            loading: state.Customer.loading,
            customers: state.Customer.list,
            error: state.Customer.error,
        })
    );
    
    const debouncedLoadList = useDebounce(() => {
        if (query.search) {
            dispatch(loadList(query));
        }
        else {
            dispatch(resetList());
        }
    }, 500);

    useEffect(() => {
        debouncedLoadList();
    }, [debouncedLoadList, dispatch, query]);

    const list = useMemo<(CustomerContract | undefined)[]>(() => Array.from({ ...(customers.items || []), length: 5 }), [customers.items]);

    useEffect(() => {
        if (customers.items?.length === 1) {
            setSelected(customers.items[0]);
        }
    }, [customers]);

    useEffect(() => {
        if (selected) {
            dispatch(selectCustomerForGateEntry(selected));
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected])

    return <>
        <Row>
            <Col xs={8} >
                <SearchBox value={query.search ?? ""} placeholder={t("$t(Suit Number) $t(or) $t(Email) $t(or) $t(Full Name)")} onChange={val => {
                    updateQuery(draft => {
                        draft.search = val;
                    });
                }} />
            </Col>
            <Col xs={4}>
                <Button color="secondary" block onClick={props.onInboundSearchRequested}>{t("Search Inbound Records")}</Button>
            </Col>
        </Row>
        <Row>
            <Col>
                <div className="table-card table-responsive mt-2">
                    <BusyOverlay busy={loading.list} backgroundColor="white" spinnerColor="tenant-primary">
                        <Table striped className="align-middle mb-0">
                            <thead>
                                <tr>
                                    <th style={{ width: "5%" }}></th>
                                    <th>{t("Name")} ({t("Job Title")})</th>
                                    <th>{t("Email")}</th>
                                    <th>{t("Suit Number")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {list?.map((customer, i) => customer ? <tr key={i}>
                                    <td>
                                        <Radiobox value={selected?.customerId === customer.customerId} 
                                                name="customerSearchItem" onChange={val => val && setSelected(customer)} />
                                    </td>
                                    <td>{customer.name} {customer?.surname}</td>
                                    <td>{customer.user?.email}</td>
                                    <td><CustomerSuitDisplay suit={customer.suitNumber} /></td>
                                </tr> : <tr key={i}><td colSpan={4}>&nbsp;</td></tr>)}
                            </tbody>
                        </Table>
                    </BusyOverlay>
                </div>
            </Col>
        </Row>
    </>;
}

export default CustomerSearch;